import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//components

import Loader from "../components/Loader";
import Message from "../components/Message";
import ScrollTop from "../components/ScrollTop";
//actions
import { listCategories } from "../actions/categoryActions";
import { submitPost } from "../actions/postActions";
import { POST_CREATE_RESET } from "../constants/postConstants";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";

import { pageAnimation } from "../animations";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Meta from "../components/Meta";
import Footer from "../components/Footer";

const Submit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const isDarkMode = useSelector((state) => state.isDark);
  //
  const [name, setName] = useState("");

  const [description, setDescription] = useState("");
  const [image, setImage] = useState({});
  const [mature, setMature] = useState(Boolean);

  //
  const postCreate = useSelector((state) => state.postCreate);
  const { loading, error, success } = postCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories, error: categoryError } = categoryList;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else if (userInfo.role == "user" && userInfo.plan !== "premium") {
      history.push("/");
    } else if (success) {
      setTimeout(() => {
        dispatch({ type: POST_CREATE_RESET });
        history.push(`/${userInfo.userName}`);
      }, 2000);
    } else {
      dispatch(listCategories({ isMature: true }));
    }
  }, [dispatch, history, success, userInfo]);
  const [category, setCategory] = useState("Anime");
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      submitPost({
        name,
        category,
        description,
        image,
        isMature: mature,
      })
    );
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    setImage(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  };

  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Submit post" />
      {loading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {success && (
        <Message variant="success">Post Submitted Successfully</Message>
      )}

      <span className="btn">
        <Link to="/admin/postlist">
          {" "}
          <FontAwesomeIcon icon={faChevronLeft} />{" "}
        </Link>
      </span>
      <div class="login">
        <form onSubmit={submitHandler} class="loginForm">
          <span className="icon">
            <a>
              <h2>Submit Post</h2>
            </a>
          </span>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              class="form-control"
              placeholder="Enter name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div class="form-group">
            <label for="category">Category</label>
            <select onChange={(e) => setCategory(e.target.value)}>
              <option value={category} selected={true}>
                {category}
              </option>
              {categories.map((cat, index) => (
                <option value={cat.name}>{cat.name}</option>
              ))}
            </select>
          </div>
          <div class="form-group">
            <label for="category">Mature</label>

            <input
              class="form-control"
              type="checkbox"
              checked={mature}
              onChange={(e) => setMature(e.target.checked)}
            />
          </div>
          <div class="form-group">
            <label for="name">Post Description</label>
            <textarea
              placeholder="Enter post description"
              rows="4"
              onChange={(e) => setDescription(e.target.value)}
              name="postDetails"
              form="usrform"
              value={description}
            ></textarea>
          </div>

          <div class="form-group">
            <label for="image">Image</label>
            <input
              class="form-control"
              className="fileInput"
              type="file"
              onChange={uploadFileHandler}
            />
            {image && (
              <span className="img">
                <img src={image.preview}></img>
                {image.name}
              </span>
            )}
          </div>
          <div class="text">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>

      <Footer />
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  .btn {
    margin-right: auto;
    padding: 1rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
    a {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      &:hover {
        color: #50c1e9;
      }
    }
  }
  .login {
    width: 27rem;
    padding-bottom: 2rem;
    @media screen and (max-width: 900px) {
      width: 100%;
      padding-bottom: 0rem;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      border-top: 0.15rem solid
        ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        h2 {
          font-weight: 400;
          font-size: 1.5rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
        h3 {
          color: #50c1e9;
          font-weight: 700;
          font-size: 1.5rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        label {
          padding: 0.2rem 0rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
        input,
        select {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#0f0f0f" : "#ffffff"};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
          }
        }
        textarea {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#0f0f0f" : "#ffffff"};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
          }
          &:focus {
            outline: none;
            border: 0.5px solid
              ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
            box-shadow: 0px 0px 1.5px 1.5px #50c1e9;
          }
        }
        img {
          width: 100%;
          margin-top: 0.4rem;
        }
      }
      .forReg {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem 0rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          font-size: 0.9rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default Submit;

///////
