import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';

//components

import Loader from '../components/Loader';
import Message from '../components/Message';
import ScrollTop from '../components/ScrollTop';
//actions
import { forgotPassword } from '../actions/userActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
import { USER_FORGOT_PASSWORD_RESET } from '../constants/userConstants';
import Meta from '../components/Meta';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const isDarkMode = useSelector((state) => state.isDark);

  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { loading, error, success } = userForgotPassword;
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push('/');
    }
    if (success) {
      setTimeout(() => {
        dispatch({ type: USER_FORGOT_PASSWORD_RESET });
        history.push('/');
      }, 2000);
    }
  }, [history, userInfo, success]);
  const submitHandler = (e) => {
    e.preventDefault();
    //Dispatch register
    dispatch(forgotPassword({ email }));
  };
  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Forgot password" />
      {error && <Message variant="danger">{error}</Message>}
      {success && (
        <Message variant="success">
          Check your email for password reset link
        </Message>
      )}
      {loading && <Loader />}
      <div class="login">
        <form onSubmit={submitHandler} class="loginForm">
          <h3 class="text-center">Forgot password</h3>
          <div class="form-group">
            <input
              class="form-control"
              placeholder="Enter email to send reset link"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div class="text">
            <button type="submit" class="mt-1">
              Submit
            </button>
          </div>
        </form>
      </div>
      <ScrollTop />
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  min-height: 81vh;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  display: flex;
  justify-content: center;
  padding: 1rem;
  @media screen and (max-width: 900px) {
    padding: 0rem;

    background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
  }
  .login {
    width: 27rem;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 900px) {
          display: none;
        }
        h2 {
          font-weight: 400;
          font-size: 1.5rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
        h3 {
          color: #50c1e9;
          font-weight: 700;
          font-size: 1.5rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      @media screen and (max-width: 900px) {
        padding: 1rem 1.2rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
      }
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        input {
          background: ${({ isDarkMode }) =>
            isDarkMode ? '#0f0f0f' : '#ffffff'};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
          }
        }
      }
      .forReg {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem 0rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          font-size: 0.9rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default ForgotPassword;
