import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/Nav';
import Home from './pages/Home';
import PostDetails from './pages/PostDetails';
import Login from './pages/Login';
import Register from './pages/Register';
import Categories from './pages/Categories';
import CategoryPosts from './pages/CategoryPosts';
import Search from './pages/Search';
import Account from './pages/Account';
import NotFound from './pages/NotFound';
import IdentitySettings from './pages/IdentitySettings';
import GeneralSettings from './pages/GeneralSettings';
import DeactivationSettings from './pages/DeactivationSettings';
import AdminUserList from './pages/AdminUserList';
import AdminPostList from './pages/AdminPostList';
import AdminCategoryList from './pages/AdminCategoryList';
import AdminUserEdit from './pages/AdminUserEdit';
import AdminPostEdit from './pages/AdminPostEdit';
import AdminPostCreate from './pages/AdminPostCreate';
import AdminCategoryCreate from './pages/AdminCategoryCreate';
import AdminCategoryEdit from './pages/AdminCategoryEdit';
import Submit from './pages/Submit';
import Premium from './pages/Premium';
import BillingAndPayments from './pages/BillingAndPayments';
import ForgotPassword from './pages/ForgotPassword';
import Terms from './pages/Terms';
import ResetPassword from './pages/ResetPassword';
import Contact from './pages/Contact';
import About from './pages/About';
import Policy from './pages/Policy';
import NotificationList from './pages/NotificationList';
import GlobalStyle from './components/GlobalStyle';
import { Switch, Route, useLocation } from 'react-router-dom';

//Animation
import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation();

  const isDarkMode = useSelector((state) => state.isDark);

  return (
    <div className="App">
      <Navbar />
      <GlobalStyle isDarkMode={isDarkMode} />
      <AnimatePresence>
        <Switch lacation={location} key={location.pathname}>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/category" exact>
            <Categories />
          </Route>
          <Route path="/category/:slug" exact>
            <CategoryPosts />
          </Route>
          <Route path="/:userName/art/:slug/:id" exact>
            <PostDetails />
          </Route>
          <Route path="/search" exact>
            <Search />
          </Route>
          <Route path="/settings/identity" exact>
            <IdentitySettings />
          </Route>
          <Route path="/settings/general" exact>
            <GeneralSettings />
          </Route>
          <Route path="/settings/deactivation" exact>
            <DeactivationSettings />
          </Route>
          <Route path="/admin/userlist" exact>
            <AdminUserList />
          </Route>
          <Route path="/admin/user/:id/edit" exact>
            <AdminUserEdit />
          </Route>
          <Route path="/admin/postlist" exact>
            <AdminPostList />
          </Route>
          <Route path="/admin/post/:id/edit" exact>
            <AdminPostEdit />
          </Route>
          <Route path="/admin/createpost" exact>
            <AdminPostCreate />
          </Route>
          <Route path="/submit" exact>
            <Submit />
          </Route>
          <Route path="/admin/categorylist" exact>
            <AdminCategoryList />
          </Route>
          <Route path="/admin/createcategory" exact>
            <AdminCategoryCreate />
          </Route>
          <Route path="/admin/category/:id/edit" exact>
            <AdminCategoryEdit />
          </Route>
          <Route path="/premium" exact>
            <Premium />
          </Route>
          <Route path="/account" exact>
            <BillingAndPayments />
          </Route>
          <Route path="/notifications/feedback" exact>
            <NotificationList />
          </Route>
          <Route path="/forgotPassword" exact>
            <ForgotPassword />
          </Route>
          <Route path="/terms" exact>
            <Terms />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/policy" exact>
            <Policy />
          </Route>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/resetPassword/:id" exact>
            <ResetPassword />
          </Route>
          <Route path="/404" exact>
            <NotFound />
          </Route>
          <Route path={['/:id', '/:id/posts', '/:id/about']} exact>
            <Account />
          </Route>
          /////
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
