import {
    REPLY_DELETE_FAIL,
    REPLY_DELETE_SUCCESS,
    REPLY_DELETE_REQUEST,
    REPLY_DELETE_RESET,
  
    REPLY_CREATE_FAIL,
    REPLY_CREATE_SUCCESS,
    REPLY_CREATE_REQUEST,
    REPLY_CREATE_RESET,
  
    REPLY_UPDATE_FAIL,
    REPLY_UPDATE_SUCCESS,
    REPLY_UPDATE_REQUEST,
    REPLY_UPDATE_RESET,
  
    UNLIKE_REPLY_REQUEST,
  UNLIKE_REPLY_FAIL,
  UNLIKE_REPLY_SUCCESS,
  UNLIKE_REPLY_RESET,
  
  LIKE_REPLY_REQUEST,
  LIKE_REPLY_SUCCESS,
  LIKE_REPLY_FAIL,
  LIKE_REPLY_RESET
  
  } from "../constants/replyConstants";

  export const replyDeleteReducer=(state={},action)=>{
    switch (action.type) {
      case REPLY_DELETE_REQUEST:
        return { loading: true };
      case REPLY_DELETE_SUCCESS:
        return { loading: false, success:true,};
      case REPLY_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case REPLY_DELETE_RESET:
          return {};
      default:
        return state;
    }
  } 
  
  export const replyCreateReducer=(state={reply:{}},action)=>{
    switch (action.type) {
      case REPLY_CREATE_REQUEST:
        return { loading: true };
      case REPLY_CREATE_SUCCESS:
        return { loading: false,success:true,reply:action.payload.data };
      case REPLY_CREATE_FAIL:
        return { loading: false, error: action.payload };
        case REPLY_CREATE_RESET:
          return { product:{} };
      default:
        return state;
    }
  } 
  export const replyUpdateReducer=(state={reply:{}},action)=>{
    switch (action.type) {
      case REPLY_UPDATE_REQUEST:
        return { loading: true };
      case REPLY_UPDATE_SUCCESS:
        return { loading: false,success:true,reply:action.payload.data };
      case REPLY_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case REPLY_UPDATE_RESET:
          return { product:{} };
      default:
        return state;
    }
  } 


  
  export const likeReplyReducer=(state={},action)=>{
    switch (action.type) {
      case LIKE_REPLY_REQUEST:
        return { loading: true };
      case LIKE_REPLY_SUCCESS:
        return { loading: false, success:true};
      case LIKE_REPLY_FAIL:
        return { loading: false, error: action.payload };
        case LIKE_REPLY_RESET:
          return {};
      default:
        return state;
    }
  } 
  
  export const unlikeReplyReducer=(state={},action)=>{
    switch (action.type) {
      case UNLIKE_REPLY_REQUEST:
        return { loading: true };
      case UNLIKE_REPLY_SUCCESS:
        return { loading: false, success:true};
      case UNLIKE_REPLY_FAIL:
        return { loading: false, error: action.payload };
        case UNLIKE_REPLY_RESET:
          return {};
      default:
        return state;
    }
  } 