import React from "react";

//Redux
import { useSelector } from "react-redux";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";
const About = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledAbout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Policy" />
      <div className="about">
        <div className="section">
          <h2>Privacy Policy</h2>
          <div>
            Wandart respects your privacy and is fully committed to protect your
            personal information and use it properly and in compliance with data
            privacy laws.This Privacy Policy explains what information we
            (together with our affiliated companies worldwide) collect and use
            pertaining to each of our Account holders and non-Account holders
            (such as Site visitors) through the Sites, how we use that
            information, and what choices you have with respect to the
            information we collect about you. The Sites and our services
            available via the Sites are collectively called the "Services".
            Please take a few moments to read this Privacy Policy before you
            access or use the Services. By using or accessing the Wandart Sites,
            you acknowledge that you have read and fully understood this Privacy
            Policy and our Terms of Service and that they both apply to your use
            of the Services. If you do not read and fully understand this
            Privacy Policy, please do not use the Sites or Services. This
            Privacy Policy is not intended to override the terms of any contract
            you have with us, nor any rights you might have under applicable
            data privacy laws. Where you have read this policy but would like
            further clarification, please contact us at support@wandart.art
          </div>
          <div>
            <h3>1. What information do we collect? </h3>To provide you the
            Services, we may collect personal information relating to an
            identified or identifiable natural person ("Information"). We
            collect Information in order to provide, operate and improve our
            Sites and Services, to administer your use of the Site or Services
            (including your Account, as defined below), and to enable you to
            enjoy and easily navigate our Site and Services. Additionally, we
            collect your information, among other reasons stated below, to
            provide you with ongoing customer assistance and technical support,
            and to enhance our data security and fraud prevention capabilities.
            <ol type="I">
              <li>
                <h4>Information you provide us</h4>
                When you register for our Services and create a Wandart account
                via the Wandart Sites (an "Account"), when you register for
                special services (e.g., the Print Program, Premium Content),
                sign up for e-mail newsletters and purchase products or
                services, information you include in your communications with
                us, we collect certain information such as your name, age/ date
                of birth, e-mail address.
              </li>{" "}
              <li>
                {" "}
                <h4>
                  {" "}
                  Information collected through your use of the Site or
                  Services.
                </h4>
                When you are visiting or using our Site or Services, including
                when you browse the Site or a User Account, edit your Account
                and upload information and content, and/or download and use any
                Site feature or services, we are aware of it and will usually
                gather, collect and record such uses, and related information,
                either independently or with the help of third-party services as
                detailed herein, including through the use of "cookies" and
                other tracking technologies, as further detailed in section 5,
                below. Our servers automatically collect and record certain
                information about how a person uses our Services (we refer to
                this information as "Log Data"), for both Account holders and
                non-Account holders (such as Site visitors) (both will be
                referred to as a "User"). Log Data may include information such
                as a User's Internet Protocol (IP) address, mobile device
                identifier, browser type, operating system, the pages or
                features of our Services to which a User browsed and the time
                spent on those pages or features, search terms, the links on our
                Services on which a User clicked, the friends of Account holders
                who also use the Services, how often Users click on our
                advertisements, which files are downloaded most frequently, and
                which areas of our Site are the most popular and other
                statistics. We use this information to administer the Services
                and we analyze (and may engage third parties to analyze) this
                information to further develop, improve and enhance the Services
                by expanding their features and functionality and tailoring them
                to our Users' needs and preferences, and to make
                recommendations.
              </li>
              <li>
                {" "}
                <h4> Information we collect from other sources </h4>
                We cannot delete or change any Information that is stored on
                third party sites and services. In addition, We may receive
                information about you from third-party sources, such as (i)
                security providers, fraud detection and prevention providers to
                help us screen out Users associated with fraud, and (ii)
                advertising and marketing partners in order to monitor, manage
                and measure our ad campaigns.
              </li>
            </ol>{" "}
            <h3>2. How do we use such information? </h3>
            <ol type="I">
              <li>
                <h4>We use your information for the following purposes</h4> to
                provide and operate the Services; to enhance our data security
                and fraud prevention capabilities; To create aggregated
                statistical data and other aggregated and/or inferred
                information, which we may use to provide and improve our
                respective Services; to customize advertising and content
                available on the Wandart Sites, to send you service-related
                notices and promotional information about products and services
                that may be of interest to you and/or to conduct research for
                internal purposes; to help us in updating, expanding and
                analyzing our records to identify new Users; to facilitate,
                sponsor, and offer certain contests, events, and promotions,
                monitor performance, contact winners, and grant prizes and
                benefits; and to comply with any applicable laws and
                regulations.{" "}
              </li>{" "}
              <li>
                {" "}
                <h4>
                  We will only use your Information for the purposes set out in
                  Section 2 where you give us your permission and/or we are
                  satisfied that
                </h4>{" "}
                <ol type="a">
                  <li>
                    {" "}
                    our use of your Information is necessary to perform a
                    contract or take steps to enter into a contract with you
                    (e.g. to provide you with Services, to provide you with our
                    customer assistance and technical support), or
                  </li>{" "}
                  <li>
                    our use of your Information is necessary to comply with a
                    relevant legal or regulatory obligation that we have, or
                  </li>{" "}
                  <li>
                    {" "}
                    our use of your Information is necessary to support
                    legitimate interests that we have as a business (for
                    example, to maintain and improve our Services by identifying
                    user trends and the effectiveness of our promotional
                    campaigns and identifying technical issues), provided it is
                    conducted at all times in a way that is proportionate, and
                    that respects your privacy rights
                  </li>
                </ol>
              </li>
            </ol>{" "}
            <h3> 3. How we share your information?</h3>
            <ol type="I">
              We may share your information with service providers and others
              (or otherwise allow them access to it) in the following manners
              and instances
              <li>
                {" "}
                <h4> Third Party Service Providers</h4>
                We may share Information as well as aggregated information that
                we collect with third party service providers whose services and
                solutions complement, facilitate and enhance our own. These
                include but are not limited to hosting and server co-location
                services, communications and content delivery networks (CDNs),
                data and cyber security services, billing and payment processing
                services, fraud detection and prevention services, industry
                analysis, web analytics, e-mail distribution and monitoring
                services, session recording and remote access services,
                performance measurement, data optimisation and marketing
                services, content providers, and our legal and financial
                advisors (collectively, "Third Party Services")
              </li>{" "}
              <li>
                {" "}
                <h4>
                  {" "}
                  Information Disclosed for Our Protection and the Protection of
                  Others.
                </h4>
                Where permitted by local data protection laws, we will disclose
                Information when required by law or under the good-faith belief
                that such disclosure is necessary in order to conform to
                applicable law, comply with subpoenas, court orders or legal
                process served on Wandart, to establish or exercise our legal
                rights or defend against legal claims, and to protect the
                property or interests of Wandart, its agents and employees,
                personal safety, or the public. Under these circumstances,
                Wandart may be prohibited by law, court order or other legal
                process from providing notice of the disclosure, and Wandart
                reserves the right under those conditions not to provide notice
                in its sole discretion.
              </li>{" "}
            </ol>
            <h3>4. Use of Cookies and Similar Technologies </h3>
            <ol type="I">
              {" "}
              A cookie is a small file of letters and numbers downloaded on to
              your computer when you access certain websites. In general,
              cookies allow a website to recognize a users computer. The most
              important thing to know about cookies placed by Wandart is that
              they make our website a bit more user-friendly, for example, by
              remembering site preferences and language settings
            </ol>
            <h3>5. Your rights in relation to your information</h3>
            <ol type="I">
              <li>
                It is imperative that you will have control over your
                Information. That is the reason why we are taking reasonable
                steps in order to enable you to access, receive a copy of,
                update, amend, delete, or limit the use of your Information
              </li>{" "}
              <li>
                We may ask you for additional information to confirm your
                identity and for security purposes, before disclosing the
                requested data to you. We reserve the right to charge a fee
                where permitted by law, for instance if your request is
                manifestly unfounded or excessive.
              </li>{" "}
              <li>
                You have the right to lodge a complaint with your local
                supervisory authority for data protection (but we still
                recommend you to contact us first).
              </li>{" "}
              <li>
                {" "}
                For all Wandart Users, if you may review and amend your
                Information by logging in and navigating to your Settings page
                on the website. Once there, you may modify information that is
                incorrect and delete certain information from your profile.
              </li>
            </ol>
            <h3>6. Security</h3>
            We have implemented security measures designed to protect the
            Information you share with us, including physical, electronic and
            procedural measures. Among other things, we offer HTTPS secure
            access to most areas on our Services; We also regularly monitor our
            systems for possible vulnerabilities and attacks, and regularly seek
            new ways and Third Party Services for further enhancing the security
            of our Services and protection of our visitors' and Users' privacy.
            Regardless of the measures and efforts taken by us, we cannot and do
            not guarantee the absolute protection and security of your
            Information, or any other content you upload, publish or otherwise
            share with us or anyone else. We therefore encourage you to set
            strong passwords for your User Account, and avoid providing us or
            anyone with any sensitive information which you believe its
            disclosure could cause you substantial or irreparable harm. If you
            have any questions regarding the security of our Services, you are
            more than welcome to contact us at{" "}
            <a href="mailto:support@wandart.art">support@wandart.art</a>
          </div>
          <h2>Copyright Policy</h2>
          <div>
            <div className="copy">
              <h3>Copyright and Your Wandart Submission </h3>{" "}
              <p>
                {" "}
                Here at Wandart we respect the rights of all artists and
                creative people worldwide and we expect our members to also
                demonstrate that respect and assist us in creating a beneficial
                and positive atmosphere for all Wandart visitors and members.
                The bottom line is: Just about anything that is on this site, on
                the web, on TV, on CD's, on DVD's, in books & in magazines is
                probably copyrighted by someone.{" "}
              </p>
              <p>
                {" "}
                Copyright can be a confusing territory for many people. In many
                cases the natural confusion over the sometimes varied
                circumstances surrounding copyright will lead people to rely on
                rumor or myth more often than the actual law which naturally
                confuses the matter even more.{" "}
              </p>
              In this document we will attempt to eliminate some of the
              confusion and counter many of the myths surrounding copyright and
              to present clearly the Wandart policies and practices with regard
              to copyright. The availability of this document should not be
              construed as rendering legal or other professional advice, and
              this document is not a substitute for the advice of an attorney.
              If you require legal advice, you should seek the services of a
              qualified attorney.
            </div>
            <div className="copy">
              <h3>How do I get Copyright?</h3>
              <p>
                Under most national laws and international copyright treaties
                you receive a copyright automatically in any original work as
                you make it. Registration may be required to exercise some
                rights, like commencing a lawsuit. Copyright does NOT protect
                ideas. Copyright protects the expression of ideas or the ways in
                which an idea is materially placed or expressed in the work.{" "}
              </p>
            </div>
            <div className="copy">
              <h3>What is Copyright Infringement? </h3>
              <p>
                {" "}
                To simplify this question, copyright infringement occurs when
                you do certain things with a creative work which someone else
                produced without first getting the proper permission.
              </p>
              <p>
                Some examples of copyright infringement (this is only a partial
                listing) can include:
                <ul>
                  <li>
                    Placing someone else's photograph or creative work online
                    without proper permission.
                  </li>
                  <li>
                    {" "}
                    Using a creative work commercially without permission.
                  </li>
                  <li>
                    Adapting someone else's creative work found in one medium to
                    another medium, such as making a book into a movie or a
                    photograph into a painting.
                  </li>
                  <li>
                    {" "}
                    Modifying or editing a creative work without proper
                    permission.
                  </li>
                </ul>
              </p>
            </div>
            <div className="copy">
              <h3> How Can I Avoid Infringing on Someone's Copyright?</h3>
              <p>
                {" "}
                The best way to avoid infringing on the rights of another
                creative person is to use your skill, talent and imagination to
                create your own completely original work. When we use the word
                'original' we don't mean that you must come up with an idea
                which hasn't been used before - recall that copyright does not
                protect ideas. When used in reference to copyright "original"
                means that you created your work without referencing or
                deliberately copying anyone else's work during the process.
              </p>
              <p>
                Ensure that all parts of your work, both visual and audio, are
                your own original creations. If you have used materials which
                are owned by other people or companies make certain that you
                have obtained proper permission or licensing for the use before
                you place your work online.{" "}
              </p>{" "}
              <p>
                You can also obtain permission to use a copyrighted work by
                license.
              </p>{" "}
            </div>
            <div className="copy">
              <h3>What Sort of Things are Copyrighted?</h3>
              <p>
                {" "}
                The easy answer to this question is that just about any creative
                work that is less than 150 years old you might find should be
                considered copyrighted by default. A work is not required to
                have a copyright statement printed on it or near it in order to
                be considered copyrighted so do not assume that the work is
                unprotected simply because you cannot see a notice written
                anywhere.
              </p>
              <p>
                Also do not confuse the fact that a work is publicly available
                with the idea that it is in the public domain or free for use.
                Being easy to find on the internet does not affect a work's
                copyrighted status. There are many exceptions to the 150 year
                guideline, but you are best advised to obtain legal advice from
                an expert if you intend to rely on the possibility that a modern
                work is not copyrighted.
              </p>
              <h4>Some Cautions </h4>
              <ul>
                {" "}
                <li>
                  In most cases it does not matter how much of the material you
                  have used, whether it's a single frame, a few moments of
                  audio, a short clip of video or any other sampling it's still
                  considered to be protected by copyright and you still require
                  the owner's permission for use.
                </li>
                <li>
                  {" "}
                  It doesn't matter how you obtained the material, it's still
                  considered copyrighted and you still need permission. It
                  doesn't matter whether or not you've credited the proper
                  owner, it's still considered copyrighted and you still need
                  permission.
                </li>
                <li>
                  {" "}
                  It doesn't matter if you are not selling it or making a
                  profit, it's still considered copyrighted and you still need
                  permission.
                </li>
                <li>
                  {" "}
                  It doesn't matter if you can find other people using things
                  without permission, it's still considered copyrighted and you
                  still need permission.
                </li>
                <li>
                  {" "}
                  It doesn't matter if you've edited it a little bit or made a
                  few alterations, if it's recognizable it's still considered
                  copyrighted and you still need permission.
                </li>
                <li>
                  {" "}
                  Read licenses carefully to understand the type of permission
                  they provide. For example, there are many versions of the
                  Creative Commons - each giving different permissions.
                </li>
              </ul>{" "}
            </div>
            <div className="copy">
              <h3>What Happens When You Submit Infringing Works to Wandart?</h3>
              <p>
                {" "}
                Any copyright owner following the procedures in this Copyright
                Policy can require Wandart to remove his or her copyrighted
                content in use by a member of Wandart. When through the proper
                notice we become aware that a submission to Wandart infringes
                upon the copyrights of another artist, creative person or
                company, we will immediately delete it. This is a legal
                requirement which we fulfill immediately; you will not receive
                an advanced warning and you will not be given an opportunity to
                'fix it'.
                <p>
                  If you believe that a submission on Wandart infringes on your
                  copyright you may either report the submission using our
                  internal reporting system or send us a copyright notice via
                  mail or email. A member of staff will review your notice and
                  act accordingly.
                </p>
              </p>
              <p>
                {" "}
                If you believe that one of your submissions was removed in error
                you may contact our helpdesk or otherwise file a counter notice.
              </p>
              <p>
                {" "}
                If you are found to repeatedly post infringing content, your
                account will be suspended and serious offenders will have their
                account banned and deactivated. We consider three strikes as an
                indication of being a repeat infringer subject to ban.
              </p>
              <p>
                {" "}
                If you are found deliberately misrepresenting the copyrighted
                work of another as your own your account will be immediately
                banned and deactivated.
              </p>

              <p>
                The copyright owner may also decide to sue you directly if you
                infringe his or her copyright in posting content to Wandart.
              </p>
            </div>
            <div className="copy">
              <h3> What about "Fair Use"</h3>
              <p>
                {" "}
                "Fair Use" is the notion that some public and private uses of
                copyrighted works should not require the permission of a
                copyright owner. These circumstances are very limited, complex
                to analyze under the law and require the help of expert advice
                from a lawyer. We recommend you talk to your own lawyer if you
                want to know more about fair use as it applies to the work you
                are doing. If it turns out that it isn't fair use, you may be
                liable for very serious money damages.
              </p>
            </div>
            <div className="copy">
              <h3> If you take my work down am I protected from a lawsuit?</h3>
              <p>
                No. Even if Wandart takes an infringing work down, you may still
                be responsible for very significant damages if the copyright
                owner decides to sue you.
              </p>
            </div>
            <div className="copy">
              <h3>
                {" "}
                Notification of Copyright Infringement, Instructions for
                Copyright Owners{" "}
              </h3>
              <p>
                {" "}
                This section contains the formal requirements of the Copyright
                Act with respect to the rights of copyright owners whose content
                appears on Wandart without authorization and instruction to
                copyright owners.{" "}
              </p>
              <p>
                To file a copyright infringement notification with Wandart (also
                commonly known as a "DMCA takedown notice"), the copyright owner
                or an authorized agent acting on his or her behalf will need to
                send a written communication that includes substantially the
                following:
              </p>
              <ol>
                <li>
                  {" "}
                  A physical or electronic signature of the copyright owner or
                  of a person authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a
                  representative list of such works at that site.
                </li>
                <li>
                  {" "}
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  and information reasonably sufficient to permit the service
                  provider to locate the material. In this regard please provide
                  URLs when you identify the location of the material.
                </li>
                <li>
                  Information reasonably sufficient to permit the service
                  provider to contact the complaining party, such as an address,
                  telephone number, and, if available, an electronic mail
                  address at which the complaining party may be contacted.{" "}
                </li>
                <li>
                  {" "}
                  A statement that the complaining party has a good faith belief
                  that use of the material in the manner complained of is not
                  authorized by the copyright owner, its agent, or the law.
                </li>{" "}
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that the complaining
                  party is authorized to act on behalf of the owner of an
                  exclusive right that is allegedly infringed.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
      <Footer />
    </StyledAbout>
  );
};

const StyledAbout = styled(motion.div)`
  .about {
    background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 2rem 2rem;

    @media screen and (max-width: 900px) {
      padding: 0rem;
    }
    .section {
      border-radius: 5px;
      padding: 2rem 2rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      @media screen and (max-width: 900px) {
        border-radius: 0px;
        padding: 1rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      }
    }
    h2 {
      text-align: center;
      padding: 0.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 2rem;
      @media screen and (max-width: 900px) {
        padding: 0.5rem;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    h3 {
      padding: 0.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 1.4rem;
      font-weight: 500;
      padding-top: 1.5rem;
      @media screen and (max-width: 900px) {
        padding: 0.5rem;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
    a {
      cursor: pointer;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      text-decoration: underline;
      &:hover {
        color: #50c1e9;
      }
    }
    ol {
      padding: 0rem 1rem;

      li {
        padding: 0.2rem 0rem;

        a {
          cursor: pointer;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
      h4 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0.2rem 0rem;
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
    .copy {
      ul {
        padding: 0rem 1rem;
        li {
          padding: 0.2rem 0rem;
        }
      }
      p {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        padding: 0.2rem 0rem;
        font-size: 1rem;
        line-height: 1.2rem;
      }
      h4 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1.2rem;
        font-weight: 500;
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
`;
export default About;
