import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';

//components
import Loader from '../components/Loader';
import Message from '../components/Message';
import SettingsNav from '../components/SettingsNav';
import ScrollTop from '../components/ScrollTop';
import {
  getUserDetails,
  updateUserName,
  updateUserProfile,
} from '../actions/userActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
import Meta from '../components/Meta';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart } from '@fortawesome/free-regular-svg-icons';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants';
import Footer from '../components/Footer';

function DeactivationSettings(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const [userName, setUserName] = useState('');

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user, error } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(getUserDetails('me'));

      //   } else if (resendSuccess) {
      //     setTimeout(() => {
      //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
      //     }, 2000);
      //   } else if (resendError) {
      //     setTimeout(() => {
      //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
      //     }, 2000)
    }
  }, [dispatch, history, userInfo]);

  return (
    <StyledDeactivationSettings isDarkMode={isDarkMode}>
      <Meta title="Deactivation settings" />
      <div className="settings">
        <SettingsNav pathname={pathname} isDarkMode={isDarkMode} />
        <div className="identity">
          <StyledCard isDarkMode={isDarkMode}>
            <h3>Coming Soon!!</h3>
          </StyledCard>
        </div>
      </div>
      <Footer />
      <ScrollTop />
      <ScrollTop />
    </StyledDeactivationSettings>
  );
}

const StyledDeactivationSettings = styled(motion.div)`
  display: flex;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  flex-flow: column wrap;
  @media (max-width: 900px) {
    flex-flow: column wrap;
  }
  .settings {
    display: flex;
    padding-bottom: 5rem;
    width: 100%;
    @media (max-width: 900px) {
      flex-flow: column wrap;
      padding-bottom: 0rem;
      background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
    }
  }
  .identity {
    flex: 80%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 2rem 1rem;
    @media (max-width: 900px) {
      padding: 0.5rem;

      width: 100%;
      padding-bottom: 10rem;
    }
  }
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
  width: 40rem;
  height: 20rem;
  padding: 1.2rem;
  margin: 0.5rem 0rem;
  border-radius: 8px;
  @media (max-width: 900px) {
    width: 100%;
  }
  background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  justify-content: center;
  align-items: center;
  h3 {
    color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
    font-size: 2rem;
  }
`;
export default DeactivationSettings;
