import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
//components

import Loader from '../components/Loader';
import Message from '../components/Message';
import ScrollTop from '../components/ScrollTop';
//actions
import { getUserDetails } from '../actions/userActions';
import { updateUser } from '../actions/userActions';

import { USER_UPDATE_RESET } from '../constants/userConstants';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { pageAnimation } from '../animations';

import Meta from '../components/Meta';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const UserEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const userId = pathname.split('/')[3];
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [active, setActive] = useState(true);
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);

  const [role, setRole] = useState('');
  const [plan, setPlan] = useState('');

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = userUpdate;
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else if (userInfo.role === 'user') {
      history.push('/');
    } else if (updateSuccess) {
      dispatch({ type: USER_UPDATE_RESET });
      history.push('/admin/userlist');
    } else if (!user.userName || user.userName != userId) {
      dispatch(getUserDetails(userId));
    } else {
      setRole(user.role);
      setPlan(user.plan);
    }
  }, [dispatch, user, history, updateSuccess, userInfo, userId]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ role, active }, user._id));
  };

  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Edit User" />
      {updateLoading && <Loader />}
      {updateSuccess && (
        <Message variant="success">User edited successfully</Message>
      )}
      {updateError && <Message variant="danger">{updateError}</Message>}{' '}
      <span className="btn">
        <Link to="/admin/userlist">
          {' '}
          <FontAwesomeIcon icon={faChevronLeft} />{' '}
        </Link>
      </span>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div class="login">
            <form onSubmit={submitHandler} class="loginForm">
              <span className="icon">
                <a>
                  <h2>User Edit</h2>
                </a>
              </span>
              <div class="form-group">
                <label for="name">Role</label>
                <input
                  class="form-control"
                  type="text"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              </div>
              <div class="form-group">
                <label for="name">Plan</label>
                <input
                  class="form-control"
                  type="text"
                  value={plan}
                  onChange={(e) => setPlan(e.target.value)}
                />
              </div>

              {/* <div class="form-group">
                <label for="name">Active</label>
                <input
                  class="form-control"
                  type="checkbox"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              </div> */}

              <div class="text">
                <button type="submit">Update</button>
              </div>
            </form>
          </div>
        </>
      )}
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  min-height: 81vh;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  .btn {
    margin-right: auto;
    @media screen and (max-width: 900px) {
      display: none;
    }
    a {
      color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
      &:hover {
        color: #50c1e9;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 0rem;

    background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
  }
  .login {
    width: 27rem;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        h2 {
          font-weight: 400;
          font-size: 1.5rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
        h3 {
          color: #50c1e9;
          font-weight: 700;
          font-size: 1.5rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      @media screen and (max-width: 900px) {
        padding: 1rem 1.2rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
        min-height: 91vh;
      }
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        label {
          padding: 0.2rem 0rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
        input {
          background: ${({ isDarkMode }) =>
            isDarkMode ? '#0f0f0f' : '#ffffff'};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
          }
        }
      }
      .forReg {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem 0rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          font-size: 0.9rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default UserEdit;
