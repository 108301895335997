import { Helmet } from 'react-helmet';
//Redux
import { useSelector } from 'react-redux';
const Meta = ({ title, description, keywords }) => {
  const isDarkMode = useSelector((state) => state.isDark);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {isDarkMode ? (
        <meta name="theme-color" content="#0f0f0f" />
      ) : (
        <meta name="theme-color" content="#ffffff" />
      )}
      {isDarkMode ? (
        <meta name="msapplication-navbutton-color" content="#0f0f0f" />
      ) : (
        <meta name="msapplication-navbutton-color" content="#ffffff" />
      )}
      {isDarkMode ? (
        <meta name="apple-mobile-web-app-status-bar-style" content="#0f0f0f" />
      ) : (
        <meta name="apple-mobile-web-app-status-bar-style" content="#ffffff" />
      )}
      {/* {userInfo ? (
        userInfo.role == 'user' &&
        userInfo.plan !== 'premium' && (
          <script
            type="text/javascript"
            src="https://goraps.com/tup.php?section=General&pt=7&pub=311311&ga=g"
          ></script>
        )
      ) : (
        <script
          type="text/javascript"
          src="https://goraps.com/tup.php?section=General&pt=7&pub=311311&ga=g"
        ></script>
      )} */}
    </Helmet>
  );
};
Meta.defaultProps = {
  title: 'Wandart-The best online art, wallpapers and gallery community',
  keywords: 'Anime,wallpaper,wallpaper art,art',
};
export default Meta;
