export const USER_NOTIFICATION_LIST_REQUEST = "USER_NOTIFICATION_LIST_REQUEST";
export const USER_NOTIFICATION_LIST_FAIL = "USER_NOTIFICATION_LIST_FAIL";
export const USER_NOTIFICATION_LIST_SUCCESS = "USER_NOTIFICATION_LIST_SUCCESS";

export const NOTIFICATION_DELETE_REQUEST = "NOTIFICATION_DELETE_REQUEST";
export const NOTIFICATION_DELETE_FAIL = "NOTIFICATION_DELETE_FAIL";
export const NOTIFICATION_DELETE_SUCCESS = "NOTIFICATION_DELETE_SUCCESS";
export const NOTIFICATION_DELETE_RESET = "NOTIFICATION_DELETE_RESET";


  