import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "../components/Loader";
import Message from "../components/Message";
import SettingsNav from "../components/SettingsNav";
import ErrorMessage from "../components/forms/ErrorMessage";
import ScrollTop from "../components/ScrollTop";
import {
  getUserDetails,
  updateUserName,
  updateUserProfile,
  updateUserPassword,
  updateEmail,
} from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Footer from "../components/Footer";

function GeneralSettings(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [passwordCurrent2, setPasswordCurrent2] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user, error } = userDetails;
  const [birthdayDisplay, setBirthdayDisplay] = useState(user.showDob);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success,
    error: updateError,
    loading: updateLoading,
  } = userUpdateProfile;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user.userName) {
        dispatch(getUserDetails("me"));
      } else if (success) {
        setTimeout(() => {
          dispatch(getUserDetails("me"));
          dispatch({ type: USER_UPDATE_PROFILE_RESET });
        }, 2000);
      } else if (updateError) {
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_PROFILE_RESET });
        }, 2000);
        //   } else if (resendSuccess) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
        //   } else if (resendError) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
      } else {
      }
    }
  }, [dispatch, history, userInfo, success, updateError]);

  const passwordUpdateHandler = (a, b, c) => {
    dispatch(
      updateUserPassword({
        passwordCurrent: a,
        password: b,
        passwordConfirm: c,
      })
    );
  };
  const updateHandler = () => {
    dispatch(updateUserProfile({ showDob: birthdayDisplay }));
  };
  const emailUpdateHandler = () => {
    dispatch(updateEmail({ password: passwordCurrent, email }));
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
      )
      .label("New Password"),
    passwordCurrent2: Yup.string().required().label("Current Password"),
    passwordConfirm: Yup.string()
      .required()
      .min(8)
      .label("New Password Confirm")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <StyledGeneralSettings isDarkMode={isDarkMode}>
      <Meta title="Geeneral settings" />
      <div className="settings">
        <SettingsNav pathname={pathname} isDarkMode={isDarkMode} />
        {updateLoading && <Loader />}
        {success && (
          <Message variant="success">Profile updated successfully</Message>
        )}
        {updateError && <Message variant="danger">{updateError}</Message>}
        <div className="identity">
          <StyledCard isDarkMode={isDarkMode}>
            <h2>Personal Information</h2>
            <div className="input">
              <div className="center">
                <span>
                  <h3>Date of Birth</h3>
                </span>
                <h5>{new Date(user.dateOfBirth).toDateString()} </h5>
              </div>
            </div>
            <div className="input">
              <div className="center">
                <span>
                  <h3>Birthday Display</h3>
                </span>
                <select onChange={(e) => setBirthdayDisplay(e.target.value)}>
                  <option
                    value="plusYear"
                    selected={user.showDob == "plusYear"}
                  >
                    Show full birth day
                  </option>
                  <option
                    value="plusMonth"
                    selected={user.showDob == "plusMonth"}
                  >
                    Show only month and day
                  </option>
                  <option
                    value="dontShow"
                    selected={user.showDob == "dontShow"}
                  >
                    Dont show
                  </option>
                </select>
              </div>
            </div>

            {!changeEmail && (
              <div className="input">
                <div className="center">
                  <span>
                    <h3>Email</h3>
                  </span>
                  <h5>
                    {user.email}{" "}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => setChangeEmail(true)}
                    >
                      Change Email
                    </a>{" "}
                  </h5>
                </div>
              </div>
            )}
            {changeEmail && (
              <>
                <div className="input">
                  <div className="center">
                    <span>
                      <h3>New Email</h3>
                    </span>
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="center">
                    <span>
                      <h3>Password</h3>
                    </span>
                    <input
                      type="text"
                      value={passwordCurrent}
                      onChange={(e) => setPasswordCurrent(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}
            <span className="button">
              {!changeEmail && <button onClick={updateHandler}>Save</button>}
              {changeEmail && (
                <button onClick={emailUpdateHandler}>Change Email</button>
              )}
            </span>
          </StyledCard>
          <StyledCard isDarkMode={isDarkMode}>
            <h2>Change WandArt Password</h2>
            <Formik
              initialValues={{
                passwordCurrent2: "",
                password: "",
                passwordConfirm: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) =>
                passwordUpdateHandler(
                  values["passwordCurrent2"],
                  values["password"],
                  values["passwordConfirm"]
                )
              }
            >
              {({
                handleSubmit,
                handleChange,
                setFieldTouched,
                touched,
                errors,
              }) => (
                <>
                  <div className="input">
                    <div className="center">
                      <span>
                        <h3>Old Password</h3>
                      </span>
                      <div className="inputError">
                        <input
                          type="text"
                          onChange={handleChange("passwordCurrent2")}
                          onBlur={() => setFieldTouched("passwordCurrent2")}
                        />
                        <ErrorMessage
                          error={errors["passwordCurrent2"]}
                          visible={touched["passwordCurrent2"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <div className="center">
                      <span>
                        <h3>New Password</h3>
                      </span>
                      <div className="inputError">
                        <input
                          type="text"
                          onChange={handleChange("password")}
                          onBlur={() => setFieldTouched("password")}
                        />
                        <ErrorMessage
                          error={errors["password"]}
                          visible={touched["password"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input">
                    <div className="center">
                      <span>
                        <h3>Retype New</h3>
                      </span>
                      <div className="inputError">
                        <input
                          type="text"
                          onChange={handleChange("passwordConfirm")}
                          onBlur={() => setFieldTouched("passwordConfirm")}
                        />
                        <ErrorMessage
                          error={errors["passwordConfirm"]}
                          visible={touched["passwordConfirm"]}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="button">
                    <button onClick={handleSubmit}>Save</button>
                  </span>
                </>
              )}
            </Formik>
          </StyledCard>
        </div>
      </div>
      <Footer />
      <ScrollTop />
      <ScrollTop />
    </StyledGeneralSettings>
  );
}

const StyledGeneralSettings = styled(motion.div)`
  display: flex;
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

  flex-flow: column wrap;
  @media (max-width: 900px) {
    flex-flow: column wrap;
  }
  width: 100%;
  .settings {
    display: flex;
    padding-bottom: 5rem;
    width: 100%;
    @media (max-width: 900px) {
      flex-flow: column wrap;
      padding-bottom: 0rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
    }
  }
  .identity {
    flex: 80%;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 2rem 1rem;
    @media (max-width: 900px) {
      padding: 0.5rem;
      width: 100%;
    }
  }
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
  width: 40rem;
  min-height: 10rem;
  padding: 1.2rem;
  margin: 0.5rem 0rem;
  border-radius: 8px;
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  @media (max-width: 900px) {
    padding: 0.8rem;
    margin: 0.25rem 0rem;
    width: 100%;
  }
  h2 {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 0.6rem 0rem;
    font-size: 1.4rem;
    @media (max-width: 900px) {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  a {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    &:hover {
      color: #50c1e9;
    }
  }
  .button {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 0.35rem 1.3rem;
      margin-top: 0.6rem;
      background-color: #50c1e9;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      cursor: pointer;
      border: 2px solid #50c1e9;
      border-radius: 4px;
      &:hover {
        background-color: #75b1c7;
      }
      &:disabled {
        cursor: not-allowed;
        background-color: #75b1c7;
      }
    }
  }
  .input {
    display: flex;
    justify-content: center;
    padding: 0rem 0rem;
    @media (max-width: 900px) {
      padding: 0.3rem 0rem;
    }

    .center {
      display: flex;
      width: 100%;
      align-items: center;
      @media (max-width: 900px) {
        flex-flow: column wrap;
        width: 100%;
        align-items: flex-start;
      }
      .inputError {
        display: flex;
        flex-flow: column wrap;
        width: 70%;
        input {
          width: 100%;
        }
        @media (max-width: 900px) {
          width: 100%;
        }
      }
      h3 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-weight: 550;
        @media (max-width: 900px) {
          margin-top: 0.2rem;
        }
      }
      span {
        display: flex;
        font-size: 0.9rem;
        padding-right: 1rem;
        width: 30%;
        justify-content: flex-end;
        @media (max-width: 900px) {
          justify-content: flex-start;
          width: 100%;
        }
      }
      h5 {
        width: 70%;
        font-size: 1rem;
        font-weight: 500;
        @media (max-width: 900px) {
          width: 100%;
        }
      }
      select {
        @media (max-width: 900px) {
          width: 100%;
        }
        width: 70%;
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.5rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }
      input {
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.5rem;
        font-size: 0.9rem;
        border: 0.5px solid;
        margin: 0.5rem 0rem;
        width: 70%;
        @media (max-width: 900px) {
          width: 100%;
        }
        ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
        &:disabled {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#1b1b1b" : "#f8f8f8"};
          cursor: not-allowed;
        }
      }
    }
  }
  .talk {
    padding: 0.2rem 0rem;
    ul {
      padding: 0rem 1.2rem;
      li {
        padding: 0.1rem 0rem;
      }
    }
    h5 {
      font-size: 1rem;
      padding: 0.4rem 0rem;
      font-weight: 500;
    }
  }
`;
export default GeneralSettings;
