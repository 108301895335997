import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {} from "react-paystack";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components
import { TextField } from "@material-ui/core";

import Loader from "../components/Loader";
import Message from "../components/Message";
import ScrollTop from "../components/ScrollTop";
//actions
import { login } from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
import ErrorMessage from "../components/forms/ErrorMessage";

import {
  faAd,
  faIdBadge,
  faImage,
  faQuestionCircle,
  faShoppingCart,
  faStar,
  faTimes,
  faUniversalAccess,
  faUserEdit,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FaqSection from "../components/FaqSection";
import Footer from "../components/Footer";

const Premium = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const [checkout, setCheckout] = useState(false);
  const [plan, setPlan] = useState("");
  const [agree, setAgree] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {}, [history, userInfo, redirect]);
  const exitCheckoutHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setCheckout(false);
    }
  };
  const payWithCryptoHandler = async () => {
    if (!userInfo) {
      history.push("/login?redirect=premium");
    }
    const { data } = await axios.get(
      `https://api.wandart.art/api/v1/subscriptions/checkout-session-crypto/${plan}`
    );

    const link = document.createElement("a");
    link.href = data.response.hosted_url;

    link.click();
    setCheckout(false);
  };
  const payWithCardHandler = async () => {
    if (!userInfo) {
      history.push("/login?redirect=premium");
    }
    const { data } = await axios.get(
      `https://api.wandart.art/api/v1/subscriptions/checkout-session-card/${plan}`
    );
    const link = document.createElement("a");
    link.href = data.transaction.data.authorization_url;

    link.click();
    setCheckout(false);
  };
  return (
    <StyledPremium
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Premium" />
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <div className="intro">
        <h1>Become a Premium Member</h1>
        <FontAwesomeIcon icon={faStar} className="icon" />
      </div>

      <div className="cards">
        <StyledCard isDarkMode={isDarkMode}>
          <div class="card">
            <span className="icon">
              <Link to="/">
                <h3>Mon</h3>
                <h2>thly</h2>
              </Link>
            </span>
            <div class="premium-group">
              <span>Subscription($5/month)</span>
            </div>
            <div className="text">
              Our monthly plan grants access to all premium features
            </div>
            <button
              onClick={() => {
                setCheckout(true);
                setPlan("monthly");
              }}
            >
              Subscribe
            </button>
          </div>
        </StyledCard>
        <StyledCard isDarkMode={isDarkMode}>
          <div class="card">
            <span className="icon">
              <Link to="/">
                <h3>Yea</h3>
                <h2>rly</h2>
              </Link>
            </span>

            <div class="premium-group">
              <span>Subscription ($50/year)</span>
            </div>
            <div className="text">
              This plan saves you over 20% in comparison to the monthly plan
            </div>
            <button
              onClick={() => {
                setCheckout(true);
                setPlan("yearly");
              }}
            >
              Subscribe
            </button>
          </div>
        </StyledCard>
      </div>

      {checkout && (
        <StyledModal
          onClick={exitCheckoutHandler}
          className="shadow"
          isDarkMode={isDarkMode}
        >
          <div className="middle">
            <div className="title">
              <span>
                {" "}
                <FontAwesomeIcon
                  className="icon"
                  icon={faShoppingCart}
                  color="black"
                  onClick={() => setCheckout(false)}
                />
                Checkout
              </span>
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setCheckout(false)}
              />
            </div>
            <div className="terms">
              <h2>Agreement</h2>
              By starting the Premium Subscription, you agree to our
              <Link to="/terms"> Terms of Service</Link> and
              <Link to="/policy"> Privacy Statement</Link>. You may disable at
              any time during your subscription, an email will be sent to
              disable your subscription if you wish. Once disabled, your
              subscription will stay active until the end of billing period.
              Wandart will automatically renew your subscription at the end of
              your billing period and charge to your payment method on a
              "period" basis until you disable. There are no refunds for any
              amounts that have been charged for subscription disablement.
              Wandart may deny you access to all or any part of the Wandart
              service or terminate your account with or without prior notice if
              you engage in any conduct or activities that Wandart determines,
              in its sole discretion, violates Wandart's Terms of Service or
              Wandart's rules or is otherwise inappropriate. Without limitation,
              Wandart may deny you access to Wandart service or terminate your
              Wandart account, without providing any refund or partial refund.
              For any other billing questions, please contact
              <a href="mailto:support@wandart.art"> support@wandart.art</a>. We
              encourage commentary about people and matters of public interest,
              but abusive or otherwise inappropriate content directed at private
              individuals is not allowed. Do not share content that you do not
              have the right to share, claim content that you did not create as
              your own, or otherwise infringe or misappropriate someone else's
              intellectual property or other rights. Always attribute materials
              used or quoted by you to the original copyright owner.
            </div>
            <div className="footer">
              <span className="agree">
                <input
                  class="form-control"
                  type="checkbox"
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                />{" "}
                I agree to this agreement and{" "}
                <Link to="/privacy">Terms of Service</Link>
              </span>
              <div className="button">
                <span className="pay">Pay with</span>
                <span className="grp-button">
                  <button
                    disabled={agree == false}
                    onClick={payWithCryptoHandler}
                  >
                    Crypto
                  </button>
                  <button
                    disabled={agree == false}
                    onClick={payWithCardHandler}
                  >
                    Card
                  </button>
                </span>
              </div>
            </div>
          </div>
        </StyledModal>
      )}

      <div className="premiumBen">
        <h1>Premium benefits</h1>
        <div className="premiumGroup">
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faAd} />
            <h2>No ads</h2>
            <h3>Premium users will see no ads</h3>
          </div>
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faImage} />
            <h2>Submit Posts</h2>
            <h3>Upload your arts and get alot of people to interact</h3>
          </div>
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faUserSecret} />
            <h2>Display mature content</h2>
            <h3>Ability to see mature categories and their posts</h3>
          </div>
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faUserEdit} />
            <h2>Change Username</h2>
            <h3>Create a new username once every 60days</h3>
          </div>
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
            <h2>Priority Support</h2>
            <h3>Receive faster assistance with prioritized customer support</h3>
          </div>
          <div className="premiumCard">
            <FontAwesomeIcon className="icon" icon={faUniversalAccess} />
            <h2>Early Access to New Features</h2>
            <h3>Be among the first to try new or developing features</h3>
          </div>
        </div>
      </div>

      <div className="FAQ">
        <h2>Frequently Asked Questions</h2>
        <FaqSection />
      </div>
      <Footer />
      <ScrollTop />
    </StyledPremium>
  );
};

const StyledModal = styled(motion.div)`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  .middle {
    @media screen and (max-width: 900px) {
      width: 95%;
      height: 80vh;
      padding: 0.6rem 0rem;
      button {
        width: 6rem;
        padding: 0.4rem;
        border: 2px solid #50c1e9;
        border-radius: 4px;
        background: transparent;
        color: #50c1e9;
        font-size: 0.9rem;
        cursor: pointer;
        &:hover {
          color: white;
          background: #50c1e9;
        }
      }
    }
    width: 35rem;
    height: 90vh;
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    display: flex;
    flex-flow: column wrap;
    padding: 0.8rem 0.8rem;
    z-index: 20;
    border-radius: 4px;
    .title {
      font-size: 1.2rem;
      font-weight: 500;
      display: flex;
      height: 8%;
      padding: 0.4rem 1rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      @media screen and (max-width: 900px) {
        font-size: 1.3rem;
        padding: 0rem 1rem;
        align-items: center;
      }
      .icon {
        cursor: pointer;
        margin-left: auto;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
    }
    .terms {
      height: 75%;
      @media (max-width: 900px) {
        height: 72%;
        margin: 0rem 0.4rem;
        padding: 0.4rem;
      }
      border: 1.5px solid
        ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
      border-radius: 4px;
      padding: 0.8rem;
      overflow-y: scroll;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
      h2 {
        text-align: center;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 2rem;
        font-weight: 500;
      }
    }
    .footer {
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      height: 15%;
      align-items: center;
      padding: 0.4rem 0rem;
      @media (max-width: 900px) {
        height: 18%;
        padding: 1rem 0rem;
      }
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};

      .agree {
        @media (max-width: 900px) {
          padding-left: 0.8rem;
        }
      }
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
      input {
        cursor: pointer;
      }
      .button {
        margin-left: auto;
        display: flex;
        flex-flow: column wrap;
        @media (max-width: 900px) {
          padding-right: 0.8rem;
        }
        .pay {
          padding: 0.2rem 0rem;
          @media (max-width: 900px) {
            padding: 0.2rem 0rem;
          }
        }
        .grp-button {
          display: flex;
          @media (max-width: 900px) {
            flex-flow: column wrap;
          }
        }
        button {
          @media (max-width: 900px) {
            margin-bottom: 0.2em;
            margin-right: 0rem;
          }
          padding: 0.3rem 0.6rem;
          margin-right: 0.3rem;
          border: 2px solid #50c1e9;
          border-radius: 5px;
          font-size: 0.95rem;
          background: #50c1e9;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          cursor: pointer;
          &:hover {
            background: #4cb1d6;
          }
          &:disabled {
            background: #4cb1d6;
            cursor: auto;
          }
        }
      }
    }
  }
`;

const StyledPremium = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  display: flex;
  flex-flow: column wrap;
  .intro {
    padding: 4rem;
    flex-flow: column wrap;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      padding: 1rem 2rem;
    }
    h1 {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      text-align: center;
      font-size: 2.6rem;
      @media (max-width: 900px) {
        font-size: 1.6rem;
      }
    }
    .icon {
      color: #50c1e9;
      font-size: 4rem;
      margin: 0.5rem;
      @media (max-width: 900px) {
        font-size: 3rem;
        margin: 0.5rem;
      }
    }
  }
  .cards {
    display: flex;
    justify-content: center;
    @media (max-width: 900px) {
      flex-flow: column wrap;
    }
  }
  .premiumBen {
    h1 {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      text-align: center;
      padding-top: 3rem;
      font-size: 2.2rem;
      @media (max-width: 900px) {
        font-size: 1.6rem;
      }
    }
    .premiumGroup {
      display: flex;
      flex-flow: row wrap;
      padding: 0rem 5rem;
      @media (max-width: 900px) {
        padding: 0rem 0rem;
      }
      .premiumCard {
        flex: 33.33%;
        @media (max-width: 900px) {
          flex: 50%;
          padding: 1rem 0.7rem;
        }
        padding: 2rem 1.5rem;
        display: flex;
        flex-flow: column wrap;
        .icon {
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
          padding: 0.3rem 0rem;
          font-size: 8rem;
          @media (max-width: 900px) {
            font-size: 5rem;
            margin-left: auto;
            margin-right: auto;
          }
        }
        h2 {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          font-size: 1.6rem;
          padding: 0.3rem 0rem;
          @media (max-width: 900px) {
            text-align: center;
          }
        }
        h3 {
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
          font-size: 1.1rem;
          @media (max-width: 900px) {
            text-align: center;
          }
        }
      }
    }
  }
  .FAQ {
    padding: 6rem 0rem;
    display: flex;
    flex-flow: column wrap;
    @media (max-width: 900px) {
      padding: 3rem 0rem;
    }
    h2 {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 2.5rem;
      @media (max-width: 900px) {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1.6rem;
        padding: 0.3rem 1rem;
      }
      padding: 0.3rem 0rem;
      text-align: center;
      font-weight: 600;
    }
  }
`;

const StyledCard = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0.25rem 0.5rem;
  }
  .card {
    display: flex;
    width: 27rem;
    @media (max-width: 900px) {
      width: 100%;
    }
    flex-flow: column wrap;
    justify-content: center;
    .icon {
      font-size: 1.5rem;
      padding: 1rem;
      display: flex;
      justify-content: center;
      width: 100%;
      h2 {
        font-weight: 400;
        font-size: 1.5rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
      h3 {
        color: #50c1e9;
        font-weight: 700;
        font-size: 1.5rem;
      }
      a {
        display: flex;
        align-items: center;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
    }

    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    padding: 0.9rem 1.5rem;
    @media (max-width: 900px) {
      padding: 1rem 1rem;
    }
    border-radius: 4px;
    color: white;
    h3 {
      text-align: center;
      font-weight: 500;
      font-size: 1.3rem;
    }
    button {
      padding: 0.7rem;
      border: 2px solid #50c1e9;
      border-radius: 5px;
      margin: 0.6rem 0rem;
      font-size: 0.95rem;
      background: #50c1e9;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      cursor: pointer;
      &:hover {
        background: #4cb1d6;
      }
    }
    .form-group {
      display: flex;
      flex-flow: column wrap;
      padding: 0.6rem 0rem;
      input {
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }
    }
    .premium-group {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 0.6rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      input {
        margin-left: auto;
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 2rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }
    }
    .forReg {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem 0rem;
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 0.9rem;
        &:hover {
          color: #50c1e9;
        }
      }
    }

    .text {
      padding: 0rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
      font-size: 0.9rem;

      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
    }
  }
`;
export default Premium;
