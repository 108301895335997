import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
//components

import Loader from '../components/Loader';
import Message from '../components/Message';
import CartMessage from '../components/CartMessage';
import Paginate from '../components/Paginate';
import ScrollTop from '../components/ScrollTop';
//actions
import { getUserDetails, listUsers } from '../actions/userActions';
import { logout, deleteUser } from '../actions/userActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { profileAnimation } from '../animations';
import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { USER_DELETE_RESET } from '../constants/userConstants';
import Meta from '../components/Meta';
import AdminNav from '../components/AdminNav';
import ScrollToTop from '../components/ScrollToTop';

const AdminUserList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();
  const isDarkMode = useSelector((state) => state.isDark);
  const [searchUserId, setSearchUserId] = useState('');
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userList = useSelector((state) => state.userList);
  const { success, error, loading, users, page, pages } = userList;
  const userDetails = useSelector((state) => state.userDetails);
  const userDelete = useSelector((state) => state.userDelete);
  const {
    success: successDelete,
    error: errorDelete,
    loading: loadingDelete,
  } = userDelete;

  const search = location.search;
  const pageNumber =
    search && search.startsWith('?page') && search.split('=')[1];

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else if (userInfo.role === 'user') {
      history.push('/');
    } else if (successDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
        dispatch(listUsers(searchUserId || ''));
      }, 2000);
    } else if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: USER_DELETE_RESET });
      }, 2000);
    } else {
      dispatch(getUserDetails('me'));
      dispatch(listUsers(searchUserId || ''));
    }
  }, [dispatch, userInfo, history, successDelete, errorDelete, searchUserId]);

  const logoutHandler = () => {
    dispatch(logout());
  };
  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(id));
    }
  };
  return (
    <StyledProfile
      variants={profileAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="User list" />
      {error && <Message variant="danger">{error}</Message>}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {successDelete && (
        <Message variant="success">User deleted successfully</Message>
      )}
      {loadingDelete && <Loader />}
      {userDetails.error && (
        <Message variant="danger">{userDetails.error}</Message>
      )}
      {loading && <Loader />}
      <StyledMain isDarkMode={isDarkMode}>
        <AdminNav pathname={pathname} isDarkMode={isDarkMode} />
        <StyledRight isDarkMode={isDarkMode}>
          <div className="row">
            <span className="bar">
              <h3>
                Users{' '}
                {userInfo.role == 'admin' && (
                  <>
                    {' '}
                    {users.length},
                    {users.filter((user) => user.plan == 'premium').length},
                    {users.filter((user) => user.role !== 'user').length}
                  </>
                )}
              </h3>
              <div className="formGroup">
                <input
                  type="search"
                  placeholder="Search User by Username"
                  value={searchUserId}
                  onChange={(e) => setSearchUserId(e.target.value)}
                />

                <button type="submit">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </span>
            {users.length === 0 ? (
              <CartMessage>No User Available</CartMessage>
            ) : (
              <div className="flex">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>USERNAME</th>
                      <th>EMAIL</th>
                      <th>ADMIN</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user._id}>
                        <td>{user._id}</td>
                        <td>
                          <Link to={`/${user.userName}`}>{user.userName}</Link>
                        </td>
                        <td>
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </td>
                        <td>{user.role}</td>
                        <td>
                          <Link to={`/admin/user/${user.userName}/edit`}>
                            <button>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </Link>
                          <button
                            className="red"
                            onClick={() => deleteHandler(user._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </StyledRight>
      </StyledMain>
      <ScrollTop />
      <ScrollToTop />
    </StyledProfile>
  );
};
const StyledProfile = styled(motion.div)`
  min-height: 81vh;
`;
const StyledMain = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
`;

const StyledRight = styled(motion.div)`
  @media screen and (max-width: 900px) {
    padding: 0rem;
  }
  .row {
    padding: 1rem 2rem;
    color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
    background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
    width: 100%;
    @media screen and (max-width: 900px) {
      border-radius: 0px;
      padding: 1rem 0.4rem;
    }
    .bar {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 900px) {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        h3 {
          display: none;
        }
      }
      .formGroup {
        @media screen and (max-width: 900px) {
          padding: 0.4rem 0rem;
        }
        input {
          width: 15rem;
          padding: 0.4rem;
          border-right: none;
          border-radius: 5px 0px 0px 5px;
          border: 1px solid
            ${({ isDarkMode }) => (isDarkMode ? '#868686' : '#d4d4d4')};
          background: none;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
          }
        }
        button {
          width: 3rem;
          padding: 0.4rem;
          background-color: #50c1e9;
          border-radius: 0px 5px 5px 0px;
          border: 1px solid
            ${({ isDarkMode }) => (isDarkMode ? '#868686' : '#d4d4d4')};
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &:hover {
            background: #4cb1d6;
          }
        }
      }
    }
    h3 {
      text-align: center;
      color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
      font-size: 1.6rem;
      font-weight: 500;
      span {
        padding: 0rem 0.4rem;
      }
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }
    .flex {
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 0.85rem;
        margin: 1rem 0rem;
        @media screen and (max-width: 900px) {
          margin: 0rem 0rem;
          width: 100%;
          word-break: break-word;
          font-size: 0.52rem;
        }
        td,
        th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 0.6rem 0.4rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          @media screen and (max-width: 900px) {
            padding: 0.3rem 0.2rem;
            word-break: break-word;
          }
          a {
            color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
            &:hover {
              color: #50c1e9;
            }
          }
        }
        button {
          padding: 0.4rem;
          font-size: 0.85rem;
          margin: 0.2rem;
          background-color: #50c1e9;
          cursor: pointer;
          border: 2px solid #50c1e9;
          border-radius: 4px;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &:hover {
            background-color: #75b1c7;
          }
          @media screen and (max-width: 900px) {
            padding: 0.3rem 0.2rem;
            font-size: 0.4rem;
          }
        }
        .red {
          background-color: red;
          &:hover {
            background-color: #f10606;
          }
        }
      }
    }
  }
`;

export default AdminUserList;
