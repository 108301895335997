import React, { useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
//Redux
import { useDispatch, useSelector } from 'react-redux';

//components

import Loader from '../components/Loader';
import ErrorMessage from '../components/forms/ErrorMessage';
import Message from '../components/Message';
import ScrollTop from '../components/ScrollTop';
//actions
import { resetPassword } from '../actions/userActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
import { USER_RESET_PASSWORD_RESET } from '../constants/userConstants';
import Meta from '../components/Meta';
import whiteLogo from '../images/logoWhite.png';
import darkLogo from '../images/logoDark.png';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const isDarkMode = useSelector((state) => state.isDark);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userResetPassword = useSelector((state) => state.userResetPassword);

  const { loading, error, success } = userResetPassword;

  const resetToken = location.pathname.split('/')[2];
  useEffect(() => {
    if (userInfo) {
      history.push('/');
    } else if (success) {
      setTimeout(() => {
        dispatch({ type: USER_RESET_PASSWORD_RESET });
        history.push('/login');
      }, 2000);
    } else if (error) {
      setTimeout(() => {
        dispatch({ type: USER_RESET_PASSWORD_RESET });
      }, 2000);
    }
  }, [history, userInfo, resetToken, success, error]);
  const submitHandler = ({ password, passwordConfirm }) => {
    //Dispatch register
    dispatch(resetPassword({ password, passwordConfirm }, resetToken));
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character'
      )
      .label('Password'),
    passwordConfirm: Yup.string()
      .required()
      .min(8)
      .label('Confirm Password')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
  });
  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Reset password" />
      {error && <Message variant="danger">{error}</Message>}
      {success && (
        <Message variant="success">
          Password resetted successfully, Login
        </Message>
      )}

      {loading && <Loader />}
      <div class="login">
        <Formik
          initialValues={{
            password: '',

            passwordConfirm: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => submitHandler(values)}
          class="loginForm"
        >
          {({
            handleSubmit,
            handleChange,
            setFieldTouched,
            touched,
            errors,
          }) => (
            <div class="loginForm">
              <span className="icon">
                <Link to="/">
                  {isDarkMode ? (
                    <img src={whiteLogo} />
                  ) : (
                    <img src={darkLogo} />
                  )}
                </Link>
              </span>

              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  onChange={handleChange('password')}
                  onBlur={() => setFieldTouched('password')}
                />
                <ErrorMessage
                  error={errors['password']}
                  visible={touched['password']}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Confirm password"
                  type="password"
                  onChange={handleChange('passwordConfirm')}
                  onBlur={() => setFieldTouched('passwordConfirm')}
                />
                <ErrorMessage
                  error={errors['passwordConfirm']}
                  visible={touched['passwordConfirm']}
                />
              </div>

              <button onClick={handleSubmit}>Reset password</button>

              <div class="text">
                By continuing, you agree to Wandart's
                <Link to="terms-and-conditions"> Conditions of Use</Link> and
                <Link to="/privacy"> Privacy Notice.</Link>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  min-height: 81vh;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  display: flex;
  justify-content: center;
  padding: 1rem;
  @media screen and (max-width: 900px) {
    padding: 0rem;

    background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
  }
  .login {
    width: 27rem;
    @media screen and (max-width: 900px) {
      width: 100%;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 900px) {
          display: none;
        }
        img {
          width: 7rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      @media screen and (max-width: 900px) {
        padding: 1rem 1.2rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
      }
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        input {
          background: ${({ isDarkMode }) =>
            isDarkMode ? '#0f0f0f' : '#ffffff'};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
          }
        }
        .date {
          ::-webkit-calendar-picker-indicator {
            filter: ${({ isDarkMode }) =>
              isDarkMode ? 'invert(1)' : 'invert(0)'};
            cursor: pointer;
          }
        }
      }
      .forReg {
        display: flex;
        justify-content: center;
        padding: 0.4rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
        font-size: 0.9rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          font-size: 0.9rem;
          padding: 0rem 0.2rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default ResetPassword;
