export const SUBSCRIPTION_LIST_REQUEST = 'SUBSCRIPTION_LIST_REQUEST';
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL';
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS';

export const SUBSCRIPTION_DETAILS_REQUEST = 'SUBSCRIPTION_DETAILS_REQUEST';
export const SUBSCRIPTION_DETAILS_FAIL = 'SUBSCRIPTION_DETAILS_FAIL';
export const SUBSCRIPTION_DETAILS_SUCCESS = 'SUBSCRIPTION_DETAILS_SUCCESS';

export const SUBSCRIPTION_DISABLE_REQUEST = 'SUBSCRIPTION_DISABLE_REQUEST';
export const SUBSCRIPTION_DISABLE_FAIL = 'SUBSCRIPTION_DISABLE_FAIL';
export const SUBSCRIPTION_DISABLE_SUCCESS = 'SUBSCRIPTION_DISABLE_SUCCESS';
export const SUBSCRIPTION_DISABLE_RESET = 'SUBSCRIPTION_DISABLE_RESET';

export const SUBSCRIPTION_ENABLE_REQUEST = 'SUBSCRIPTION_ENABLE_REQUEST';
export const SUBSCRIPTION_ENABLE_FAIL = 'SUBSCRIPTION_ENABLE_FAIL';
export const SUBSCRIPTION_ENABLE_SUCCESS = 'SUBSCRIPTION_ENABLE_SUCCESS';
export const SUBSCRIPTION_ENABLE_RESET = 'SUBSCRIPTION_ENABLE_RESET';
