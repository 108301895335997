export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const FOLLOW_USER_REQUEST = "FOLLOW_USER_REQUEST";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAIL = "FOLLOW_USER_FAIL";
export const FOLLOW_USER_RESET = "FOLLOW_USER_RESET";

export const UNFOLLOW_USER_REQUEST = "UNFOLLOW_USER_REQUEST";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAIL = "UNFOLLOW_USER_FAIL";
export const UNFOLLOW_USER_RESET = "UNFOLLOW_USER_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";
export const USER_FORGOT_PASSWORD_RESET = "USER_FORGOT_PASSWORD_RESET";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_RESET_PASSWORD_RESET = "USER_RESET_PASSWORD_RESET";

export const USER_CONFIRM_EMAIL_REQUEST = "USER_CONFIRM_EMAIL_REQUEST";
export const USER_CONFIRM_EMAIL_SUCCESS = "USER_CONFIRM_EMAIL_SUCCESS";
export const USER_CONFIRM_EMAIL_FAIL = "USER_CONFIRM_EMAIL_FAIL";
export const USER_CONFIRM_EMAIL_RESET = "USER_CONFIRM_EMAIL_RESET";

export const USER_RESEND_CONFIRM_EMAIL_REQUEST =
  "USER_RESEND_CONFIRM_EMAIL_REQUEST";
export const USER_RESEND_CONFIRM_EMAIL_SUCCESS =
  "USER_RESEND_CONFIRM_EMAIL_SUCCESS";
export const USER_RESEND_CONFIRM_EMAIL_FAIL = "USER_RESEND_CONFIRM_EMAIL_FAIL";
export const USER_RESEND_CONFIRM_EMAIL_RESET =
  "USER_RESEND_CONFIRM_EMAIL_RESET";
