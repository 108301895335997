import React, { useState } from "react";
import { Link } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
//actions
import { reportPost } from "../actions/postActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
function FollowersModal({ on, setOn, title, followers }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [more, setMore] = useState("");
  const [reportPage, setReportPage] = useState("/");
  const exitModalHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      setOn(false);
      setValue2("");
    }
  };
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledModal onClick={exitModalHandler} isDarkMode={isDarkMode}>
      {on && (
        <div className="modal shadow">
          <div className="middle">
            <div className="title">
              {title} {followers.length}
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setOn(false)}
              />
            </div>
            <ModalChildren isDarkMode={isDarkMode}>
              <div className="followers">
                {followers.map((follower) => (
                  <span className="follower">
                    <Link to={`/${follower.userName}`}>
                      {" "}
                      <img src={follower.photo.url} />{" "}
                    </Link>
                    <h3>
                      {" "}
                      <Link to={`/${follower.userName}`}>
                        {follower.userName}
                      </Link>
                    </h3>
                  </span>
                ))}
              </div>
            </ModalChildren>
          </div>
        </div>
      )}
    </StyledModal>
  );
}
export default FollowersModal;

const ModalChildren = styled.div`
  .followers {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 1rem;
    max-height: 16rem;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    img {
      width: 2rem;
      cursor: pointer;
      border-radius: 4px;
    }
    .follower {
      display: flex;
      padding: 0.5rem 0rem;
      align-items: center;
      h3 {
        padding-left: 1rem;
        cursor: pointer;
        font-size: 1.2rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }
    }
  }
`;

const StyledModal = styled(motion.div)`
  .modal {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    img {
      height: 100%;
      object-fit: cover;
    }
    .middle {
      @media screen and (max-width: 900px) {
        padding: 0.6rem 0rem;
        width: 80%;
      }
      width: 32rem;
      min-height: 14rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      display: flex;
      flex-flow: column wrap;
      padding: 1.5rem 0rem;
      z-index: 20;
      .title {
        height: 15%;
        font-size: 1.4rem;
        font-weight: 600;
        display: flex;
        padding: 0rem 2rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          padding: 0rem 1rem;
          align-items: center;
        }
        .icon {
          cursor: pointer;
          margin-left: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }
    }
  }
`;
