
import {

  COMMENT_LIST_REQUEST,
COMMENT_LIST_SUCCESS,
COMMENT_LIST_FAIL,

  COMMENT_DELETE_FAIL,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_REQUEST,
  COMMENT_DELETE_RESET,

  COMMENT_CREATE_FAIL,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_REQUEST,
  COMMENT_CREATE_RESET,

  COMMENT_UPDATE_FAIL,
  COMMENT_UPDATE_SUCCESS,
  COMMENT_UPDATE_REQUEST,
  COMMENT_UPDATE_RESET,

UNLIKE_COMMENT_REQUEST,
UNLIKE_COMMENT_FAIL,
UNLIKE_COMMENT_SUCCESS,
UNLIKE_COMMENT_RESET,

LIKE_COMMENT_REQUEST,
LIKE_COMMENT_SUCCESS,
LIKE_COMMENT_FAIL,
LIKE_COMMENT_RESET

} from "../constants/commentConstants";
export const commentListReducer = (state = { comments:[] }, action) => {
  switch (action.type) {
    case COMMENT_LIST_REQUEST:
      return { loading: true, ...state };
    case COMMENT_LIST_SUCCESS:
      return { loading: false, comments: action.payload.data.doc,
        pages:action.payload.data.pages,page:action.payload.data.page};
    case COMMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const commentDeleteReducer=(state={},action)=>{
    switch (action.type) {
      case COMMENT_DELETE_REQUEST:
        return { loading: true };
      case COMMENT_DELETE_SUCCESS:
        return { loading: false, success:true};
      case COMMENT_DELETE_FAIL:
        return { loading: false, error: action.payload };
        case COMMENT_DELETE_RESET:
          return {};
      default:
        return state;
    }
  } 
  
  export const commentCreateReducer=(state={comment:{}},action)=>{
    switch (action.type) {
      case COMMENT_CREATE_REQUEST:
        return { loading: true };
      case COMMENT_CREATE_SUCCESS:
        return { loading: false,success:true,comment:action.payload.data };
      case COMMENT_CREATE_FAIL:
        return { loading: false, error: action.payload };
        case COMMENT_CREATE_RESET:
          return { product:{} };
      default:
        return state;
    }
  } 
  export const commentUpdateReducer=(state={comment:{}},action)=>{
    switch (action.type) {
      case COMMENT_UPDATE_REQUEST:
        return { loading: true };
      case COMMENT_UPDATE_SUCCESS:
        return { loading: false,success:true,comment:action.payload.data };
      case COMMENT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
        case COMMENT_UPDATE_RESET:
          return { product:{} };
      default:
        return state;
    }
  } 
  

  export const likeCommentReducer=(state={},action)=>{
    switch (action.type) {
      case LIKE_COMMENT_REQUEST:
        return { loading: true };
      case LIKE_COMMENT_SUCCESS:
        return { loading: false, success:true};
      case LIKE_COMMENT_FAIL:
        return { loading: false, error: action.payload };
        case LIKE_COMMENT_RESET:
          return {};
      default:
        return state;
    }
  } 
  
  export const unlikeCommentReducer=(state={},action)=>{
    switch (action.type) {
      case UNLIKE_COMMENT_REQUEST:
        return { loading: true };
      case UNLIKE_COMMENT_SUCCESS:
        return { loading: false, success:true};
      case UNLIKE_COMMENT_FAIL:
        return { loading: false, error: action.payload };
        case UNLIKE_COMMENT_RESET:
          return {};
      default:
        return state;
    }
  } 