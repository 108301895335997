import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//components

import Loader from "../components/Loader";
import Message from "../components/Message";
import CartMessage from "../components/CartMessage";
import Paginate from "../components/Paginate";
import AdminNav from "../components/AdminNav";
//actions
import { getUserDetails } from "../actions/userActions";
import { logout } from "../actions/userActions";
import { listCategories, deleteCategory } from "../actions/categoryActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { profileAnimation } from "../animations";
import {
  faEdit,
  faSearch,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import {
  CATEGORY_DELETE_RESET,
  CATEGORY_UPDATE_RESET,
} from "../constants/categoryConstants";
import Meta from "../components/Meta";
import ScrollToTop from "../components/ScrollToTop";
import ScrollTop from "../components/ScrollTop";

const AdminCategoryList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const history = useHistory();
  const isDarkMode = useSelector((state) => state.isDark);
  const [searchCategory, setSearchCategory] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const categoryList = useSelector((state) => state.categoryList);
  const { success, error, loading, categories } = categoryList;

  const userDetails = useSelector((state) => state.userDetails);
  const categoryDelete = useSelector((state) => state.categoryDelete);
  const {
    success: successDelete,
    error: errorDelete,
    loading: loadingDelete,
  } = categoryDelete;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = categoryUpdate;

  const search = location.search;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else if (userInfo.role === "user") {
      history.push("/");
    } else if (successDelete) {
      setTimeout(() => {
        dispatch({ type: CATEGORY_DELETE_RESET });
        dispatch(listCategories({ isMature: true, searchCategory }));
      }, 2000);
    } else if (errorDelete) {
      setTimeout(() => {
        dispatch({ type: CATEGORY_DELETE_RESET });
      }, 2000);
    } else if (updateSuccess || updateError) {
      setTimeout(() => {
        dispatch({ type: CATEGORY_UPDATE_RESET });
      }, 2000);
    } else {
      dispatch(getUserDetails("me"));
      dispatch(listCategories({ isMature: true, searchCategory }));
    }
  }, [
    dispatch,
    userInfo,
    history,
    successDelete,
    errorDelete,

    searchCategory,
    updateSuccess,
    updateError,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      //Delete product
      dispatch(deleteCategory(id));
    }
  };

  return (
    <StyledProfile
      variants={profileAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Category list" />
      {error && <Message variant="danger">{error}</Message>}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {successDelete && (
        <Message variant="success">User deleted successfully</Message>
      )}
      {updateError && <Message variant="danger">{updateError}</Message>}
      {updateSuccess && (
        <Message variant="success">Post approved successfully</Message>
      )}
      {loadingDelete && <Loader />}
      {updateLoading && <Loader />}
      {userDetails.error && (
        <Message variant="danger">{userDetails.error}</Message>
      )}

      <StyledMain isDarkMode={isDarkMode}>
        <AdminNav pathname={pathname} isDarkMode={isDarkMode} />
        {loading ? (
          <Loader />
        ) : (
          <StyledRight isDarkMode={isDarkMode}>
            <div className="row">
              <span className="bar">
                <h3>Categories {categories.length}</h3>
                <Link to="/admin/createcategory">Create Category</Link>
                <div className="formGroup">
                  <input
                    type="search"
                    placeholder="Search Category by name"
                    value={searchCategory}
                    onChange={(e) => setSearchCategory(e.target.value)}
                  />

                  <button type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </span>
              {categories.length === 0 ? (
                <CartMessage>No Category Available</CartMessage>
              ) : (
                <div className="flex">
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>DESCRIPTION</th>

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category) => (
                        <tr key={category._id}>
                          <td>{category._id}</td>
                          <td>
                            <Link to={`/category/${category.slug}`}>
                              {category.name}
                            </Link>
                          </td>
                          <td>{category.description}</td>

                          <td>
                            <Link to={`/admin/category/${category.slug}/edit`}>
                              <button>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                            </Link>
                            <button
                              className="red"
                              onClick={() => deleteHandler(category._id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </StyledRight>
        )}
      </StyledMain>
      <ScrollToTop />
      <ScrollTop />
    </StyledProfile>
  );
};
const StyledProfile = styled(motion.div)`
  min-height: 81vh;
`;
const StyledMain = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
`;

const StyledRight = styled(motion.div)`
  @media screen and (max-width: 900px) {
    padding: 0rem;
  }
  .row {
    padding: 1rem 2rem;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    width: 100%;
    @media screen and (max-width: 900px) {
      border-radius: 0px;
      padding: 1rem 0.4rem;
    }
    .bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 900px) {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        h3 {
          display: none;
        }
      }
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &:hover {
          color: #50c1e9;
        }
      }
      .approve {
        margin-left: auto;
        margin-right: auto;
        .active {
          color: #50c1e9;
        }
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          padding: 0rem 0.4rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }
      .formGroup {
        @media screen and (max-width: 900px) {
          padding: 0.4rem 0rem;
        }
        input {
          width: 15rem;
          padding: 0.4rem;
          border-right: none;
          border-radius: 5px 0px 0px 5px;
          border: 1px solid
            ${({ isDarkMode }) => (isDarkMode ? "#868686" : "#d4d4d4")};
          background: none;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
          }
        }
        button {
          width: 3rem;
          padding: 0.4rem;
          background-color: #50c1e9;
          border-radius: 0px 5px 5px 0px;
          border: 1px solid
            ${({ isDarkMode }) => (isDarkMode ? "#868686" : "#d4d4d4")};
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &:hover {
            background: #4cb1d6;
          }
        }
      }
    }
    h3 {
      text-align: center;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 1.6rem;
      font-weight: 500;

      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }
    .flex {
      table {
        border-collapse: collapse;
        width: 100%;
        font-size: 0.85rem;
        margin: 1rem 0rem;
        @media screen and (max-width: 900px) {
          margin: 0rem 0rem;
          width: 100%;
          word-break: break-word;
          font-size: 0.52rem;
        }
        td,
        th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 0.6rem 0.4rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          @media screen and (max-width: 900px) {
            padding: 0.3rem 0.2rem;
            word-break: break-word;
          }
          a {
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            &:hover {
              color: #50c1e9;
            }
          }
        }
        button {
          padding: 0.4rem;
          font-size: 0.85rem;
          margin: 0.2rem;
          background-color: #50c1e9;
          cursor: pointer;
          border: 2px solid #50c1e9;
          border-radius: 4px;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &:hover {
            background-color: #75b1c7;
          }
          @media screen and (max-width: 900px) {
            padding: 0.3rem 0.2rem;
            font-size: 0.4rem;
          }
        }
        .red {
          background-color: red;
          &:hover {
            background-color: #f10606;
          }
        }
      }
    }
  }
`;

export default AdminCategoryList;
