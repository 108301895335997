import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
//components
import Rating from "../components/Rating";

import Loader from "../components/Loader";
import LikeLoader from "../components/LikeLoader";
import Footer from "../components/Footer";
import Message from "../components/Message";
import Comment from "../components/Comment";
import ShareModal from "../components/ShareModal";
import ScrollTop from "../components/ScrollTop";
import ReportModal from "../components/ReportModal";
import ScrollToTop from "../components/ScrollToTop";
import HashNavigation from "../components/HashNavigation";
//actions
import {
  likePost,
  unLikePost,
  listPostDetails,
  listUserPosts,
  listSimilarPosts,
} from "../actions/postActions";
import {
  followUser,
  getUserDetails,
  unFollowUser,
} from "../actions/userActions";
import {
  listComments,
  unLikeComment,
  createComment,
} from "../actions/commentActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";

import { pageAnimation } from "../animations";
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_DELETE_REVIEW_RESET,
  REPORT_POST_RESET,
} from "../constants/postConstants";
import CartMessage from "../components/CartMessage";
import Meta from "../components/Meta";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faCommentAlt,
  faHeart,
  faTrash,
  faChevronLeft,
  faTimes,
  faDownload,
  faShare,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import SendIcon from "@material-ui/icons/Send";
import { HashLink } from "react-router-hash-link";

const PostDetails = () => {
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [imgZoom, setImgZoom] = useState(false);
  const [imgZoomFull, setImgZoomFull] = useState(false);
  const [shareOn, setShareOn] = useState(false);
  const [reportOn, setReportOn] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const { hash } = useLocation();
  const pathname = location.pathname;
  const isMature = useSelector((state) => state.displayMature);
  const id = location.pathname.split("/")[4];
  const paramUserName = location.pathname.split("/")[1];
  const postDetails = useSelector((state) => state.postDetails);
  const { loading, post, error } = postDetails;
  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;
  const userPostList = useSelector((state) => state.userPostList);
  const {
    loading: userPostsLoading,
    posts: userPosts,
    error: userPostsError,
  } = userPostList;

  const similarPostList = useSelector((state) => state.similarPostList);
  const {
    loading: similarPostsLoading,
    posts: similarPosts,
    error: similarPostsError,
  } = similarPostList;

  const commentList = useSelector((state) => state.commentList);
  const {
    loading: commentsLoading,
    comments: postComments,
    error: commentsError,
  } = commentList;

  const likeComment = useSelector((state) => state.likeComment);
  const {
    loading: likeCommentLoading,
    success: likeCommentSuccess,
    error: likeCommentError,
  } = likeComment;

  const unlikeComment = useSelector((state) => state.unlikeComment);
  const {
    loading: unlikeCommentLoading,
    success: unlikeCommentSuccess,
    error: unlikeCommentError,
  } = unlikeComment;

  const likeReply = useSelector((state) => state.likeReply);
  const {
    loading: likeReplyLoading,
    success: likeReplySuccess,
    error: likeReplyError,
  } = likeReply;

  const unlikeReply = useSelector((state) => state.unlikeReply);
  const {
    loading: unlikeReplyLoading,
    success: unlikeReplySuccess,
    error: unlikeReplyError,
  } = unlikeReply;

  const commentDelete = useSelector((state) => state.commentDelete);
  const {
    loading: commentDeleteLoading,
    success: commentDeleteSuccess,
    error: commentDeleteError,
  } = commentDelete;

  const replyDelete = useSelector((state) => state.replyDelete);
  const {
    loading: replyDeleteLoading,
    success: replyDeleteSuccess,
    error: replyDeleteError,
  } = replyDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const likerPost = useSelector((state) => state.likePost);
  const {
    loading: likeLoading,
    success: likeSuccess,
    error: likeError,
  } = likerPost;
  const unlikePost = useSelector((state) => state.unlikePost);
  const {
    loading: unlikeLoading,
    success: unlikeSuccess,
    error: unlikeError,
  } = unlikePost;

  const _followUser = useSelector((state) => state.followUser);
  const {
    loading: followLoading,
    success: followSuccess,
    error: followError,
  } = _followUser;
  const _unFollowUser = useSelector((state) => state.unFollowUser);
  const {
    loading: unfollowLoading,
    success: unfollowSuccess,
    error: unfollowError,
  } = _unFollowUser;

  const _createComment = useSelector((state) => state.commentCreate);
  const {
    loading: createCommentLoading,
    success: createCommentSuccess,
    error: createCommentError,
  } = _createComment;

  const _updateComment = useSelector((state) => state.commentUpdate);
  const {
    loading: updateCommentLoading,
    success: updateCommentSuccess,
    error: updateCommentError,
  } = _updateComment;

  const _updateReply = useSelector((state) => state.replyUpdate);
  const {
    loading: updateReplyLoading,
    success: updateReplySuccess,
    error: updateReplyError,
  } = _updateReply;

  const _createReply = useSelector((state) => state.replyCreate);
  const {
    loading: createReplyLoading,
    success: createReplySuccess,
    error: createReplyError,
  } = _createReply;

  const reportPost = useSelector((state) => state.reportPost);
  const {
    loading: reportLoading,
    success: reportSuccess,
    error: reportError,
  } = reportPost;

  useEffect(() => {
    dispatch(listPostDetails(id));
    dispatch(listUserPosts({ userName: paramUserName, limit: 9, isMature }));
    dispatch(listSimilarPosts({ id, limit: 9, isMature }));
    dispatch(listComments(id));
    dispatch(getUserDetails("me"));

    if (reportSuccess) {
      setTimeout(() => {
        dispatch({ type: REPORT_POST_RESET });
      }, 2000);
    }

    if (window.location.href.includes("#")) {
      postComments &&
        setTimeout(() => {
          const id = pathname.split("#")[1];
          const element = document.getElementById(id);
          const elementComment = document.getElementById("comments");
          if (element) {
            element.scrollIntoView();
          } else {
            elementComment.scrollIntoView();
          }
        }, 1000);
    }
  }, [
    dispatch,
    pathname,
    likeSuccess,
    unlikeSuccess,
    followSuccess,
    unfollowSuccess,
    isMature,
    likeCommentSuccess,
    unlikeCommentSuccess,
    commentDeleteSuccess,
    replyDeleteSuccess,
    likeReplySuccess,
    unlikeReplySuccess,
    createCommentSuccess,
    createReplySuccess,
    updateCommentSuccess,
    updateReplySuccess,
    reportSuccess,
  ]);

  const submitHandler = (e) => {
    e.preventDefault();
  };
  const isDarkMode = useSelector((state) => state.isDark);

  const handleLike = () => {
    if (userInfo) {
      post.likes.filter((like) => like.id == userInfo._id).length > 0
        ? dispatch(unLikePost(post.id))
        : dispatch(likePost({ id: post.id, idString: pathname }));
    } else {
      history.push("/login");
    }
  };
  const handleFollow = () => {
    if (userInfo) {
      post.user.followers.filter((follower) => follower.id == userInfo._id)
        .length > 0 || post.user.followers.includes(userInfo.id)
        ? dispatch(unFollowUser(post.user.id))
        : dispatch(followUser(post.user.id));
    } else {
      history.push("/login");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createComment({ comment, id: post.id, idString: pathname }));
    setComment("");
  };

  const downloadhandler = async (url, name) => {
    if (!userInfo) {
      history.push("/login");
    } else {
      const urlChanged = url.replace("upload", `upload/fl_attachment:${name}`);
      const link = document.createElement("a");
      link.href = urlChanged;
      //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const reportHandler = () => {
    if (userInfo) {
      setReportOn(!reportOn);
    } else {
      history.push("/login");
    }
  };
  const hashHandler = () => {
    const element = document.getElementById("comment");
    if (element) {
      element.scrollIntoView();
    }
  };
  return (
    <StyledDetails
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title={`Post details-${post.name}`} />
      {reportSuccess && (
        <Message variant="success">Post reported successfully</Message>
      )}
      <StyledMain isDarkMode={isDarkMode}>
        <StyledLeft isDarkMode={isDarkMode} imgZoomFull={imgZoomFull}>
          <div className="back">
            <Link
              to={history.location.state ? history.location.state.path : "/"}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              {history.location.state ? history.location.state.name : " Home"}
            </Link>
          </div>
          <div className="img">
            <img
              src={
                post.image.url &&
                post.image.url.replace(
                  "upload",
                  "upload/c_scale,f_auto,q_auto,w_800"
                )
              }
              onClick={() => setImgZoom(true)}
            />
            {imgZoom && (
              <div className="imgZoom">
                <div className="imgZ">
                  <img
                    src={
                      post.image.url &&
                      post.image.url.replace(
                        "upload",
                        "upload/c_scale,f_auto,q_auto,w_800"
                      )
                    }
                    onClick={() => setImgZoomFull(!imgZoomFull)}
                  />
                  <span className="cancel">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => setImgZoom(false)}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          <StyledDet isDarkMode={isDarkMode}>
            <div className="title">
              <span className="titLeft">
                <span className="comI" onClick={handleLike}>
                  {" "}
                  {userInfo ? (
                    post.likes.filter((like) => like.id == userInfo._id)
                      .length > 0 ? (
                      <FavoriteIcon className="icon ic" />
                    ) : (
                      <FavoriteBorderIcon className="icon" />
                    )
                  ) : (
                    <FavoriteBorderIcon className="icon" />
                  )}{" "}
                  <h4>
                    {userInfo
                      ? post.likes.filter((like) => like.id == userInfo._id)
                          .length > 0
                        ? "Unlike Post"
                        : "Like Post"
                      : "Like Post"}
                  </h4>
                </span>
                <span className="comI" onClick={hashHandler}>
                  {" "}
                  <ChatBubbleOutlineIcon size="20x" /> <h4> Comment </h4>
                </span>
              </span>

              <span className="titRight">
                <span className="comI">
                  {" "}
                  <GetAppIcon
                    className="icon"
                    onClick={() => downloadhandler(post.image.url, post.name)}
                  />
                </span>
                <span className="comI">
                  {" "}
                  <ShareIcon
                    className="icon"
                    onClick={() => setShareOn(!shareOn)}
                  />
                </span>
                <span className="comI" onClick={reportHandler}>
                  Report
                </span>
              </span>
            </div>
            <div className="userDet">
              <span className="imgU">
                <Link to={`/${post.user.userName}`}>
                  <img src={post.user.photo.url} alt="image" />{" "}
                </Link>

                <div className="userTi">
                  <h4>{post.name}</h4>
                  <span className="Ti">
                    <small>by </small>
                    <Link to={`/${post.user.userName}`}>
                      {" "}
                      {post.user.userName}
                    </Link>
                    {userInfo ? (
                      userInfo.userName != post.user.userName &&
                      (post.user.followers.filter(
                        (follower) => follower.id == userInfo._id
                      ).length > 0 ||
                      post.user.followers.includes(userInfo.id) ? (
                        <button className="unfollow" onClick={handleFollow}>
                          Unfollow{" "}
                          {unfollowLoading && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </button>
                      ) : (
                        <button className="follow" onClick={handleFollow}>
                          Follow{" "}
                          {followLoading && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </button>
                      ))
                    ) : (
                      <button className="follow" onClick={handleFollow}>
                        Follow{" "}
                        {followLoading && (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        )}
                      </button>
                    )}
                  </span>
                </div>
              </span>
            </div>
            <div className="postDet">
              <div className="likCoView">
                <span className="comI">
                  <FavoriteIcon className="icon" />
                  <h5>{post && post.likes.length}</h5>
                  <h4> Likes</h4>
                </span>
                <span className="comI">
                  <ChatBubbleIcon className="icon" />
                  <h5>{post && post.comments.length}</h5>
                  <h4> Comments</h4>
                </span>
                <span className="comI">
                  <VisibilityIcon className="icon" />
                  <h5>{post && post.views.length}</h5> <h4>Views</h4>
                </span>
              </div>
              <div className="desc">{post && post.description}</div>
              <div className="imageDesc">
                <span className="tit">IMAGE DETAILS</span>
                <span>
                  <h4>Image size:</h4>
                  <h5>
                    {post && post.imageDimensions.width}x
                    {post && post.imageDimensions.height}px
                  </h5>{" "}
                  {post && (post.imageSize / 1024).toFixed(2)}KB
                </span>
                <span>
                  <h4>Date Uploaded:</h4>{" "}
                  {post && new Date(post.createdAt).toDateString()}
                </span>
              </div>
            </div>
            <StyledComments isDarkMode={isDarkMode}>
              <div className="tit" id="comments">
                COMMENTS {post && post.comments.length}{" "}
              </div>

              <div className="createComment" id="comment">
                <div className="left">
                  <Link to={`/${userInfo ? userInfo.userName : "login"}`}>
                    <img
                      src={
                        userInfo
                          ? userInfo.photo.url
                          : "https://res.cloudinary.com/darmvd8nd/image/upload/v1619768396/bv4zf4nc9z0nklf98pxn.jpg"
                      }
                      alt="image"
                    />{" "}
                  </Link>
                </div>
                <div className="right">
                  {userInfo ? (
                    <div className="logged">
                      <div className="inputGroup">
                        <input
                          type="text"
                          placeholder="Write Comment"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                        <button onClick={handleSubmit}>
                          <SendIcon />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="notLogged">
                      <span>
                        <Link to={`/register?redirect=${pathname}`}>
                          Register
                        </Link>{" "}
                        or <Link to={`/login?redirect=${pathname}`}>Login</Link>{" "}
                        to add your comment
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {postComments &&
                postComments.map((comment) => (
                  <Comment
                    comment={comment.comment}
                    user={comment.user}
                    replies={comment.replies}
                    createdAt={comment.createdAt}
                    likes={comment.likes}
                    id={comment._id}
                  />
                ))}
            </StyledComments>
          </StyledDet>
        </StyledLeft>

        <StyledRight isDarkMode={isDarkMode}>
          <div className="right">
            {userPosts.length > 0 && (
              <div classname="row">
                <div className="title">
                  {" "}
                  See more by{" "}
                  <Link to={`/${post.user.userName}`}>
                    {" "}
                    {post.user.userName}
                  </Link>{" "}
                </div>
                <div className="imageList">
                  {userPosts.map((post) => (
                    <div className="image">
                      <Link
                        to={`/${post.user.userName}/art/${post.slug}/${post.id}`}
                      >
                        <img
                          src={
                            post.image.url &&
                            post.image.url.replace(
                              "upload",
                              "upload/c_scale,f_auto,q_auto,w_800"
                            )
                          }
                          alt="image"
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div classname="row">
              <div className="title"> You might like....... </div>
              <div className="imageList">
                {similarPosts &&
                  similarPosts.map((post) => (
                    <div className="image">
                      <Link
                        to={`/${post.user.userName}/art/${post.slug}/${post.id}`}
                      >
                        <img
                          src={
                            post.image.url &&
                            post.image.url.replace(
                              "upload",
                              "upload/c_scale,f_auto,q_auto,w_800"
                            )
                          }
                          alt="image"
                        />
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </StyledRight>
        <ShareModal
          isDarkMode={isDarkMode}
          title="Share"
          on={shareOn}
          setOn={setShareOn}
        />

        <ReportModal
          isDarkMode={isDarkMode}
          title="Report"
          on={reportOn}
          setOn={setReportOn}
          post={post}
        />
      </StyledMain>
      <Footer />
      <ScrollToTop />
      <ScrollTop />
    </StyledDetails>
  );
};

const StyledDetails = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
  .comI {
    display: flex;
    align-items: center;
  }
`;
const StyledMain = styled(motion.div)`
  display: flex;
  @media (max-width: 900px) {
    flex-flow: column wrap;
  }
  .right {
    flex: 30%;
    width: 100%;
    @media (max-width: 900px) {
      flex: 100%;
    }
  }
`;
const StyledLeft = styled(motion.div)`
  flex: 70%;
  @media (max-width: 900px) {
    flex: 100%;
  }
  .back {
    a {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      &:hover {
        color: #50c1e9;
      }
    }
    font-size: 1rem;
    padding: 0.8rem;
    @media (max-width: 900px) {
      padding: 0.6rem 0.4rem;
    }
  }
  .img {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 0rem 2rem;
    padding-bottom: 1rem;

    @media (max-width: 900px) {
      padding: 0rem 0rem;
      padding-bottom: 0.5rem;
    }
    img {
      display: block;
      height: 30rem;
      max-width: 100%;
      cursor: zoom-in;
      @media (max-width: 900px) {
        height: 15rem;
      }
    }
    .imgZoom {
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 5;
      left: 0;
      top: 0;
      display: flex;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
      padding: 1rem;
      .imgZ {
        overflow-y: ${({ imgZoomFull }) => (imgZoomFull ? "scroll" : "")};
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        .cancel {
          position: fixed;
          top: 2%;
          right: 2%;
          font-size: 1.5rem;
          cursor: pointer;
          padding: 0.2rem 0.6rem;
          border-radius: 50%;
          background: ${({ isDarkMode }) =>
            isDarkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.7)"};
          @media (max-width: 900px) {
            padding: 0.15rem 0.4rem;
          }
        }
        img {
          display: block;
          height: ${({ imgZoomFull }) => (imgZoomFull ? "auto" : "95vh")};
          cursor: ${({ imgZoomFull }) =>
            imgZoomFull ? "zoom-out" : "zoom-in"};
          width: ${({ imgZoomFull }) => (imgZoomFull ? "100%" : "")};
          @media (max-width: 900px) {
            height: ${({ imgZoomFull }) => (imgZoomFull ? "auto" : "auto")};
          }
        }
      }
    }
  }
`;

const StyledDet = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    @media (max-width: 900px) {
      padding: 0.8rem 0.5rem;
    }
    .titLeft {
      display: flex;
      align-items: center;
    }
    .titRight {
      display: flex;
      align-items: center;
    }
  }
  .comI {
    padding: 0rem 1rem;
    cursor: pointer;
    @media (max-width: 900px) {
      padding: 0rem 0.6rem;
    }
    &:hover {
      color: #50c1e9;
    }
    .ic {
      color: #50c1e9;

      &:focus {
        font-size: 0.8rem;
      }
    }
    .icon {
    }
    h4 {
      padding-left: 0.3rem;
      font-size: 1rem;
      font-weight: 400;
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
  h5 {
    padding-left: 0.3rem;
    font-size: 1rem;
    font-weight: 400;
  }
  .userDet {
    padding: 0rem 2rem;
    @media (max-width: 900px) {
      padding: 0rem 0.5rem;
    }
  }
  .imgU {
    display: flex;
    align-items: flex-start;
    img {
      border-radius: 1px;
      width: 3rem;
      @media (max-width: 900px) {
        width: 2.4rem;
      }
    }

    .userTi {
      display: flex;
      flex-flow: column wrap;
      padding: 0rem 1rem;

      @media (max-width: 900px) {
        padding: 0rem 0.5rem;
      }
      h4 {
        font-size: 1.2rem;
        font-weight: 500;

        padding-bottom: 0.2rem;
        @media (max-width: 900px) {
          font-size: 1.1rem;
          padding: 0rem 0rem;
          padding-bottom: 0.2rem;
          padding-top: 0rem;
        }
      }
      .Ti {
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        padding: 0.2rem 0rem;
        @media (max-width: 900px) {
          font-size: 0.9rem;
          padding: 0.2rem 0rem;
        }
      }
    }
    a {
      padding-left: 0.3rem;
      text-decoration: underline;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      &:hover {
        color: #50c1e9;
      }
    }
    button {
      margin-left: 0.6rem;
      padding: 0.3rem 1rem;
      border-radius: 5px;
      cursor: pointer;
    }
    .follow {
      color: #50c1e9;
      background-color: transparent;

      border: 1px solid #50c1e9;
      &:hover {
        color: #4cb1d6;
        border: 1px solid #4cb1d6;
      }
    }
    .unfollow {
      background-color: #50c1e9;
      border: 1px solid #50c1e9;
      &:hover {
        background-color: #4cb1d6;
        border: 1px solid #4cb1d6;
      }
    }
    small {
      padding: 0.2rem 0rem;
      color: gray;
    }
  }

  .postDet {
    display: flex;
    flex-flow: column wrap;
    .likCoView {
      display: flex;
      padding: 1rem;
      @media (max-width: 900px) {
        padding: 0.8rem 0.5rem;
      }
      .comI {
        h4 {
          font-size: 1rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "#222222")};
        }
        .icon {
          font-size: 1.2rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "#222222")};
        }
      }
    }
  }
  .desc {
    padding: 1rem 2rem;
    line-height: 1.5rem;
    @media (max-width: 900px) {
      padding: 0.5rem 1rem;
    }
  }
  .imageDesc {
    padding: 1rem 2rem;
    display: flex;
    flex-flow: column wrap;
    @media (max-width: 900px) {
      padding: 0.5rem 1rem;
    }
    .tit {
      font-size: 1.1rem;
    }
    span {
      display: flex;
      align-items: center;
      padding: 0.7rem 0rem;
      @media (max-width: 900px) {
        padding: 0.4rem 0rem;
      }
      h4 {
        font-size: 1rem;
        padding-right: 5rem;
        font-weight: 300;
        @media (max-width: 900px) {
          padding-right: 0.4rem;
        }
      }
      h5 {
        font-size: 1rem;
        font-weight: 300;
        padding-right: 0.5rem;
        @media (max-width: 900px) {
          padding-right: 0.4rem;
        }
      }
    }
  }
`;

const StyledComments = styled(motion.div)`
  padding: 2rem;
  @media (max-width: 900px) {
    padding: 1rem;
  }
  .tit {
    font-size: 1.1rem;
    @media (max-width: 900px) {
      font-size: 1rem;
    }
  }
  .createComment {
    padding: 1rem 0rem;
    display: flex;
    @media (max-width: 900px) {
      padding: 0.5rem 0rem;
    }
    img {
      width: 2rem;
      border-radius: 2px;
      @media (max-width: 900px) {
        width: 1.7rem;
      }
    }
    .left {
      padding-right: 0.5rem;
    }
    .right {
      display: flex;
      width: 100%;
      background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      border-radius: 2px;

      .logged {
        width: 100%;
        .inputGroup {
          width: 100%;
          display: flex;
          input {
            width: 83%;
            display: flex;
            background: ${({ isDarkMode }) =>
              isDarkMode ? "#171818" : "#f8f8f8"};
            outline: none;
            border: none;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 1rem;
            font-size: 0.9rem;
            @media (max-width: 900px) {
              padding: 0.5rem;
              width: 80%;
            }
            &::placeholder {
              color: ${({ isDarkMode }) =>
                isDarkMode ? "#e2dede" : "#444343"};
            }
          }
          button {
            padding: 1rem;
            background: #50c1e9;
            outline: none;
            border: none;
            border-radius: 0px 2px 2px 0px;
            width: 17%;
            cursor: pointer;
            border: 2px solid #50c1e9;

            cursor: pointer;
            &:hover {
              background: #4cb1d6;
            }
            @media (max-width: 900px) {
              padding: 0.5rem;
              font-size: 0.9rem;
              width: 20%;
            }
          }
        }
      }
      .notLogged {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        justify-content: center;
        align-items: center;
        @media (max-width: 900px) {
          text-align: center;
          padding: 1rem;
        }
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "#e2dede" : "#444343")};

          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;

const StyledRight = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  flex: 30%;
  display: flex;
  flex-flow: column wrap;
  @media (max-width: 900px) {
    width: 100%;
    padding-bottom: 1rem;
  }
  .row {
  }
  .title {
    padding: 1rem 2rem;

    @media (max-width: 900px) {
      padding: 1rem;
    }
    a {
      text-decoration: underline;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      &:hover {
        color: #50c1e9;
      }
    }
  }
  .imageList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-column-gap: 0.3rem;
    grid-row-gap: 0.3rem;
    padding: 0rem 2rem;
    @media (max-width: 900px) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      padding: 0rem 0.5rem;
      grid-row-gap: 0.2rem;
      grid-column-gap: 0.2rem;
    }

    .image {
      width: 6.79rem;
      height: 6.3rem;
      overflow: hidden;
      border-radius: 3px;
      background: black;
      @media (max-width: 900px) {
        width: 100%;
        height: 8rem;
      }
      img {
        width: 100%;
        height: 7rem;
        display: block;
        object-fit: cover;
        cursor: pointer;
        @media (max-width: 900px) {
          height: 100%;
        }
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`;
export default PostDetails;
