import axios from "axios";
import {
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_REQUEST,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_POSTS_LIST_REQUEST,
  CATEGORY_POSTS_LIST_SUCCESS,
  CATEGORY_POSTS_LIST_FAIL,
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
} from "../constants/categoryConstants";

import { domain } from "./api";

export const listCategories =
  ({ isMature, searchCategory }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST });

      const { data } = await axios.get(
        `${domain}/api/v1/categories/?${isMature ? "" : "isMature=false&"}${
          searchCategory ? "keyCat=" + searchCategory : ""
        }`
      );
      dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listCategoryDetails =
  ({ categorySlug }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_DETAILS_REQUEST });
      const { data } = await axios.get(
        `${domain}/api/v1/categories/${categorySlug}`
      );
      dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CATEGORY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listCategoriesPosts =
  ({ approved, categorySlug, isMature }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_POSTS_LIST_REQUEST });

      const { data } = await axios.get(
        `${domain}/api/v1/posts/?${approved ? `approved=${approved}&` : ""}${
          categorySlug ? `categorySlug=${categorySlug}&` : ""
        }${isMature ? " " : "isMature=false"}`
      );
      dispatch({ type: CATEGORY_POSTS_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CATEGORY_POSTS_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_DELETE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.delete(`${domain}/api/v1/categories/${id}`, config);
    dispatch({ type: CATEGORY_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createCategory = (category) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_CREATE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();
    formData.append("name", category.name);
    formData.append("description", category.description);
    formData.append("isMature", category.isMature);
    formData.append("image", category.image);
    const { data } = await axios.post(
      `${domain}/api/v1/categories/`,
      formData,
      config
    );
    dispatch({ type: CATEGORY_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateCategory = (category, id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_UPDATE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();

    if (category.name) formData.append("name", category.name);
    if (category.description)
      formData.append("description", category.description);
    formData.append("isMature", category.isMature);
    if (!(Object.entries(category.image).length === 0))
      formData.append("image", category.image);
    const { data } = await axios.patch(
      `${domain}/api/v1/categories/${id}`,
      formData,
      config
    );
    dispatch({ type: CATEGORY_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
