import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";

import AccountEditModal from "../components/AccountEditModal";
import FollowersModal from "../components/FollowersModal";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import CakeIcon from "@material-ui/icons/Cake";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DateRangeIcon from "@material-ui/icons/DateRange";
import favicon from "../images/favicon.png";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function AccountAbout({ user, isDarkMode, userInfo }) {
  const [on, setOn] = useState(false);
  const [followersOn, setFollowersOn] = useState(false);
  const [followingOn, setFollowingOn] = useState(false);
  return (
    <StyledAbout isDarkMode={isDarkMode}>
      <div className="about">
        <span className="title">
          <Link to={`/${user.userName}`}>{user.userName}</Link>{" "}
          {userInfo
            ? userInfo.userName === user.userName && (
                <span onClick={() => setOn(!on)}>
                  Edit
                  <FontAwesomeIcon icon={faEdit} />
                </span>
              )
            : ""}
        </span>
        <span className="details">
          {user.showDob == "dontShow" ? (
            ""
          ) : (
            <span>
              <CakeIcon className="icon" />
              {user.showDob == "plusMonth"
                ? `${new Date(user.dateOfBirth).getDate()} ${
                    months[new Date(user.dateOfBirth).getMonth()]
                  }`
                : user.showDob == "plusYear"
                ? `${new Date(user.dateOfBirth).getDate()} ${
                    months[new Date(user.dateOfBirth).getMonth()]
                  } ${new Date(user.dateOfBirth).getFullYear()}`
                : ""}
            </span>
          )}
          {user.country && (
            <span>
              <LocationOnIcon className="icon" />
              {user.country}
            </span>
          )}
          {user.createdAt && (
            <span>
              <DateRangeIcon className="icon" />
              Joined{" "}
              {`${months[new Date(user.createdAt).getMonth()]} ${new Date(
                user.createdAt
              ).getFullYear()}`}
            </span>
          )}
        </span>
        {user.bio && (
          <span className="bio">
            <h3>Bio</h3>
            <span className="body">{user.bio}</span>
          </span>
        )}
        <div className="follow">
          <span
            className="followers"
            onClick={() => {
              if (user.followers.length > 0) setFollowersOn(!followersOn);
            }}
          >
            <h3>Followers {user.followers.length}</h3>
          </span>
          <span
            className="following"
            onClick={() => {
              if (user.following.length > 0) setFollowingOn(!followingOn);
            }}
          >
            <h3>Following {user.following.length}</h3>
          </span>
        </div>
      </div>
      <AccountEditModal
        on={on}
        setOn={setOn}
        title="Account Edit"
        isDarkMode={isDarkMode}
        user={user}
      />
      <FollowersModal
        on={followersOn}
        setOn={setFollowersOn}
        title="Followers"
        isDarkMode={isDarkMode}
        followers={user.followers}
      />
      <FollowersModal
        on={followingOn}
        setOn={setFollowingOn}
        title="Following"
        isDarkMode={isDarkMode}
        followers={user.following}
      />
    </StyledAbout>
  );
}

const StyledAbout = styled(motion.div)`
  .about {
    padding: 4rem 6rem;
    display: flex;
    flex-flow: column wrap;
    border-bottom: 0.15rem solid
      ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
    @media (max-width: 900px) {
      align-items: center;
      padding: 1rem 1.5rem;
      border-bottom: 0rem;
    }
    .title {
      display: flex;
      align-items: center;

      &:hover {
        span {
          display: flex;
        }
      }
      a {
        font-size: 3rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        @media (max-width: 900px) {
          font-size: 1.5rem;
        }
        &:hover {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }
      span {
        color: #50c1e9;
        font-size: 1.3rem;
        padding-left: 3rem;
        display: none;
        cursor: pointer;
        @media (max-width: 900px) {
          padding-left: 1.5rem;
          display: flex;
          font-size: 1.2rem;
        }
      }
    }
    .details {
      display: flex;
      padding: 2rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      @media (max-width: 900px) {
        padding: 1rem 0rem;
        flex-flow: column;

        width: 100%;
      }
      span {
        display: flex;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        align-items: center;
        padding-right: 2rem;
        font-size: 1.2rem;
        @media (max-width: 900px) {
          padding-right: 0rem;
          padding: 0.2rem 0rem;
          font-size: 1.1rem;
        }
      }
      img {
        width: 1rem;
      }
      .icon {
        margin-right: 0.7rem;
      }
    }
    .bio {
      padding-bottom: 2rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      h3 {
        font-size: 1.3rem;
        padding: 0.5rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        @media (max-width: 900px) {
          padding: 0.3rem 0rem;
        }
      }
      .body {
        line-height: 1.5;
      }
    }
    .line {
      height: 2px;
      width: 100%;
      background-color: #99a8b2;
    }

    .follow {
      display: flex;
      padding: 0.5rem 0rem;
      h3 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
      @media (max-width: 900px) {
        flex-flow: column wrap;
        align-items: center;
      }
      span {
        padding-right: 2rem;
        cursor: pointer;
        @media (max-width: 900px) {
          padding: 0.4rem 0rem;
        }
      }
      @media (max-width: 900px) {
        padding: 0rem 0rem;
        flex-flow: column;
        width: 100%;
      }
    }
  }
`;

export default AccountAbout;
