import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
//Redux
import { useDispatch, useSelector } from 'react-redux';

//components
import { TextField } from '@material-ui/core';

import Loader from '../components/Loader';
import Message from '../components/Message';
//actions
import { login } from '../actions/userActions';
import { USER_LOGOUT } from '../constants/userConstants';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
import Meta from '../components/Meta';
import ErrorMessage from '../components/forms/ErrorMessage';
import whiteLogo from '../images/logoWhite.png';
import darkLogo from '../images/logoDark.png';
import Footer from '../components/Footer';
import ScrollTop from '../components/ScrollTop';
const validationSchema = Yup.object().shape({
  username: Yup.string().required().label('Username'),
  password: Yup.string().required().label('Password'),
});
const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setTimeout(() => {
        dispatch({ type: USER_LOGOUT });
      }, 2000);
    }
  }, [history, userInfo, redirect, error]);
  const submitHandler = (username, password) => {
    dispatch(login(username, password));
  };

  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Login" />
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <div class="login">
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            submitHandler(values['username'], values['password'])
          }
          class="loginForm"
        >
          {({
            handleSubmit,
            handleChange,
            setFieldTouched,
            touched,
            errors,
          }) => (
            <div class="loginForm">
              <span className="icon">
                <Link to="/">
                  {isDarkMode ? (
                    <img src={whiteLogo} />
                  ) : (
                    <img src={darkLogo} />
                  )}
                </Link>
              </span>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Username"
                  type="text"
                  onChange={handleChange('username')}
                  onBlur={() => setFieldTouched('username')}
                />
                <ErrorMessage
                  error={errors['username']}
                  visible={touched['username']}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  onChange={handleChange('password')}
                  onBlur={() => setFieldTouched('password')}
                />
                <ErrorMessage
                  error={errors['password']}
                  visible={touched['password']}
                />
              </div>

              <button onClick={handleSubmit}>Sign In</button>
              <div className="forReg">
                <Link to="/forgotPassword"> Forgot password?</Link>
                <Link
                  to={redirect ? `/register?redirect=${redirect}` : 'register'}
                >
                  Register
                </Link>
              </div>
              <div class="text">
                By continuing, you agree to Wandart's
                <Link to="/terms"> Conditions of Use</Link> and
                <Link to="/policy"> Privacy Notice.</Link>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <Footer />
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  align-items: center;
  padding-top: 1rem;

  @media screen and (max-width: 900px) {
    padding: 0rem;

    background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
  }
  .login {
    width: 27rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 900px) {
      width: 100%;
      padding-bottom: 5rem;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 900px) {
          display: none;
        }
        img {
          width: 7rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      @media screen and (max-width: 900px) {
        padding: 1rem 1.2rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
      }
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        input {
          background: ${({ isDarkMode }) =>
            isDarkMode ? '#0f0f0f' : '#ffffff'};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
          }
        }
      }
      .forReg {
        display: flex;
        justify-content: space-between;
        padding: 0.4rem 0rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          font-size: 0.9rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default Login;
