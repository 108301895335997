import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ScrollTop from '../components/ScrollTop';
import { pageAnimation } from '../animations';

import Meta from '../components/Meta';

const NotFound = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="404" />
      <div className="notFound">
        <h1>Page Not Found</h1>
        <Link to="/">Go to home page</Link>
      </div>
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  min-height: 81vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  .notFound {
    min-height: 60vh;
    width: 60rem;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    a {
      color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};

      font-size: 1rem;
      &:hover {
        color: #50c1e9;
      }
    }
  }
`;
export default NotFound;
