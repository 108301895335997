export const POST_LIST_REQUEST = 'POST_LIST_REQUEST';
export const POST_LIST_FAIL = 'POST_LIST_FAIL';
export const POST_LIST_SUCCESS = 'POST_LIST_SUCCESS';

export const POST_DETAILS_REQUEST = 'POST_DETAILS_REQUEST';
export const POST_DETAILS_FAIL = 'POST_DETAILS_FAIL';
export const POST_DETAILS_SUCCESS = 'POST_DETAILS_SUCCESS';

export const LIKE_POST_REQUEST = 'LIKE_POST_REQUEST';
export const LIKE_POST_FAIL = 'LIKE_POST_FAIL';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_RESET = 'LIKE_POST_RESET';

export const UNLIKE_POST_REQUEST = 'UNLIKE_POST_REQUEST';
export const UNLIKE_POST_FAIL = 'UNLIKE_POST_FAIL';
export const UNLIKE_POST_SUCCESS = 'UNLIKE_POST_SUCCESS';
export const UNLIKE_POST_RESET = 'UNLIKE_POST_RESET';

export const REPORT_POST_REQUEST = 'REPORT_POST_REQUEST';
export const REPORT_POST_FAIL = 'REPORT_POST_FAIL';
export const REPORT_POST_SUCCESS = 'REPORT_POST_SUCCESS';
export const REPORT_POST_RESET = 'REPORT_POST_RESET';

export const USER_POST_LIST_REQUEST = 'USER_POST_LIST_REQUEST';
export const USER_POST_LIST_FAIL = 'USER_POST_LIST_FAIL';
export const USER_POST_LIST_SUCCESS = 'USER_POST_LIST_SUCCESS';

export const SIMILAR_POST_LIST_REQUEST = 'SIMILAR_POST_LIST_REQUEST';
export const SIMILAR_POST_LIST_FAIL = 'SIMILAR_POST_LIST_FAIL';
export const SIMILAR_POST_LIST_SUCCESS = 'SIMILAR_POST_LIST_SUCCESS';

export const SEARCHED_POST_LIST_REQUEST = 'SEARCHED_POST_LIST_REQUEST';
export const SEARCHED_POST_LIST_FAIL = 'SEARCHED_POST_LIST_FAIL';
export const SEARCHED_POST_LIST_SUCCESS = 'SEARCHED_POST_LIST_SUCCESS';

export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST';
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_RESET = 'POST_DELETE_RESET';

export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL';
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS';
export const POST_CREATE_RESET = 'POST_CREATE_RESET';

export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST';
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_UPDATE_RESET = 'POST_UPDATE_RESET';
