import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteNotification,
  listUserNotifications,
} from '../actions/notificationActions';
import { NOTIFICATION_DELETE_RESET } from '../constants/notificationConstants';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
import Meta from '../components/Meta';
import ScrollTop from '../components/ScrollTop';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../components/Loader';
function NotificationList(props) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const notificationDelete = useSelector((state) => state.notificationDelete);
  const {
    loading: notificationDeleteLoading,
    success: notificationDeleteSuccess,
    error: notificationDeleteError,
  } = notificationDelete;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      dispatch(listUserNotifications(userInfo.id, 10000));
    }
  }, [
    dispatch,
    history,
    userInfo,
    notificationDeleteSuccess,
    notificationDeleteError,
  ]);

  const userNotificationList = useSelector(
    (state) => state.userNotificationList
  );
  const { loading, notifications } = userNotificationList;

  const deleteHandler = (id) => {
    dispatch(deleteNotification(id));
  };
  return (
    <StyledNotificationList
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <div className="notificationss">
        <h4>Notifications</h4>
        {notificationDeleteLoading && <Loader />}
        {loading ? (
          <Loader />
        ) : notifications.length > 0 ? (
          notifications.map((notification) => (
            <div key={notifications.id}>
              <span className="notificationn">
                <span className="left">
                  <Link to={`/${notification.user.userName}`} className="donnt">
                    <img src={notification.user.photo.url} className="donnt" />
                  </Link>
                </span>
                <span className="right">
                  <Link to={`/${notification.user.userName}`} className="donnt">
                    {notification.user.userName}
                  </Link>{' '}
                  <h5 onClick={(e) => history.push(notification.idString)}>
                    {notification.message}
                  </h5>
                  <span className="donnt">
                    <DeleteIcon
                      onClick={() => deleteHandler(notification.id)}
                      className="donnt"
                    />
                  </span>
                </span>
              </span>
              <div className="line"></div>
            </div>
          ))
        ) : (
          <span className="noNot">
            <h4>You have no notifications</h4>
          </span>
        )}
      </div>
      <ScrollTop />
    </StyledNotificationList>
  );
}
const StyledNotificationList = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  padding: 1rem 6rem;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  @media screen and (max-width: 900px) {
    padding: 0.5rem 0.2rem;
  }
  .notificationss {
    display: flex;
    flex-flow: column;
    padding: 0rem;
    min-height: 90vh;
    h4 {
      text-align: center;
      padding: 0.6rem;
      @media screen and (max-width: 900px) {
        font-size: 1.2rem;
      }
    }
    .notificationn {
      font-size: 0.9rem;

      @media screen and (max-width: 900px) {
        margin: 0.2rem 0rem;
      }
      margin: 0.5rem 0rem;
      display: flex;
      align-items: center;
      background: ${({ isDarkMode }) => (isDarkMode ? '#171818' : '#f8f8f8')};
      &:hover {
        background: ${({ isDarkMode }) =>
          isDarkMode ? 'rgb(80,193,233,0.5)' : 'rgb(80,193,233,0.3)'};
      }
      .left {
        padding-right: 0.4rem;
        padding: 1rem 1rem;
        img {
          width: 2rem;
          border-radius: 2px;
        }
      }
      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0rem;
        padding-right: 1rem;
        width: 100%;
        cursor: pointer;
        &:hover {
          span {
            display: block;
          }
        }
        span {
          display: none;
          cursor: pointer;
          margin: 0.5rem;
          margin-left: auto;
          @media screen and (max-width: 900px) {
            display: flex;
            font-size: 1rem;
          }
        }
        a {
          padding: 0rem 0rem 0rem 0rem;
          width: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
        h5 {
          padding: 0rem 0rem 0rem 0.3rem;
          width: auto;
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
    }
    .noNot {
      margin-left: auto;
      margin-right: auto;
      padding: 10rem;
      min-height: 80vh;
      font-size: 2rem;
      background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
      @media screen and (max-width: 900px) {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        padding: 1rem;
      }
    }
  }
`;
export default NotificationList;
