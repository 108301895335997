import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Masonry from 'react-masonry-css';
//Redux
import { useDispatch, useSelector } from 'react-redux';
//components

import Post from '../components/Post';
import Loader from '../components/Loader';
import Message from '../components/Message';

//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageAnimation } from '../animations';
//actions
import { listPosts } from '../actions/postActions';
import { listCategoryDetails } from '../actions/categoryActions';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Meta from '../components/Meta';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import ScrollTop from '../components/ScrollTop';
const CategoryPosts = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isDark);
  const pathname = location.pathname;
  const isMature = useSelector((state) => state.displayMature);
  const categorySlug = location.pathname.split('/')[2];
  useEffect(() => {
    dispatch(listPosts({ isMature, categorySlug, approved: true }));
    dispatch(listCategoryDetails({ categorySlug }));
  }, [isMature]);

  const postList = useSelector((state) => state.postList);
  const { loading, posts, error, page, pages } = postList;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const {
    loading: categoryLoading,
    category,
    error: categoryError,
  } = categoryDetails;
  return (
    <div
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title={`${category && category.name} category posts`} />
      {loading || categoryLoading ? (
        <Loader />
      ) : error || categoryError ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <StyledCategoryPosts isDarkMode={isDarkMode}>
          <div className="titImg">
            <img src={category.image.url} />
            <span className="back">
              <Link to="/category">
                {' '}
                <FontAwesomeIcon icon={faChevronLeft} /> All Categories
              </Link>
            </span>
            <span className="name">
              <h4>{category.name}</h4>
              <h5>{category.description}</h5>
            </span>
          </div>
          <StyledPosts isDarkMode={isDarkMode}>
            <Masonry
              breakpointCols={{ default: 4, 900: 1 }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {posts.map((post) => (
                <Post key={post._id} id={post._id} post={post} />
              ))}
            </Masonry>
          </StyledPosts>
          <Footer />
          <ScrollToTop />
          <ScrollTop />
        </StyledCategoryPosts>
      )}
    </div>
  );
};

const StyledCategoryPosts = styled(motion.div)`
  .titImg {
    overflow: hidden;
    width: 100%;
    height: 25rem;
    background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
    position: relative;
    @media (max-width: 900px) {
      height: 15rem;
      margin-bottom: 0.3rem;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0.6;
      object-fit: cover;
    }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem 2rem;
      @media (max-width: 900px) {
        padding: 0.7rem 0.4rem;
      }
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? '#f1f1f1' : '#1b1b1b')};
        &:hover {
          color: #50c1e9;
        }
      }
      font-size: 1.2rem;
      @media (max-width: 900px) {
        font-size: 1rem;
      }
    }
    .name {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem 2rem;
      color: ${({ isDarkMode }) => (isDarkMode ? '#f1f1f1' : '#1b1b1b')};
      @media (max-width: 900px) {
        padding: 0.7rem 0.4rem;
        width: 100%;
      }
      h4 {
        font-size: 2.7rem;
        font-weight: 780;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        @media (max-width: 900px) {
          font-size: 1.5rem;
          text-align: center;
        }
      }
      h5 {
        font-size: 1.1rem;
        @media (max-width: 900px) {
          text-align: center;
          font-size: 0.95rem;
        }
      }
    }
  }
`;
const StyledPosts = styled(motion.div)`
  padding: 0.5rem 0.5rem;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  @media (max-width: 900px) {
    padding: 0rem 0rem;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 0.4rem; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 0.4rem;
  }
  @media (max-width: 900px) {
    .my-masonry-grid_column > div {
      margin-bottom: 0.25rem; /* space between items */
      height: 40vh;
    }
  }
`;
export default CategoryPosts;
