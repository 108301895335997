import React from "react";
import { Link, useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";

//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";

import { popUp } from "../animations";
const Category = ({ category }) => {
  const isDarkMode = useSelector((state) => state.isDark);

  return (
    <StyledPost
      variants={popUp}
      animate="show"
      initial="hidden"
      isDarkMode={isDarkMode}
    >
      <Link to={`/category/${category.slug}`}>
        <img
          src={
            category.image.url &&
            category.image.url.replace(
              "upload",
              "upload/c_scale,f_auto,q_auto,w_800"
            )
          }
          alt="image"
        />

        <div className="title">{category.name}</div>
      </Link>
    </StyledPost>
  );
};

const StyledPost = styled(motion.div)`
  background: black;
  position: relative;
  transition: 0.6s all ease-in-out;
  overflow: hidden;
  height: 18rem;
  border-radius: 3px;
  @media (max-width: 900px) {
    height: 10rem;
  }
  &:hover {
    img {
      opacity: 1;
      transform: scale(1.05);
    }
  }
  img {
    opacity: 0.85;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transition: 0.2s all ease-in-out;
  }
  .title {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    padding: 1rem 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1.2rem;
    font-weight: 700;
    @media (max-width: 900px) {
      font-size: 1rem;
      padding: 0.5rem 0.5rem;
    }
  }
`;
export default Category;
