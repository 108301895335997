import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//actions
import { logout, getLoggedUserStatus } from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";

function SettingsNav({ pathname, isDarkMode }) {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <StyledSettingsNav isDarkMode={isDarkMode}>
      <div className="nav">
        <h2>Settings</h2>
        <span>
          <h3>Public</h3>
          <Link
            to="/settings/identity"
            className={pathname === "/settings/identity" ? "active" : ""}
          >
            Identity
          </Link>
        </span>
        <span>
          <h3>Personal</h3>
          <Link
            to="/settings/general"
            className={pathname === "/settings/general" ? "active" : ""}
          >
            General
          </Link>
          <Link
            to="/settings/deactivation"
            className={pathname === "/settings/deactivation" ? "active" : ""}
          >
            Deactivation
          </Link>
        </span>
        {/* <span>
          <h3>Privacy & terms</h3>
          <Link
            to="/profile"
            className={pathname === "/profile" ? "active" : ""}
          >
            Terms of service
          </Link>
          <Link
            to="/profile"
            className={pathname === "/profile" ? "active" : ""}
          >
            Privacy pollcy
          </Link>
        </span> */}
      </div>
    </StyledSettingsNav>
  );
}

const StyledSettingsNav = styled(motion.div)`
  flex: 20%;
  padding: 0rem 2rem;
  .nav {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    h2 {
      font-weight: 520;
      font-size: 1.4rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      padding: 1.4rem 0rem;

      @media screen and (max-width: 900px) {
        background: ${({ isDarkMode }) =>
          isDarkMode ? "rgb(23, 24, 24,0.9)" : "rgb(248, 248, 248,0.9)"};
        padding: 1rem 2rem;
        margin-bottom: 0.4rem;
      }
    }
    span {
      display: flex;
      flex-flow: column wrap;
      @media screen and (max-width: 900px) {
        padding: 0rem 2rem;
      }
      .active {
        background-color: ${({ isDarkMode }) =>
          isDarkMode ? "#99a8b2;" : "#99a8b2;"};
        color: white;
        border-radius: 4px;
        &:hover {
          color: white;
          background-color: ${({ isDarkMode }) =>
            isDarkMode ? "#99a8b2;" : "#99a8b2;"};
        }
      }
      h3 {
        font-weight: 520;
        font-size: 1rem;
        margin-top: 0.3rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
      a {
        padding: 0.3rem 0.8rem;
        margin-top: 0.3rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-weight: 400;
        font-size: 0.85rem;
        border-radius: 4px;
        &:hover {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#171818" : "#f8f8f8"};
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    flex: 100%;
    padding: 0rem 0rem;
    padding-bottom: 0.4rem;
  }
`;

export default SettingsNav;
