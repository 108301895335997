import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//components
import MobNav from "./MobNav";
//actions
import {
  logout,
  getLoggedUserStatus,
  resendConfirmEmail,
} from "../actions/userActions";
import { listUserNotifications } from "../actions/notificationActions";
import { changeTheme } from "../actions/themeActions";
import { changeMature } from "../actions/matureActions";
//Styling and Animationimport styled from "styled-components";
import { lineAnim } from "../animations";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faBars,
  faSearch,
  faEllipsisH,
  faShoppingBasket,
  faUser,
  faCaretDown,
  faHome,
  faTimes,
  faMoon,
  faSun,
  faToggleOn,
  faToggleOff,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import CheckIcon from "@material-ui/icons/Check";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import NotificationsIcon from "@material-ui/icons/Notifications";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import whiteLogo from "../images/logoWhite.png";
import darkLogo from "../images/logoDark.png";
import {
  USER_RESEND_CONFIRM_EMAIL_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import Loader from "./Loader";
import { listCategories } from "../actions/categoryActions";
const Nav = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [searchToggle, setSearchToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [category, setCategory] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [notDown, setNotDown] = useState(false);
  const [themeLoading, setThemeloading] = useState(false);
  const [appended, setAppended] = useState(false);

  const { pathname } = useLocation();
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);

  const search = location.search;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userResendConfirmEmail = useSelector(
    (state) => state.userResendConfirmEmail
  );
  const {
    loading: resendLoading,
    success: resendSuccess,
    error: resendError,
  } = userResendConfirmEmail;
  useEffect(() => {
    if (pathname === "/login" || "/register") {
      setMenuToggle(false);
      setSearchToggle(false);
    }
    dispatch(getLoggedUserStatus());
    dispatch(listCategories({ isMature }));
    setNotDown(false);
    setTimeout(() => {
      dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
    }, 2000);
    if (userInfo && userInfo.id) {
      dispatch(listUserNotifications(userInfo.id, 6));
    }
    if (
      (isMature && !userInfo) ||
      (isMature && userInfo.role == "user" && userInfo.plan !== "premium")
    ) {
      localStorage.setItem("displayMature", JSON.stringify(false));
    }
  }, [pathname, dispatch, userInfo && userInfo.id, resendSuccess, isMature]);

  const userNotificationList = useSelector(
    (state) => state.userNotificationList
  );
  const { loading, notifications } = userNotificationList;

  const handlerThemeSwitch = () => {
    setThemeloading(true);
    setTimeout(() => {
      setThemeloading(false);
      dispatch(changeTheme());
    }, 1300);
  };
  const handlerMatureSwitch = () => {
    if (!userInfo || (userInfo.plan !== "premium" && userInfo.role == "user")) {
      history.push("/premium");
    } else {
      dispatch(changeMature());
    }
  };

  const logoutHandler = () => {
    setMenuToggle(!menuToggle);
    dispatch(logout());
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/?keyword=${keyword}`);
    } else {
      history.push("/");
    }
  };
  const exitHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("window")) {
      setMenuToggle(false);
    }
  };
  const resendHandler = (e) => {
    dispatch(resendConfirmEmail());
  };
  // if (!userInfo || (userInfo.role == 'user' && userInfo.plan !== 'premium')) {
  //   const script = document.createElement('script');
  //   script.src =
  //     'https://goraps.com/tup.php?section=General&pt=7&pub=311311&ga=g&fN=12&fT=12&fTT=1';

  //   script.async = true;
  //   document.body.appendChild(script);
  // }
  return (
    <StyledNav isDarkMode={isDarkMode}>
      {themeLoading && <Loader />}

      <MobNav
        searchToggle={searchToggle}
        menuToggle={menuToggle}
        isDarkMode={isDarkMode}
        setMenuToggle={setMenuToggle}
        userInfo={userInfo}
        logoutHandler={logoutHandler}
        exitHandler={exitHandler}
        setSearchToggle={setSearchToggle}
        notifications={notifications}
        handlerThemeSwitch={handlerThemeSwitch}
        handlerMatureSwitch={handlerMatureSwitch}
        isMature={isMature}
        notDown={notDown}
        setNotDown={setNotDown}
        setThemeloading={setThemeloading}
      />
      <div className="navbar">
        <span className="icon">
          <Link to="/">
            {isDarkMode ? <img src={whiteLogo} /> : <img src={darkLogo} />}
          </Link>
        </span>
        <div className="form">
          <form onSubmit={submitHandler}>
            <input
              type="search"
              placeholder="Search HD wallpapers and art"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <motion.div variants={lineAnim} className="line hide"></motion.div>
            <button type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div className="nav-right">
          {userInfo ? (
            userInfo.role == "user" && userInfo.plan !== "premium" ? (
              <div className="premiumButton">
                {" "}
                <button onClick={() => history.push("/premium")}>
                  Premium <FontAwesomeIcon icon={faStar} />
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            <div className="premiumButton">
              {" "}
              <button onClick={() => history.push("/premium")}>
                Premium <FontAwesomeIcon icon={faStar} />
              </button>
            </div>
          )}
          {userInfo && userInfo.photo ? (
            <div className="dropdown profile ">
              <span className="image more">
                <Link to={`/${userInfo.userName}`}>
                  <img src={userInfo.photo.url} />
                </Link>
              </span>
              <div className="hover-down">
                <span className="userName">
                  {" "}
                  <Link to={`/${userInfo.userName}`}>{userInfo.userName} </Link>
                </span>
                {userInfo.emailConfirmStatus == false && (
                  <div className="resend-email">
                    <span>Please confirm your email !!</span>
                    <small>
                      Didnt recieve an email?{" "}
                      {resendSuccess ? (
                        <button>
                          Sent <CheckIcon className="icon" />
                        </button>
                      ) : (
                        <button onClick={resendHandler}>
                          Resend{" "}
                          {resendLoading && (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          )}
                        </button>
                      )}
                    </small>
                  </div>
                )}
                <div className="line"></div>
                <span>
                  {" "}
                  <Link to="/account">Billing & payment </Link>
                </span>

                <span>
                  {" "}
                  <Link to="/settings/identity">Account Settings </Link>
                </span>
                <div className="line"></div>

                <span>
                  {" "}
                  <Link onClick={logoutHandler} className="logout">
                    Logout
                  </Link>
                </span>
              </div>
            </div>
          ) : (
            <>
              <Link className="nav-link" to="/login">
                Login
              </Link>
              <div className="line"></div>
              <Link className="nav-link" to="/register">
                Register
              </Link>
            </>
          )}

          {(userInfo && userInfo.role === "admin") ||
          (userInfo && userInfo.role === "moderator") ? (
            <div className="dropdown ">
              <span className="nav-link more">
                Admin <FontAwesomeIcon icon={faCaretDown} className="fIcon" />
              </span>

              <div className="hover-down">
                <Link className="nav-link" to="/admin/createpost">
                  Create Post
                </Link>
                <div className="line"></div>
                <Link className="nav-link" to="/admin/userlist">
                  Users
                </Link>
                <Link className="nav-link" to="/admin/postlist">
                  Posts
                </Link>
                <Link className="nav-link" to="/admin/categorylist">
                  Categories
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}

          {userInfo && userInfo.photo && (
            <div className="dropdown not">
              <span className="nav-link more">
                <NotificationsIcon />
              </span>
              <div className="hover-down hovNot">
                <span>
                  <h3>Your notifications</h3>
                </span>
                {notifications && notifications.length > 0 && (
                  <div className="line"></div>
                )}
                {notifications && notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <div key={notifications.id}>
                      <span
                        className="notification"
                        onClick={(e) =>
                          !e.target.classList.contains("dont") &&
                          history.push(notification.idString)
                        }
                      >
                        <span className="left">
                          <Link
                            to={`/${notification.user.userName}`}
                            className="dont"
                          >
                            <img src={notification.user.photo.url} />
                          </Link>
                        </span>
                        <span className="right">
                          <Link
                            to={`/${notification.user.userName}`}
                            className="dont"
                          >
                            {notification.user.userName}
                          </Link>{" "}
                          <h5>{notification.message}</h5>
                        </span>
                      </span>
                      <div className="line"></div>
                    </div>
                  ))
                ) : (
                  <span className="noNotification">
                    You have no notifications
                  </span>
                )}
                <span className="feedBack">
                  <Link to="/notifications/feedback">See All</Link>
                </span>
              </div>
            </div>
          )}
          <div className="dropdown ">
            <span className="nav-link more">
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>

            <div className="hover-down">
              <span className="theme">
                <span>Theme </span>
                {isDarkMode ? (
                  <button onClick={handlerThemeSwitch} className="moon">
                    <FontAwesomeIcon icon={faMoon} color="whitesmoke" />
                  </button>
                ) : (
                  <button onClick={handlerThemeSwitch} className="sun">
                    <FontAwesomeIcon icon={faSun} color="gray" />
                  </button>
                )}
              </span>
              <span className="theme">
                <span>Display mature content </span>
                {isMature ? (
                  <button onClick={handlerMatureSwitch}>
                    On
                    <FontAwesomeIcon icon={faToggleOn} className="fI" />
                  </button>
                ) : (
                  <button onClick={handlerMatureSwitch}>
                    Off
                    <FontAwesomeIcon icon={faToggleOff} className="fI" />
                  </button>
                )}
              </span>
              <Link to="/about"> About </Link>
              <Link to="/contact">Contact</Link>
              <Link to="/policy">Privacy & Copyright policy</Link>
            </div>
          </div>
          {userInfo && userInfo.plan == "premium" && userInfo.role == "user" && (
            <div className="submit">
              <Link className="nav-link" to="/submit">
                Submit
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* ///////////////////MOBILE */}

      {pathname == "/" || pathname == "/category" ? (
        <div className="nav2 row">
          <Link to="/" className={pathname == "/" && "active"}>
            Home
          </Link>
          <Link to="/category" className={pathname == "/category" && "active"}>
            Categories
          </Link>
        </div>
      ) : (
        ""
      )}
    </StyledNav>
  );
};

const StyledNav = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;

  display: flex;
  flex-flow: column wrap;

  .nav2 {
    display: flex;
    flex-direction: row;
    padding: 0rem 0.6rem;
    width: 100%;

    @media screen and (max-width: 900px) {
    }
    background: ${({ isDarkMode }) =>
      isDarkMode ? "rgb(23, 24, 24,0.9)" : "rgb(248, 248, 248,0.9)"};
    color: #f8f8f8;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    .active {
      border-bottom: 2px solid#5ab4d4;
      color: #50c1e9;
    }
    a {
      padding: 1rem 0.7rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      @media screen and (max-width: 900px) {
        padding: 0.8rem 0.7rem;
      }
    }
  }
  .navbar {
    z-index: 4;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    @media screen and (max-width: 900px) {
      display: none;
    }

    #toggle {
      display: none;
    }
    .hide {
      display: none;
    }

    .icon {
      font-size: 1.5rem;
      padding-left: 1rem;
      width: 30%;

      a {
        display: flex;
        align-items: center;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
      img {
        width: 7rem;
        padding-left: 0.4rem;
      }
    }
    .form {
      width: 40%;
      display: flex;
      .suggestions {
        display: none;
      }

      form {
        width: 78%;
        margin-right: auto;
        margin-left: auto;
        display: flex;
      }
      input {
        padding: 0.85rem 0.4rem;
        border: 0px;
        border-right: none;
        border-radius: 5px 0px 0px 5px;
        width: 86%;
        font-size: 0.9rem;
        background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
        caret-color: ${({ isDarkMode }) =>
          isDarkMode ? "#d4d4d4" : "#868686"};
        &:focus {
          outline: none;
        }
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }

      button {
        padding: 0.7rem 1rem;
        border: 0px;
        background-color: #50c1e9;
        border-radius: 0px 5px 5px 0px;
        width: 14%;
        font-size: 0.9rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
        border: 2px solid #50c1e9;
        &:hover {
          background: #4cb1d6;
        }
      }
    }
    .nav-right {
      width: 30%;
      display: flex;
      font-size: 0.95rem;
      font-weight: 500;
      justify-content: flex-end;
      .premiumButton {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0rem 0.7rem;
        button {
          width: 6rem;
          padding: 0.4rem;
          border: 2px solid #50c1e9;
          border-radius: 4px;
          background: transparent;
          color: #50c1e9;
          font-size: 0.9rem;
          cursor: pointer;
          &:hover {
            color: white;
            background: #50c1e9;
          }
        }
      }
      .submit {
        display: flex;
        align-items: center;
        padding-right: 0.4rem;
        a {
          color: #50c1e9;
          font-weight: 550;
          font-size: 1rem;
          &:hover {
            color: #4cb1d6;
          }
        }
      }
      .dropdown {
        position: relative;
        display: flex;
        border: none;
        &:hover {
          cursor: pointer;
          color: white;
          .hover-down {
            display: flex;
          }
        }

        .hover-down {
          padding: 0.5rem;
          padding-bottom: 1.3rem;
          display: flex;
          flex-flow: column wrap;
          position: absolute;
          top: 100%;
          right: 0;
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#171818" : "#f8f8f8"};
          box-shadow: 0px 0px 3px 1.5px
            ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
          z-index: 1;
          margin: 0rem;
          transition: 0.1s ease-in-out;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          display: none;
          cursor: pointer;
          .line {
            height: 2px;
            width: 100%;
            background-color: #99a8b2;
          }
          button {
            border-radius: 50%;
            padding: 0.16rem 0.25rem;
            border: none;
            cursor: pointer;
            background-color: inherit;
            border: 2px solid
              ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
            color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
            .fI {
              color: ${({ isDarkMode }) =>
                isDarkMode ? "whitesmoke" : "gray"};
              font-size: 1.3rem;
              &:hover {
                border: 2px solid #50c1e9;
                border-radius: 50%;
              }
            }
          }
          .sun {
            &:hover {
              border: 2px solid #50c1e9;
            }
          }
          .moon {
            &:hover {
              border: 2px solid #50c1e9;
            }
          }
          .theme {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.6rem 0rem 0.6rem 0.6rem;
            height: 2.5rem;
            font-size: 0.9rem;
            &:hover {
              color: #50c1e9;
            }
          }
          a {
            display: inline;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 0.6rem 0rem 0.6rem 0.6rem;
            height: 2rem;
            font-size: 0.9rem;
            width: 10rem;
            &:hover {
              color: #50c1e9;
            }
          }
        }
        .hovNot {
          width: 25rem;
          .line {
            height: 1px;
            width: 100%;
            background: ${({ isDarkMode }) =>
              isDarkMode ? "gray" : "#cfcbcb;"};
            background: #cfcbcb;
            margin: 0rem 0rem;
          }
          h3 {
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 0.7rem 0rem;
          }
          .notification {
            font-size: 0.9rem;
            padding: 1rem 0rem;
            display: flex;
            &:hover {
              background: ${({ isDarkMode }) =>
                isDarkMode ? "rgb(80,193,233,0.5)" : "rgb(80,193,233,0.3)"};
            }
            .left {
              flex: 12%;
              img {
                width: 2rem;
                border-radius: 2px;
              }
            }
            .right {
              flex: 88%;
              display: flex;
              justify-content: flex-start;
              padding: 0rem 0.4rem;
              a {
                padding: 0rem 0rem 0rem 0rem;
                width: auto;
              }
              h5 {
                padding: 0rem 0rem 0rem 0.3rem;
                width: auto;
                font-weight: 500;
                font-size: 0.9rem;
              }
            }
          }
          .noNotification {
            margin-left: auto;
            margin-right: auto;
            padding: 1rem;
          }
          .feedBack {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            a {
              padding: none;
              width: 5rem;
              font-size: 1rem;
            }
          }
        }
      }
      .profile {
        .image {
          display: flex;
          align-items: center;
          padding: 0rem 0.7rem;
          img {
            width: 2rem;
            border-radius: 5px;
          }
        }
        .resend-email {
          padding: 0.2rem 0.7rem;
          display: flex;
          flex-flow: column wrap;
          border: 2px solid #50c1e9;
          margin-bottom: 1rem;
          border-radius: 4px;
          color: #50c1e9;
          span {
            padding: 0rem 0rem;
          }
          small {
            display: flex;
            align-items: center;
            button {
              margin-left: 0.4rem;
              border: 2px solid #50c1e9;
              border-radius: 2px;
              padding: 0.2rem;
              color: #50c1e9;
              font-size: 0.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 5rem;
              .icon {
                width: 2rem;
                font-size: 1rem;
                margin: none;
              }
            }
          }
        }
        .hover-down {
          padding: 1rem;
          width: 20rem;
          .userName {
            a {
              font-size: 1.5rem;
              font-weight: 500;
            }
          }
          span {
            padding: 0.6rem 0rem;
            font-size: 0.9rem;
            width: 100%;
          }
          .line {
            height: 2px;
            width: 100%;
            background-color: #99a8b2;
          }
        }
      }
      .login {
        .hover-down {
          a {
            display: inline;
            color: black;
            padding: 0.6rem 0rem 0.6rem 0.6rem;
            font-size: 0.9rem;
            width: 8rem;
          }
          .line {
            height: 2px;
            width: 100%;
            background-color: #99a8b2;
          }
        }
      }
      .more {
        &:hover {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }
    }
    .nav-link {
      padding: 1.3rem 0.7rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #50c1e9;
      }
      .fIcon {
        margin: 0px 4px;
      }
    }
  }
`;

//////////////////
///////////////
////////////
/////////
//////
///

export default Nav;

// ///
// #showMenu {
//   transform: translateX(0%);
// }
