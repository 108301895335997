import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//actions
import { logout, getLoggedUserStatus } from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";

function AdminNav({ pathname, isDarkMode }) {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
  };
  return (
    <StyledAdminNav isDarkMode={isDarkMode}>
      <Link
        className={pathname == "/admin/userlist" && "active"}
        to="/admin/userlist"
      >
        Users
      </Link>
      <Link
        className={pathname == "/admin/postlist" && "active"}
        to="/admin/postlist"
      >
        Posts
      </Link>
      <Link
        className={pathname == "/admin/categorylist" && "active"}
        to="/admin/categorylist"
      >
        Categories
      </Link>

      <h3>ADMIN PANEL</h3>
    </StyledAdminNav>
  );
}

const StyledAdminNav = styled(motion.div)`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow: auto;
  white-space: nowrap;
  padding: 0rem 0.6rem;
  width: 100%;
  align-items: center;
  background: ${({ isDarkMode }) =>
    isDarkMode ? "rgb(23, 24, 24,0.9)" : "rgb(248, 248, 248,0.9)"};

  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  h3 {
    margin-left: auto;
    padding: 1rem 0.7rem;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  .active {
    border-bottom: 2px solid#5ab4d4;
    color: #50c1e9;
  }
  a {
    padding: 1rem 0.7rem;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    &:hover {
      color: #50c1e9;
    }
  }
`;

export default AdminNav;
