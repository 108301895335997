import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const isDarkFromStorage = localStorage.getItem("isDark")
  ? JSON.parse(localStorage.getItem("isDark"))
  : false;

const displayMatureFromStorage = localStorage.getItem("displayMature")
  ? JSON.parse(localStorage.getItem("displayMature"))
  : false;

const initialState = {
  isDark: isDarkFromStorage,
  displayMature: displayMatureFromStorage,
  userLogin: { userInfo: userInfoFromStorage },
};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;
