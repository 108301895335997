import {
  POST_LIST_FAIL,
  POST_LIST_SUCCESS,
  POST_LIST_REQUEST,
  POST_DETAILS_FAIL,
  POST_DETAILS_SUCCESS,
  POST_DETAILS_REQUEST,
  LIKE_POST_FAIL,
  LIKE_POST_SUCCESS,
  LIKE_POST_REQUEST,
  LIKE_POST_RESET,
  UNLIKE_POST_FAIL,
  UNLIKE_POST_SUCCESS,
  UNLIKE_POST_REQUEST,
  UNLIKE_POST_RESET,
  USER_POST_LIST_FAIL,
  USER_POST_LIST_SUCCESS,
  USER_POST_LIST_REQUEST,
  SIMILAR_POST_LIST_FAIL,
  SIMILAR_POST_LIST_SUCCESS,
  SIMILAR_POST_LIST_REQUEST,
  SEARCHED_POST_LIST_FAIL,
  SEARCHED_POST_LIST_SUCCESS,
  SEARCHED_POST_LIST_REQUEST,
  POST_DELETE_FAIL,
  POST_DELETE_SUCCESS,
  POST_DELETE_REQUEST,
  POST_DELETE_RESET,
  POST_CREATE_FAIL,
  POST_CREATE_SUCCESS,
  POST_CREATE_REQUEST,
  POST_CREATE_RESET,
  POST_UPDATE_FAIL,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_REQUEST,
  POST_UPDATE_RESET,
  REPORT_POST_FAIL,
  REPORT_POST_SUCCESS,
  REPORT_POST_REQUEST,
  REPORT_POST_RESET,
} from '../constants/postConstants';
export const postListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case POST_LIST_REQUEST:
      return { loading: true, ...state };
    case POST_LIST_SUCCESS:
      return {
        loading: false,
        posts: action.payload.data.doc,
        pages: action.payload.data.pages,
        page: action.payload.data.page,
      };
    case POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const postDetailsReducer = (
  state = {
    post: {
      comments: [],
      views: [],
      image: {},
      imageDimensions: {},
      likes: [],
      user: { photo: {}, followers: [] },
    },
  },
  action
) => {
  switch (action.type) {
    case POST_DETAILS_REQUEST:
      return { loading: true, ...state };
    case POST_DETAILS_SUCCESS:
      return { loading: false, post: action.payload.data };
    case POST_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const likePostReducer = (state = {}, action) => {
  switch (action.type) {
    case LIKE_POST_REQUEST:
      return { loading: true };
    case LIKE_POST_SUCCESS:
      return { loading: false, success: true };
    case LIKE_POST_FAIL:
      return { loading: false, error: action.payload };
    case LIKE_POST_RESET:
      return {};
    default:
      return state;
  }
};

export const unlikePostReducer = (state = {}, action) => {
  switch (action.type) {
    case UNLIKE_POST_REQUEST:
      return { loading: true };
    case UNLIKE_POST_SUCCESS:
      return { loading: false, success: true };
    case UNLIKE_POST_FAIL:
      return { loading: false, error: action.payload };
    case UNLIKE_POST_RESET:
      return {};
    default:
      return state;
  }
};

export const userPostListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case USER_POST_LIST_REQUEST:
      return { loading: true, ...state };
    case USER_POST_LIST_SUCCESS:
      return { loading: false, posts: action.payload.data };
    case USER_POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const similarPostListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case SIMILAR_POST_LIST_REQUEST:
      return { loading: true, ...state };
    case SIMILAR_POST_LIST_SUCCESS:
      return { loading: false, posts: action.payload.data };
    case SIMILAR_POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchedPostListReducer = (state = { posts: [] }, action) => {
  switch (action.type) {
    case SEARCHED_POST_LIST_REQUEST:
      return { loading: true, ...state };
    case SEARCHED_POST_LIST_SUCCESS:
      return { loading: false, posts: action.payload.data.doc };
    case SEARCHED_POST_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_DELETE_REQUEST:
      return { loading: true };
    case POST_DELETE_SUCCESS:
      return { loading: false, success: true };
    case POST_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case POST_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const postCreateReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case POST_CREATE_REQUEST:
      return { loading: true };
    case POST_CREATE_SUCCESS:
      return { loading: false, success: true, post: action.payload.data };
    case POST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case POST_CREATE_RESET:
      return { post: {} };
    default:
      return state;
  }
};
export const postUpdateReducer = (state = { post: {} }, action) => {
  switch (action.type) {
    case POST_UPDATE_REQUEST:
      return { loading: true };
    case POST_UPDATE_SUCCESS:
      return { loading: false, success: true, post: action.payload.data };
    case POST_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case POST_UPDATE_RESET:
      return { post: {} };
    default:
      return state;
  }
};

export const reportPostReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_POST_REQUEST:
      return { loading: true };
    case REPORT_POST_SUCCESS:
      return { loading: false, success: true };
    case REPORT_POST_FAIL:
      return { loading: false, error: action.payload };
    case REPORT_POST_RESET:
      return {};
    default:
      return state;
  }
};
