
export const REPLY_DELETE_REQUEST = "REPLY_DELETE_REQUEST";
export const REPLY_DELETE_FAIL = "REPLY_DELETE_FAIL";
export const REPLY_DELETE_SUCCESS = "REPLY_DELETE_SUCCESS";
export const REPLY_DELETE_RESET = "REPLY_DELETE_RESET";

export const REPLY_CREATE_REQUEST = "REPLY_CREATE_REQUEST";
export const REPLY_CREATE_FAIL = "REPLY_CREATE_FAIL";
export const REPLY_CREATE_SUCCESS = "REPLY_CREATE_SUCCESS";
export const REPLY_CREATE_RESET = "REPLY_CREATE_RESET";

export const REPLY_UPDATE_REQUEST = "REPLY_UPDATE_REQUEST";
export const REPLY_UPDATE_FAIL = "REPLY_UPDATE_FAIL";
export const REPLY_UPDATE_SUCCESS = "REPLY_UPDATE_SUCCESS";
export const REPLY_UPDATE_RESET = "REPLY_UPDATE_RESET";

export const LIKE_REPLY_REQUEST = "LIKE_REPLY_REQUEST";
export const LIKE_REPLY_FAIL = "LIKE_REPLY_FAIL";
export const LIKE_REPLY_SUCCESS = "LIKE_REPLY_SUCCESS";
export const LIKE_REPLY_RESET = "LIKE_REPLY_RESET";

export const UNLIKE_REPLY_REQUEST = "UNLIKE_REPLY_REQUEST";
export const UNLIKE_REPLY_FAIL = "UNLIKE_REPLY_FAIL";
export const UNLIKE_REPLY_SUCCESS = "UNLIKE_REPLY_SUCCESS";
export const UNLIKE_REPLY_RESET = "UNLIKE_REPLY_RESET";