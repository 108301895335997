
import {
    CHANGE_MATURE
    } from "../constants/matureConstants";
    
    export const changeMature=()=>async(dispatch,getState)=>{
    
    if(getState().displayMature){
        dispatch({
            type:CHANGE_MATURE,
            payload:false
        })
        localStorage.setItem("displayMature", JSON.stringify(false));
       }else{
        dispatch({
            type:CHANGE_MATURE,
            payload:true
        })
         localStorage.setItem("displayMature", JSON.stringify(true));
       }
    }