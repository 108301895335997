import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "../components/Loader";
import Message from "../components/Message";

import AccountAbout from "../components/AccountAbout";
import AccountHome from "../components/AccountHome";
import AccountPosts from "../components/AccountPosts";
import ShareModal from "../components/ShareModal";
//actions
import { listUserPosts } from "../actions/postActions";
import {
  logout,
  getUserDetails,
  updateUserProfile,
  unFollowUser,
  followUser,
} from "../actions/userActions";

//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import {
  USER_DETAILS_RESET,
  UNFOLLOW_USER_RESET,
  FOLLOW_USER_RESET,
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";
import ShareIcon from "@material-ui/icons/Share";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";
const Account = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const profileName = pathname.split("/")[1];
  const [shareOn, setShareOn] = useState(false);

  const [uploadPhoto, setUploadPhoto] = useState(false);
  const [uploadCoverPhoto, setUploadCoverPhoto] = useState(false);

  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user, error } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success,
    error: updateError,
    loading: updateLoading,
  } = userUpdateProfile;

  const _followUser = useSelector((state) => state.followUser);
  const {
    loading: followLoading,
    success: followSuccess,
    error: followError,
  } = _followUser;

  const _unFollowUser = useSelector((state) => state.unFollowUser);
  const {
    loading: unfollowLoading,
    success: unfollowSuccess,
    error: unfollowError,
  } = _unFollowUser;

  const userPostList = useSelector((state) => state.userPostList);
  const {
    loading: userPostsLoading,
    posts: userPosts,
    error: userPostsError,
  } = userPostList;
  useEffect(() => {
    if (user && !user.userName) {
      dispatch(listUserPosts({ userName: profileName, isMature }));
      dispatch(getUserDetails(profileName));
    } else if (user == null || undefined) {
      dispatch({ type: USER_DETAILS_RESET });
      history.push("/404");
    } else if (profileName !== user.userName) {
      dispatch(listUserPosts({ userName: profileName, isMature }));
      dispatch(getUserDetails(profileName));
    } else if (success) {
      dispatch(getUserDetails(profileName));
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
    } else if (followSuccess) {
      dispatch(getUserDetails(profileName));
      dispatch({ type: FOLLOW_USER_RESET });
    } else if (unfollowSuccess) {
      dispatch(getUserDetails(profileName));
      dispatch({ type: UNFOLLOW_USER_RESET });
    } else {
    }
  }, [
    dispatch,
    history,
    pathname,
    success,
    updateError,
    isMature,
    followSuccess,
    unfollowSuccess,
    user,
  ]);

  useEffect(() => {
    if (user == null || undefined) {
      dispatch({ type: USER_DETAILS_RESET });
      history.push("/404");
    }
  }, []);

  const handleFollow = () => {
    if (userInfo) {
      user.followers.filter((follower) => follower.id == userInfo._id).length >
        0 || user.followers.includes(userInfo.id)
        ? dispatch(unFollowUser(user.id))
        : dispatch(followUser(user.id));
    } else {
      history.push("/login?rediect=janvinsha");
    }
  };

  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);

  const photoUpdateHandler = () => {
    setUploadPhoto(false);
    dispatch(updateUserProfile({ photo: files1[0] }));
  };

  const coverPhotoUpdateHandler = () => {
    setUploadCoverPhoto(false);
    dispatch(updateUserProfile({ coverPhoto: files2[0] }));
  };

  const exitUploadPhotoHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow1")) {
      setUploadPhoto(false);
    }
  };
  const exitUploadCoverPhotoHandler = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow2")) {
      setUploadCoverPhoto(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      // Do something with the files
      // POST to a test endpoint for demo purposes
      setFiles1(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    isDragActive: isDragActive2,
  } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      // Do something with the files
      // POST to a test endpoint for demo purposes
      setFiles2(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <StyledAccount
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
      backgroundImage={user && user.userName ? user.coverPhoto.url : null}
      coverPhoto={user && user.userName ? user.coverPhoto.url : null}
    >
      <Meta title={`${user && user.userName}'s account`} />
      {userUpdateProfile.error && (
        <Message variant="danger">{userUpdateProfile.error}</Message>
      )}
      {success && (
        <Message variant="success">Profile updated successfully</Message>
      )}
      {updateLoading && <Loader />}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : user !== null ? (
        <>
          {user && user.coverPhoto.url && (
            <div className="img">
              <img src={user.coverPhoto.url} />
            </div>
          )}
          <div className="account">
            <div className="userDetails">
              <div className="left">
                {userInfo
                  ? userInfo.userName === user.userName && (
                      <span className="change">
                        <span>
                          <FontAwesomeIcon icon={faEdit} size="1.5x" />
                        </span>
                        <span>Change</span>
                      </span>
                    )
                  : ""}
                <img
                  src={user.photo.url}
                  onClick={() =>
                    userInfo
                      ? userInfo.userName === user.userName
                        ? setUploadPhoto(true)
                        : history.push(`/${user.userName}`)
                      : history.push(`/${user.userName}`)
                  }
                />
              </div>
              <div className="right">
                <span className="lft">
                  <span className="userName">
                    <Link to={`/${user.userName}`}>{user.userName}</Link>
                  </span>
                  <span>
                    {user && user.followers.length}{" "}
                    {user && user.followers.length == 1
                      ? "Follower"
                      : "Followers"}{" "}
                    | {(user.views && user.views.length) || 0}{" "}
                    {user.views.length == 1 ? "Page View" : "Page Views"} |{" "}
                    {userPosts.length}{" "}
                    {userPosts.length == 1 ? "Post" : "Posts"}
                  </span>
                </span>
                <span className="rght">
                  {userInfo
                    ? userInfo.userName === user.userName && (
                        <>
                          <span
                            className="coverBtn"
                            onClick={() =>
                              userInfo
                                ? userInfo.userName === user.userName &&
                                  setUploadCoverPhoto(true)
                                : ""
                            }
                          >
                            {user.coverPhoto.url
                              ? "Change Cover Photo"
                              : "Add cover photo"}
                          </span>
                          <span
                            className="coverBtnMob"
                            onClick={() =>
                              userInfo
                                ? userInfo.userName === user.userName &&
                                  setUploadCoverPhoto(true)
                                : ""
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} size="1.5x" />
                          </span>
                        </>
                      )
                    : ""}
                </span>
              </div>
            </div>
            <div className="tab">
              <span>
                <Link
                  to={`/${user.userName}`}
                  className={pathname == `/${user.userName}` && "active"}
                >
                  POSTS
                </Link>
              </span>
              <span>
                <Link
                  to={`/${user.userName}/about`}
                  className={pathname == `/${user.userName}/about` && "active"}
                >
                  ABOUT
                </Link>
              </span>

              <div className="right">
                <FontAwesomeIcon
                  icon={faShare}
                  size="1.8x"
                  className="ellipsis"
                  onClick={() => setShareOn(!shareOn)}
                />

                {userInfo ? (
                  userInfo.userName != user.userName &&
                  (user.followers.filter(
                    (follower) => follower.id == userInfo._id
                  ).length > 0 || user.followers.includes(userInfo.id) ? (
                    <span className="unfollow" onClick={handleFollow}>
                      UNFOLLOW{" "}
                      {unfollowLoading && (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      )}{" "}
                    </span>
                  ) : (
                    <span className="follow" onClick={handleFollow}>
                      FOLLOW{" "}
                      {followLoading && (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      )}
                    </span>
                  ))
                ) : (
                  <span className="follow" onClick={handleFollow}>
                    FOLLOW{" "}
                    {followLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {uploadPhoto && (
        <StyledUpload
          className="upload shadow1"
          onClick={exitUploadPhotoHandler}
          isDarkMode={isDarkMode}
        >
          <div className="middle">
            <div className="title">
              Upload profile picture
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setUploadPhoto(false)}
              />
            </div>

            <div className="upload" {...getRootProps()}>
              <input {...getInputProps()} />
              {files1 && files1.map((file) => <img src={file.preview} />)}

              {files1.length > 0 ? (
                ""
              ) : isDragActive ? (
                <p>Drop the image here ...</p>
              ) : (
                <p>Drag 'n' drop the image here, or click to select image</p>
              )}
            </div>

            <div className="confirm">
              <button className="cancel" onClick={() => setUploadPhoto(false)}>
                Cancel
              </button>
              <button onClick={photoUpdateHandler}>Confirm</button>
            </div>
          </div>
        </StyledUpload>
      )}

      {uploadCoverPhoto && (
        <StyledUpload
          className="upload shadow2"
          onClick={exitUploadCoverPhotoHandler}
          isDarkMode={isDarkMode}
        >
          <div className="middle">
            <div className="title">
              Upload Cover photo
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setUploadCoverPhoto(false)}
              />
            </div>

            <div className="upload" {...getRootProps2()}>
              <input {...getInputProps2()} />
              {files2 && files2.map((file) => <img src={file.preview} />)}

              {files2.length > 0 ? (
                ""
              ) : isDragActive2 ? (
                <p>Drop the image here ...</p>
              ) : (
                <p>Drag 'n' drop the image here, or click to select image</p>
              )}
            </div>

            <div className="confirm">
              <button
                className="cancel"
                onClick={() => setUploadCoverPhoto(false)}
              >
                Cancel
              </button>
              <button onClick={coverPhotoUpdateHandler}>Confirm</button>
            </div>
          </div>
        </StyledUpload>
      )}
      {loading ? (
        ""
      ) : user !== null || undefined ? (
        <StyledPages isDarkMode={isDarkMode}>
          {pathname == `/${user && user.userName}` ? (
            <AccountPosts user={user} posts={userPosts} userInfo={userInfo} />
          ) : pathname == `/${user && user.userName}/about` ? (
            <AccountAbout
              user={user}
              userInfo={userInfo}
              isDarkMode={isDarkMode}
            />
          ) : (
            ""
          )}
        </StyledPages>
      ) : (
        ""
      )}
      <ShareModal
        isDarkMode={isDarkMode}
        title="Share"
        on={shareOn}
        setOn={setShareOn}
      />
      <Footer />
      <ScrollTop />
    </StyledAccount>
  );
};

const StyledUpload = styled(motion.div)`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  img {
    height: 100%;
    object-fit: cover;
  }
  .middle {
    @media screen and (max-width: 900px) {
      width: 90%;
      height: 60vh;
      padding: 0.6rem 0rem;
    }
    width: 50%;
    height: 82vh;
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    display: flex;
    flex-flow: column wrap;
    padding: 1.5rem 0rem;
    z-index: 20;
    .title {
      height: 15%;
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      padding: 0rem 2rem;
      @media screen and (max-width: 900px) {
        font-size: 1.3rem;
        padding: 0rem 1rem;
        align-items: center;
      }
      .icon {
        cursor: pointer;
        margin-left: auto;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
    }
    .upload {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
      height: 62%;
      margin: 0rem 2rem;
      cursor: pointer;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      @media screen and (max-width: 900px) {
        margin: 0rem 1rem;
        padding: 1rem;
      }
      p {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1rem;
      }
      &:focus {
        outline: none;
      }
      button {
        padding: 0.7rem 1.4rem;
        margin-top: 0.3rem;
        @media screen and (max-width: 900px) {
          padding: 0.6rem 1.2rem;
        }
      }
    }
    .confirm {
      height: 15%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      padding: 2rem 2rem;
      @media screen and (max-width: 900px) {
        padding: 2rem 1rem;
      }
      button {
        padding: 0.6rem 1.2rem;
        margin-left: 0.8rem;
        background-color: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
        border: 2px solid #50c1e9;
        border-radius: 4px;
        &:hover {
          background-color: #4cb1d6;
        }
      }
      .cancel {
        background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        border: 2px solid
          ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
        &:hover {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#0f0f0f" : "#ffffff"};
        }
      }
    }
  }
`;

const StyledAccount = styled(motion.div)`
  width: 100%;
  height: 100vh;
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  z-index: 1;
  .img {
    z-index: -2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      opacity: 0.85;
    }
    background: ${({ isDarkMode }) => (isDarkMode ? "black" : "white")};
  }
  .account {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    background: ${({ coverPhoto, isDarkMode }) =>
      coverPhoto ? "" : isDarkMode ? "#171818" : "#f8f8f8"};
    padding: 1.5rem 4rem;
    display: flex;
    flex-flow: column;
    padding-top: 10rem;
    @media (max-width: 900px) {
      padding: 0rem 0rem;
      padding-top: 2.5rem;
    }
    .userDetails {
      display: flex;
      @media (max-width: 900px) {
        flex-flow: column wrap;
        align-items: center;
        position: relative;
      }
      .left {
        position: relative;
        flex: 10%;
        display: flex;
        align-items: center;
        margin: 0.6rem;
        background: black;
        border-radius: 4px;
        overflow: hidden;
        @media (max-width: 900px) {
          flex: 100%;
          margin: 0rem;
        }
        &:hover {
          .change {
            display: flex;
            align-items: center;
          }
          img {
            opacity: 0.8;
          }
        }
        img {
          width: 100%;
          cursor: pointer;
          @media (max-width: 900px) {
            width: 5.5rem;
          }
        }
        .change {
          display: none;
          flex-flow: column wrap;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1rem;
          color: #50c1e9;
          background: none;
          z-index: 2;
          cursor: pointer;
        }
      }
      .right {
        padding: 0.6rem;
        flex: 90%;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        @media (max-width: 900px) {
          padding: 0rem;
          flex-flow: column wrap;
          align-items: center;
        }
        .lft {
          display: flex;
          flex-flow: column wrap;
          flex: 50%;
          @media (max-width: 900px) {
            padding-bottom: 0.8rem;
          }
        }
        .rght {
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          flex: 50%;
          padding: 2rem;
          @media (max-width: 900px) {
            padding: 0rem;
          }
          &:hover {
            .coverBtn {
              display: flex;
              @media (max-width: 900px) {
                display: none;
              }
            }
          }
          .coverBtn {
            display: none;
            margin-left: auto;
            cursor: pointer;
            border: 2px solid #50c1e9;
            padding: 0.4rem;
            border-radius: 10px;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            &:hover {
              border: 2px solid #5892d4;
            }
          }
          .coverBtnMob {
            display: none;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 0.5rem;
            @media (max-width: 900px) {
              display: flex;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0, -50%);
            }
          }
        }
        .userName {
          a {
            font-size: 2.5rem;
            font-weight: 500;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          }
          @media (max-width: 900px) {
            text-align: center;
            padding: 0rem;
            padding-bottom: 0.3rem;

            a {
              font-size: 1.8rem;
            }
          }
          padding-bottom: 0.4rem;
        }
      }
    }
  }
  .tab {
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    margin: 0.6rem;
    margin-top: 2rem;
    display: flex;
    flex-flow: row wrap;
    @media (max-width: 900px) {
      margin-top: 0rem;
      margin: 0rem;
      position: sticky;
    }
    span {
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
      padding: 0.8rem;
      font-size: 0.9rem;
      .active {
        color: #50c1e9;
        border-bottom: 2px solid #50c1e9;
        padding: 0.8rem;
      }
    }
    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      .ellipsis {
        margin-right: 0.8rem;
        cursor: pointer;
      }
      span {
        background: #50c1e9;

        border: 2px solid #50c1e9;

        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
    }
  }
`;

const StyledPages = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
`;

export default Account;
