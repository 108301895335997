import React from "react";

//Redux
import { useSelector } from "react-redux";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";
const Terms = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledAbout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Terms and conditions" />
      <div className="about">
        <div className="section">
          <h2>Terms and conditions</h2>
          <div>
            Introduction Please read these Terms of Service ("Terms") carefully.
            They contain the legal terms and conditions that govern your use of
            services provided to you by Wandart, including information, text,
            images, graphics, data or other materials ("Content") and products
            and services provided through www.wandart.art This Service is
            operated by Wandart, Inc. ("Wandart"). Wandart is also referred to
            in these Terms as "we", "our", and "us". By using our Service, you
            agree to be bound by Section I of these Terms ("General Terms"),
            which contains provisions applicable to all users of our Service,
            including visitors to the Wandart website (the "Site"). If you
            choose to register as a member of our Service, you will be asked to
            check a box indicating that you have read, and agree to be bound by,
            the additional terms set forth in Section II of these Terms
            ("Additional Terms").
          </div>
          <h3>Section I: General Terms</h3>
          <ol>
            {" "}
            <li>
              {" "}
              <h4>Availability</h4> This Service is provided by Wandart on an
              "AS IS" and "AS AVAILABLE" basis and Wandart reserves the right to
              modify, suspend or discontinue the Service, in its sole
              discretion, at any time and without notice. You agree that Wandart
              is and will not be liable to you for any modification, suspension
              or discontinuance of the Service.
            </li>{" "}
            <li>
              {" "}
              <h4>Privacy</h4> Wandart has a firm commitment to safeguarding
              your privacy. Please review Wandart's Privacy Policy. The terms of
              Wandart's privacy policy are incorporated into, and form a part
              of, these Terms.
            </li>{" "}
            <li>
              {" "}
              <h4>Trademarks</h4> All brand, product and service names used in
              this Service which identify Wandart or third parties and their
              products and services are proprietary marks of Wandart and/or the
              relevant third parties. Nothing in this Service shall be deemed to
              confer on any person any license or right on the part of Wandart
              or any third party with respect to any such image, logo or name.
            </li>{" "}
            <li>
              {" "}
              <h4>Copyright</h4> Wandart is, unless otherwise stated, the owner
              of all copyright and data rights in the Service and its contents.
              Individuals who have posted works to Wandart are either the
              copyright owners of the component parts of that work or are
              posting the work under license from a copyright owner or his or
              her agent or otherwise as permitted by law. You may not reproduce,
              distribute, publicly display or perform, or prepare derivative
              works based on any of the Content including any such works without
              the express, written consent of Wandart or the appropriate owner
              of copyright in such works. Wandart does not claim ownership
              rights in your works or other materials posted by you to Wandart
              (Your Content). You agree not to distribute any part of the
              Service other than Your Content in any medium other than as
              permitted in these Terms of Service or by use of functions on the
              Service provided by us. You agree not to alter or modify any part
              of the Service unless expressly permitted to do so by us or by use
              of functions on the Service provided by us.
            </li>{" "}
            <li>
              {" "}
              <h4>Reporting Copyright Violations</h4> Wandart respects the
              intellectual property rights of others and expects users of the
              Service to do the same. At Wandart's discretion and in appropriate
              circumstances, Wandart may remove Your Content submitted to the
              Site, terminate the accounts of users or prevent access to the
              Site by users who infringe the intellectual property rights of
              others. If you believe the copyright in your work or in the work
              for which you act as an agent has been infringed through this
              Service, please contact Wandart's agent for notice of claims of
              copyright infringement. You must provide our agent with
              substantially the following information, which Wandart may then
              forward to the alleged infringer. A physical or electronic
              signature of a person authorized to act on behalf of the owner of
              an exclusive right that is allegedly infringed. Identification of
              the copyrighted work claimed to have been infringed, or, if
              multiple copyrighted works at a single online site are covered by
              a single notification, a representative list of such works at that
              site. Identification of the material that is claimed to be
              infringing or to be the subject of infringing activity and that is
              to be removed or access to which is to be disabled, and
              information reasonably sufficient to permit the service provider
              to locate the material. Information reasonably sufficient to
              permit the service provider to contact the complaining party, such
              as an address, telephone number, and, if available, an electronic
              mail address at which the complaining party may be contacted. A
              statement that the complaining party has a good faith belief that
              use of the material in the manner complained of is not authorized
              by the copyright owner. A statement that the information in the
              notification is accurate, and under penalty of perjury, that the
              complaining party is authorized to act on behalf of the owner of
              an exclusive right that is allegedly infringed. Please see
              Wandart's Copyright Policy for further information and details.
            </li>{" "}
            <li>
              <h4>External Links</h4> Wandart may provide links to third-party
              websites or resources. You acknowledge and agree that Wandart is
              not responsible or liable for: the availability or accuracy of
              such websites or resources; or the Content, products, or services
              on or available from such websites or resources. Links to such
              websites or resources do not imply any endorsement by Wandart of
              such websites or resources or the Content, products, or services
              available from such websites or resources. You acknowledge sole
              responsibility for and assume all risk arising from your use of
              any such websites or resources.
            </li>{" "}
            <li>
              {" "}
              <h4>Third Party Software</h4> As a convenience, we may make
              third-party software available through the Service. To use the
              third-party software, you must agree to the terms and conditions
              imposed by the third party provider and the agreement to use such
              software will be solely between you and the third party provider.
              By downloading third party software, you acknowledge and agree
              that the software is provided on an "AS IS" basis without warranty
              of any kind. In no event shall Wandart be liable for claims or
              damages of any nature, whether direct or indirect, arising from or
              related to any third-party software downloaded through the
              Service.
            </li>{" "}
            <li>
              {" "}
              <h4>Conduct</h4> You agree that you shall not interfere with or
              disrupt (or attempt to interfere with or disrupt) this Service or
              servers or networks connected to this Service, or to disobey any
              requirements, procedures, policies or regulations of networks
              connected to this Service; or provide any information to Wandart
              that is false or misleading, that attempts to hide your identity
              or that you do not have the right to disclose. Wandart does not
              endorse any content placed on the Service by third parties or any
              opinions or advice contained in such content. You agree to defend,
              indemnify, and hold harmless Wandart, its officers, directors,
              employees and agents, from and against any claims, liabilities,
              damages, losses, and expenses, including, without limitation,
              reasonable legal and expert fees, arising out of or in any way
              connected with your access to or use of the Services, or your
              violation of these Terms.
            </li>{" "}
            <li>
              {" "}
              <h4> Disclaimer of Warranty and Limitation of Liability </h4>
              Wandart makes no representations or warranties of any kind,
              express or implied as to the operation of the service, or the
              content or products, provided through the service. you expressly
              agree that your use of the service is at your sole risk. wandart
              disclaims all warranties, express or implied, including without
              limitation, implied warranties of merchantability, fitness for a
              particular purpose, and non-infringement, to the fullest extent
              permitted by law. wandart makes no warranty as to the security,
              reliability, timeliness, and performance of this service. you
              specifically acknowledge that wandart is not liable for your
              defamatory, offensive or illegal conduct, or such conduct by third
              parties, and you expressly assume all risks and responsibility for
              damages and losses arising from such conduct. except for the
              express, limited remedies provided herein, and to the fullest
              extent allowed by law, wandart shall not be liable for any damages
              of any kind arising from use of the service, including but not
              limited to direct, indirect, incidental, consequential, special,
              exemplary, or punitive damages, even if wandart has been advised
              of the possibility of such damages. the foregoing disclaimers,
              waivers and limitations shall apply notwithstanding any failure of
              essential purpose of any limited remedy. some jurisdictions do not
              allow the exclusion of or limitations on certain warranties or
              damages. therefore, some of the above exclusions or limitations
              may not apply to you. in no event shall wandart's aggregate
              liability to you exceed the amounts paid by you to wandart
              pursuant to this agreement.
            </li>{" "}
            <li>
              <h4>Amendment of the Terms</h4>
              We reserve the right to amend these Terms from time to time in our
              sole discretion. If you have registered as a member, we shall
              notify you of any material changes to these Terms (and the
              effective date of such changes) by sending an email to the address
              you have provided to Wandart for your account. For all other
              users, we will post the revised terms on the Site. If you continue
              to use the Service after the effective date of the revised Terms,
              you will be deemed to have accepted those changes. If you do not
              agree to the revised Terms, your sole remedy shall be to
              discontinue using the Service.
            </li>{" "}
            <li>
              {" "}
              <h4>General</h4>
              These Terms constitute the entire agreement between Wandart and
              you with respect to your use of the Service. Wandart's failure to
              enforce any right or provision in these Terms shall not constitute
              a waiver of such right or provision. If a court should find that
              one or more provisions contained in these Terms is invalid, you
              agree that the remainder of the Terms shall be enforceable.
              Wandart shall have the right to assign its rights and/or delegate
              its obligations under these Terms, in whole or in part, to any
              person or business entity. You may not assign your rights or
              delegate your obligations under these Terms without the prior
              written consent of Wandart.
            </li>
            <li>
              {" "}
              <h4>Comments</h4>
              If you have any comments or questions about the Service please
              contact us by email at{" "}
              <a href="mailto:support@wandart.art">support@wandart.art</a>
            </li>{" "}
          </ol>
          <h3>Section II: Additional Terms</h3>
          <ol>
            <li>
              {" "}
              <h4> Registration</h4>
              To register as a member of the Service or purchase products, you
              must be 16 years or lawfully permitted to enter into and form
              contracts under applicable law. In no event may minors submit
              Content to the Service. You agree that the information that you
              provide to us upon registration, at the time of purchase, and at
              all other times will be true, accurate, current and complete. You
              also agree that you will ensure that this information is kept
              accurate and up to date at all times. This is especially important
              with respect to your email address, since that is the primary way
              in which we will communicate with you about your account and your
              orders.
            </li>{" "}
            <li>
              {" "}
              <h4>Password </h4>
              When you register as a member you will be asked to provide a
              password. You are responsible for safeguarding the password and
              you agree not to disclose your password to any third party. You
              agree that you shall be solely responsible for any activities or
              actions under your password, whether or not you have authorized
              such activities or actions. You shall immediately notify Wandart
              of any unauthorized use of your password.
            </li>{" "}
            <li>
              {" "}
              <h4>Copyright in Your Content</h4>
              Wandart does not claim ownership rights in Your Content. For the
              sole purpose of enabling us to make your Content available through
              the Service, you grant to Wandart a non-exclusive, royalty-free
              license to reproduce, distribute, re-format, store, prepare
              derivative works based on, and publicly display and perform Your
              Content. Please note that when you upload Content, third parties
              will be able to copy, distribute and display your Content using
              readily available tools on their computers for this purpose
              although other than by linking to your Content on Wandart any use
              by a third party of your Content could violate paragraph 4 of
              these Terms and Conditions unless the third party receives
              permission from you by license.
            </li>{" "}
            <li>
              <h4> Monitoring Content</h4> Wandart has no ability to control the
              Content you may upload, post or otherwise transmit using the
              Service and does not have any obligation to monitor such Content
              for any purpose. You acknowledge that you are solely responsible
              for all Content and material you upload, post or otherwise
              transmit using the Service.
            </li>{" "}
            <li>
              {" "}
              <h4>Conduct</h4> You agree to be subject to and to conduct
              yourself in accordance with the Wandart Etiquette Policy.You are
              responsible for all of Your Content you upload, download, and
              otherwise copy, distribute and display using the Service. You must
              have the legal right to copy, distribute and display all parts of
              any content that you upload, download and otherwise copy,
              distribute and display. Content provided to you by others, or made
              available through websites, magazines, books and other sources,
              are protected by copyright and should not be uploaded, downloaded,
              or otherwise copied, distributed or displayed without the consent
              of the copyright owner or as otherwise permitted by law. Please
              refer to Wandart's Copyright Policy for further details. You agree
              not to use the Service: for any unlawful purposes; to upload,
              post, or otherwise transmit any material that is obscene,
              offensive, blasphemous, pornographic, unlawful, threatening,
              menacing, abusive, harmful, an invasion of privacy or publicity
              rights, defamatory, libelous, vulgar, illegal or otherwise
              objectionable; to upload, post, or otherwise transmit any material
              that infringes any copyright, trade mark, patent or other
              intellectual property right or any moral right or artist's right
              of any third party including, but not limited to, Wandart or to
              facilitate the unlawful distribution of copyrighted content or
              illegal content; to harm minors in any way, including, but not
              limited to, uploading, posting, or otherwise transmitting content
              that violates child pornography laws, child sexual exploitation
              laws or laws prohibiting the depiction of minors engaged in sexual
              conduct, or submitting any personally identifiable information
              about any child under the age of 13; to forge headers or otherwise
              manipulate identifiers in order to disguise the origin of any
              Content transmitted through the Service; to upload, post, or
              otherwise transmit any material which is likely to cause harm to
              Wandart or anyone else's computer systems, including but not
              limited to that which contains any virus, code, worm, data or
              other files or programs designed to damage or allow unauthorized
              access to the Service which may cause any defect, error,
              malfunction or corruption to the Service.
            </li>
            <li>
              <h4>Suspension and Termination of Access and Membership</h4> You
              agree that Wandart may at any time, and without notice, suspend or
              terminate any part of the Service, or refuse to fulfill any order,
              or any part of any order or terminate your membership and delete
              any Content stored on the Wandart Site, in Wandart's sole
              discretion, if you fail to comply with the Terms or applicable
              law.
            </li>
          </ol>
        </div>
      </div>
      <ScrollTop />
      <Footer />
    </StyledAbout>
  );
};

const StyledAbout = styled(motion.div)`
  .about {
    background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 2rem 2rem;

    @media screen and (max-width: 900px) {
      padding: 0rem;
    }
    .section {
      border-radius: 5px;
      padding: 2rem 2rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      @media screen and (max-width: 900px) {
        border-radius: 0px;
        padding: 1rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      }
    }
    h2 {
      text-align: center;
      padding: 0.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 2rem;
      @media screen and (max-width: 900px) {
        padding: 0.5rem;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }

    h3 {
      padding: 0.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 1.4rem;
      font-weight: 500;
      padding-top: 1.5rem;
      @media screen and (max-width: 900px) {
        padding: 0.5rem;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
    ol {
      padding: 0rem 1rem;

      li {
        padding: 0.2rem 0rem;

        a {
          cursor: pointer;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
      h4 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1.2rem;
        font-weight: 500;
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          font-weight: 500;
        }
      }
    }
  }
`;
export default Terms;
