import axios from "axios";
import {
  USER_NOTIFICATION_LIST_REQUEST,
  USER_NOTIFICATION_LIST_FAIL,
  USER_NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_RESET,
} from "../constants/notificationConstants";
import { domain } from "./api";

export const listUserNotifications = (id, limit) => async (dispatch) => {
  try {
    dispatch({ type: USER_NOTIFICATION_LIST_REQUEST });
    const { data } = await axios.get(
      `${domain}/api/v1/notifications/user/${id}/?limit=${limit}&sort=-createdAt`
    );
    dispatch({ type: USER_NOTIFICATION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_NOTIFICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const deleteNotification = (id) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_DELETE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.delete(`${domain}/api/v1/notifications/${id}`, config);
    dispatch({ type: NOTIFICATION_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
