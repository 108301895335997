import React from "react";
//Redux
import { useSelector } from "react-redux";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";

import { Instagram, Facebook, Whatsapp, Twitter } from "@material-ui/icons";

const Contact = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledContact
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Contact" />
      <div className="about">
        <div className="section">
          <h2>Contact us</h2>
          <div>
            The best way to contact us is by sending us an email{" "}
            <a href="mailto:support@wandart.art">support@wandart.art</a>. You
            can also contact us on our social media platforms below
          </div>
          <div className="social">
            <div className="top">
              {" "}
              <a
                href="https://facebook.com/Wandart-105185731854263/?_rdc=1&_rdr"
                target="_blank"
              >
                {" "}
                <Facebook className="icon" />
              </a>
              <a href="http://www.twitter.com/wandartofficial" target="_blank">
                {" "}
                <Twitter className="icon" />
              </a>
              <a
                href="http://www.instagram.com/wandartofficial"
                target="_blank"
              >
                <Instagram className="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
      <Footer />
    </StyledContact>
  );
};
const StyledContact = styled(motion.div)`
  .about {
    background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 2rem 2rem;

    @media screen and (max-width: 900px) {
      padding: 0rem;
    }
    .section {
      border-radius: 5px;
      padding: 2rem 2rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      h2 {
        text-align: center;
        padding: 0.5rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 2rem;
        @media screen and (max-width: 900px) {
          padding: 0.5rem;
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
      p {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 1rem;
        padding: 0.5rem 0rem;
      }
      a {
        cursor: pointer;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
      @media screen and (max-width: 900px) {
        border-radius: 0px;
        padding: 1rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      }

      .social {
        display: flex;
        width: 100%;
        flex-flow: column wrap;
        align-items: center;
        .top {
          padding: 1rem 0rem;
          width: 10rem;
          display: flex;
          justify-content: space-around;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          a {
            cursor: pointer;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            &:hover {
              color: #50c1e9;
            }
          }
        }
      }
    }
  }
`;
export default Contact;

// .section {
//     .social {
//       display: flex;
//       justify-content: center;
//       padding: 0.6rem;
//       a {
//         padding: 0rem 1.3rem;
//         @media screen and (max-width: 900px) {
//         }
//         padding: 0rem 0.8rem;
//       }
//       .fac {
//         color: #7373c5;
//         font-size: 2rem;
//         @media screen and (max-width: 900px) {
//           font-size: 1.5rem;
//         }
//       }
//       .twi {
//         color: lightblue;
//         font-size: 2rem;
//         @media screen and (max-width: 900px) {
//           font-size: 1.5rem;
//         }
//       }
//       .wha {
//         color: green;
//         font-size: 2rem;
//         @media screen and (max-width: 900px) {
//           font-size: 1.5rem;
//         }
//       }
//       .ins {
//         color: #d44c4c;
//         font-size: 2rem;
//         @media screen and (max-width: 900px) {
//           font-size: 1.5rem;
//         }
//       }
