import React from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { About } from '../styles';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';

const FaqSection = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <Faq isDarkMode={isDarkMode}>
      <AnimateSharedLayout>
        <Toggle title="What is automatic renewal?">
          <div className="answer">
            <p>
              Premium subscription is designed to provide an ongoing experience,
              and each package is set to automatically renew if you use card
              payment. As such, your subscription gets extended every month or
              every year, depending on the billing cycle you have chosen. The
              payment amount remains the same throughout the cycle.
            </p>
          </div>
        </Toggle>

        <Toggle title="What are the accepted payment methods">
          <div className="answer">
            <p>
              We want to make our service available to everyone so we accept
              crypto payment or debit/credit card payment
            </p>
          </div>
        </Toggle>
        <Toggle
          title="What’s the refund policy for Premium subscription
"
        >
          <div className="answer">
            <p>
              Premium subscription is non-refundable. If you tried and decided
              it is not for you, please cancel automatic renewal to avoid future
              charges.
            </p>
          </div>
        </Toggle>
        <Toggle title="How do I cancel my subscription">
          <div className="answer">
            <p>
              An email will be sent to you once you subscribe if you need to cancel in the future
            </p>
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};

const Faq = styled.div`
  display: flex;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  flex-flow: column wrap;

  padding: 3rem 6rem;
  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }
  h2 {
    padding-bottom: 2rem;
    color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
  }
  .faq-line {
    background: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
    height: 3px;
    margin: 1rem 0rem;
    width: 100%;
    @media (max-width: 900px) {
      margin: 0.5rem 0rem;
      height: 2px;
    }
  }
  .question {
    width: 100%;
    padding: 1.8rem 0rem;
    cursor: pointer;
    h4 {
      font-size: 1.7rem;
      @media (max-width: 900px) {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
  .answer {
    padding: 0rem 0rem;
    color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
    p {
      padding: 0.5rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'grey')};
      font-size: 1.2rem;
      @media (max-width: 900px) {
        padding: 0.2rem 0rem;
        font-size: 1.25rem;
      }
    }
  }
`;

export default FaqSection;
