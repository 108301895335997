import {
    CHANGE_MATURE
    } from "../constants/matureConstants";

    export const displayMatureReducer=(state=false,action)=>{

        switch(action.type){
            case CHANGE_MATURE:
               return state=action.payload
            default: return state
        }
    }