import {
  SUBSCRIPTION_LIST_FAIL,
  SUBSCRIPTION_LIST_SUCCESS,
  SUBSCRIPTION_LIST_REQUEST,
  SUBSCRIPTION_DETAILS_REQUEST,
  SUBSCRIPTION_DETAILS_FAIL,
  SUBSCRIPTION_DETAILS_SUCCESS,
  SUBSCRIPTION_DISABLE_FAIL,
  SUBSCRIPTION_DISABLE_SUCCESS,
  SUBSCRIPTION_DISABLE_REQUEST,
  SUBSCRIPTION_DISABLE_RESET,
  SUBSCRIPTION_ENABLE_FAIL,
  SUBSCRIPTION_ENABLE_SUCCESS,
  SUBSCRIPTION_ENABLE_REQUEST,
  SUBSCRIPTION_ENABLE_RESET,
} from '../constants/subscriptionConstants';

export const subscriptionListReducer = (
  state = { subscriptions: [] },
  action
) => {
  switch (action.type) {
    case SUBSCRIPTION_LIST_REQUEST:
      return { loading: true, ...state };
    case SUBSCRIPTION_LIST_SUCCESS:
      return {
        loading: false,
        subscriptions: action.payload.data.doc,
        pages: action.payload.data.pages,
        page: action.payload.data.page,
      };
    case SUBSCRIPTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscriptionDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case SUBSCRIPTION_DETAILS_SUCCESS:
      return { loading: false, subscription: action.payload.data };
    case SUBSCRIPTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscriptionDisableReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_DISABLE_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_DISABLE_SUCCESS:
      return { loading: false, success: true };
    case SUBSCRIPTION_DISABLE_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_DISABLE_RESET:
      return {};
    default:
      return state;
  }
};
export const subscriptionEnableReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_ENABLE_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_ENABLE_SUCCESS:
      return { loading: false, success: true };
    case SUBSCRIPTION_ENABLE_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_ENABLE_RESET:
      return {};
    default:
      return state;
  }
};
