import React from "react";

//Redux
import { useSelector } from "react-redux";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollTop";
const About = () => {
  const isDarkMode = useSelector((state) => state.isDark);
  return (
    <StyledAbout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="About" />
      <div className="about">
        <div className="section">
          <h2>About us</h2>
          <div>
            {" "}
            We are a wallpaper and art community. We want an environment where
            art lovers and artists meet, We believe that art is for everyone.
            Wandart Founded in August 2021 is the fastest growing online
            wallpaper and art community for art lovers and artists. We hope to
            have over one hundred thousand users to complete phase one of the
            project. Phase two will allow premium users sell their arts and
            competitions will be organized for best artists. Phase three will be
            implemented when we have over one million users, there will be a
            category for NFT's. Users that have stayed with us for a long time
            will be given more benefits and have more chance to appear on our
            social media channels.
          </div>
        </div>
      </div>
      <ScrollTop />
      <Footer />
    </StyledAbout>
  );
};

const StyledAbout = styled(motion.div)`
  .about {
    background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 2rem 2rem;

    @media screen and (max-width: 900px) {
      padding: 0rem;
    }
    .section {
      border-radius: 5px;
      padding: 2rem 2rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      @media screen and (max-width: 900px) {
        border-radius: 0px;
        padding: 1rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      }
    }
    h2 {
      text-align: center;
      padding: 0.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 2rem;
      @media screen and (max-width: 900px) {
        padding: 0.5rem;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
`;
export default About;
