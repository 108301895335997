import {
  USER_NOTIFICATION_LIST_REQUEST,
  USER_NOTIFICATION_LIST_FAIL,
  USER_NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_RESET,
} from '../constants/notificationConstants';

export const userNotificationListReducer = (
  state = { notifications: [] },
  action
) => {
  switch (action.type) {
    case USER_NOTIFICATION_LIST_REQUEST:
      return { loading: true, ...state };
    case USER_NOTIFICATION_LIST_SUCCESS:
      return { loading: false, notifications: action.payload.data };
    case USER_NOTIFICATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const notificationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case NOTIFICATION_DELETE_REQUEST:
      return { loading: true };
    case NOTIFICATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case NOTIFICATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case NOTIFICATION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
