import axios from "axios";
import {
  SUBSCRIPTION_LIST_FAIL,
  SUBSCRIPTION_LIST_SUCCESS,
  SUBSCRIPTION_LIST_REQUEST,
  SUBSCRIPTION_DETAILS_REQUEST,
  SUBSCRIPTION_DETAILS_FAIL,
  SUBSCRIPTION_DETAILS_SUCCESS,
  SUBSCRIPTION_DISABLE_FAIL,
  SUBSCRIPTION_DISABLE_SUCCESS,
  SUBSCRIPTION_DISABLE_REQUEST,
  SUBSCRIPTION_ENABLE_FAIL,
  SUBSCRIPTION_ENABLE_SUCCESS,
  SUBSCRIPTION_ENABLE_REQUEST,
} from "../constants/subscriptionConstants";
import { domain } from "./api";

export const listSubscriptions = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_LIST_REQUEST });
    const { data } = await axios.get(
      `${domain}/api/v1/subscriptions/?user=${id}&sort=-createdAt`
    );
    dispatch({ type: SUBSCRIPTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSubscriptionDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_DETAILS_REQUEST });
    const { data } = await axios.get(`${domain}/api/v1/subscriptions/${id}`);
    dispatch({ type: SUBSCRIPTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const disableSubscription = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_DISABLE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.patch(`${domain}/api/v1/subscriptions/cancel/${id}`, config);
    dispatch({ type: SUBSCRIPTION_DISABLE_SUCCESS });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_DISABLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const enableSubscription = (id) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIPTION_DISABLE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.patch(`${domain}/api/v1/subscriptions/enable/${id}`, config);
    dispatch({ type: SUBSCRIPTION_DISABLE_SUCCESS });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_DISABLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
