import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*{
    margin:0;
    padding:0;
    box-sizing:border-box;
}
body{
    background:${({isDarkMode})=>isDarkMode?"#0f0f0f":"#ffffff"};
    font-family:'Inter',sans-serif;
    overflow-x:hidden;
    color:white;
}
button{
}input{
    color:${({isDarkMode})=>isDarkMode?"white":"black"};
    &:focus{
outline:none;
border: 0.5px solid ${({isDarkMode})=>isDarkMode?"grey":"black"};
box-shadow:0px 0px 1.5px 1.5px #50C1E9
    }
}
select{
    cursor:pointer;
      &:focus {
            outline: none;
            border: 0.5px solid
              ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
            box-shadow: 0px 0px 1.5px 1.5px #50c1e9;
          }
}
h2{
        font-weight:lighter;
        font-size:4rem;

    }
    h3{

        color:white;
    }
    h4{

        font-weight:bold;
      font-size:2rem;
    }
    span{

    }
    a{
      
        text-decoration:none;
        color:white;
        &:hover{
            color:#50C1E9
        }
    }
    p{
        padding:3rem 0rem;
        color:#ccc;
        font-size:1.4rem;
        line-height:150%;
    }
`;
export default GlobalStyle;
