import React from 'react';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { loader } from '../animations';
import favicon from '../images/favicon.png';
const Loader = () => {
  return (
    <StyledContainer>
      <StyledLoader variants={loader} animate="animateOne">
        <img src={favicon} alt="" />
      </StyledLoader>
    </StyledContainer>
  );
};
const StyledContainer = styled(motion.div)`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
`;
const StyledLoader = styled(motion.div)`
  img {
    width: 2rem;
  }
`;

export default Loader;
