export const COMMENT_LIST_REQUEST = "COMMENT_LIST_REQUEST";
export const COMMENT_LIST_FAIL = "COMMENT_LIST_FAIL";
export const COMMENT_LIST_SUCCESS = "COMMENT_LIST_SUCCESS";

export const COMMENT_DELETE_REQUEST = "COMMENT_DELETE_REQUEST";
export const COMMENT_DELETE_FAIL = "COMMENT_DELETE_FAIL";
export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS";
export const COMMENT_DELETE_RESET = "COMMENT_DELETE_RESET";

export const COMMENT_CREATE_REQUEST = "COMMENT_CREATE_REQUEST";
export const COMMENT_CREATE_FAIL = "COMMENT_CREATE_FAIL";
export const COMMENT_CREATE_SUCCESS = "COMMENT_CREATE_SUCCESS";
export const COMMENT_CREATE_RESET = "COMMENT_CREATE_RESET";

export const COMMENT_UPDATE_REQUEST = "COMMENT_UPDATE_REQUEST";
export const COMMENT_UPDATE_FAIL = "COMMENT_UPDATE_FAIL";
export const COMMENT_UPDATE_SUCCESS = "COMMENT_UPDATE_SUCCESS";
export const COMMENT_UPDATE_RESET = "COMMENT_UPDATE_RESET";

export const LIKE_COMMENT_REQUEST = "LIKE_COMMENT_REQUEST";
export const LIKE_COMMENT_FAIL = "LIKE_COMMENT_FAIL";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_RESET = "LIKE_COMMENT_RESET";

export const UNLIKE_COMMENT_REQUEST = "UNLIKE_COMMENT_REQUEST";
export const UNLIKE_COMMENT_FAIL = "UNLIKE_COMMENT_FAIL";
export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
export const UNLIKE_COMMENT_RESET = "UNLIKE_COMMENT_RESET";