import axios from "axios";
import {
  POST_LIST_FAIL,
  POST_LIST_SUCCESS,
  POST_LIST_REQUEST,
  POST_DETAILS_FAIL,
  POST_DETAILS_REQUEST,
  POST_DETAILS_SUCCESS,
  LIKE_POST_FAIL,
  LIKE_POST_SUCCESS,
  LIKE_POST_REQUEST,
  UNLIKE_POST_FAIL,
  UNLIKE_POST_SUCCESS,
  UNLIKE_POST_REQUEST,
  USER_POST_LIST_FAIL,
  USER_POST_LIST_SUCCESS,
  USER_POST_LIST_REQUEST,
  SIMILAR_POST_LIST_FAIL,
  SIMILAR_POST_LIST_SUCCESS,
  SIMILAR_POST_LIST_REQUEST,
  SEARCHED_POST_LIST_FAIL,
  SEARCHED_POST_LIST_SUCCESS,
  SEARCHED_POST_LIST_REQUEST,
  POST_DELETE_FAIL,
  POST_DELETE_SUCCESS,
  POST_DELETE_REQUEST,
  POST_CREATE_FAIL,
  POST_CREATE_SUCCESS,
  POST_CREATE_REQUEST,
  POST_UPDATE_FAIL,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_REQUEST,
  REPORT_POST_FAIL,
  REPORT_POST_SUCCESS,
  REPORT_POST_REQUEST,
} from "../constants/postConstants";
import { domain } from "./api";

export const listPosts =
  ({ isMature, categorySlug, approved, id, keyword }) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_LIST_REQUEST });
      const { data } = await axios.get(
        `${domain}/api/v1/posts/?${approved ? `approved=${approved}&` : ""}${
          categorySlug ? `categorySlug=${categorySlug}&` : ""
        }${isMature ? "" : "isMature=false&"}${id ? `_id=${id}&` : ""}${
          keyword ? `keyword=${keyword}` : ""
        }`
      );
      dispatch({ type: POST_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPostDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: POST_DETAILS_REQUEST });
    const { data } = await axios.get(`${domain}/api/v1/posts/user/${id}`);
    dispatch({ type: POST_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const likePost =
  ({ id, idString }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LIKE_POST_REQUEST });
      const { data } = await axios.patch(`${domain}/api/v1/posts/${id}/like`, {
        idString,
      });
      dispatch({ type: LIKE_POST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: LIKE_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const unLikePost = (id) => async (dispatch) => {
  try {
    dispatch({ type: UNLIKE_POST_REQUEST });
    const { data } = await axios.patch(`${domain}/api/v1/posts/${id}/unlike`);
    dispatch({ type: UNLIKE_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNLIKE_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUserPosts =
  ({ userName, limit, isMature }) =>
  async (dispatch) => {
    try {
      if (isMature) {
        dispatch({ type: USER_POST_LIST_REQUEST });
        const { data } = await axios.get(
          `${domain}/api/v1/posts/${userName}/?approved=true&limit=${limit}`
        );
        dispatch({ type: USER_POST_LIST_SUCCESS, payload: data });
      } else {
        const { data } = await axios.get(
          `${domain}/api/v1/posts/${userName}/?approved=true&limit=${limit}&isMature=false`
        );
        dispatch({ type: USER_POST_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: USER_POST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSimilarPosts =
  ({ id, limit, isMature }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SIMILAR_POST_LIST_REQUEST });
      const { data } = await axios.get(
        `${domain}/api/v1/posts/similar/${id}/?approved=true&limit=${limit}${
          isMature ? " " : "&isMature=false"
        }`
      );
      dispatch({ type: SIMILAR_POST_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SIMILAR_POST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSearchedPosts =
  ({ keyword, isMature }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCHED_POST_LIST_REQUEST });
      const { data } = await axios.get(
        `${domain}/api/v1/posts/?approved=true&keyword=${keyword}${
          isMature ? " " : "&isMature=false"
        }`
      );
      dispatch({ type: SEARCHED_POST_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SEARCHED_POST_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deletePost = (id) => async (dispatch) => {
  try {
    dispatch({ type: POST_DELETE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.delete(`${domain}/api/v1/posts/${id}`, config);
    dispatch({ type: POST_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: POST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPost = (post) => async (dispatch) => {
  try {
    dispatch({ type: POST_CREATE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();
    formData.append("name", post.name);
    formData.append("description", post.description);
    formData.append("isMature", post.isMature);
    formData.append("category", post.category);
    formData.append("image", post.image);
    const { data } = await axios.post(
      `${domain}/api/v1/posts/admin`,
      formData,
      config
    );
    dispatch({ type: POST_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const submitPost = (post) => async (dispatch) => {
  try {
    dispatch({ type: POST_CREATE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();
    formData.append("name", post.name);
    formData.append("description", post.description);
    formData.append("isMature", post.isMature);
    formData.append("category", post.category);
    formData.append("image", post.image);
    const { data } = await axios.post(
      `${domain}/api/v1/posts`,
      formData,
      config
    );
    dispatch({ type: POST_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePost = (post, id) => async (dispatch) => {
  try {
    dispatch({ type: POST_UPDATE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    let formData = new FormData();

    if (post.name) formData.append("name", post.name);
    if (post.description) formData.append("description", post.description);
    formData.append("isMature", post.isMature);
    if (post.category) formData.append("category", post.category);
    if (!(Object.entries(post.image).length === 0))
      formData.append("image", post.image);
    const { data } = await axios.patch(
      `${domain}/api/v1/posts/${id}`,
      formData,
      config
    );
    dispatch({ type: POST_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approvePost =
  ({ user, idString }, id) =>
  async (dispatch) => {
    try {
      dispatch({ type: POST_UPDATE_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      await axios.patch(
        `${domain}/api/v1/posts/${id}/approve`,
        { user, idString },
        config
      );
      dispatch({ type: POST_UPDATE_SUCCESS });
    } catch (error) {
      dispatch({
        type: POST_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const reportPost =
  ({ id, title, body }) =>
  async (dispatch) => {
    try {
      dispatch({ type: REPORT_POST_REQUEST });
      const { data } = await axios.patch(`${domain}/api/v1/posts/report`, {
        title,
        url: window.location.href,
        body,
      });
      dispatch({ type: REPORT_POST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REPORT_POST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
