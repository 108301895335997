import React from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

function ErrorMessage({ error, visible }) {
  const isDarkMode = useSelector((state) => state.isDark);
  if (!visible || !error) return null;
  return (
    <StyledErrorMessage isDarkMode={isDarkMode}>{error}</StyledErrorMessage>
  );
}
const StyledErrorMessage = styled.div`
  padding-top: 0.4rem;
  font-size: 0.85rem;
  color: ${({ isDarkMode }) => (isDarkMode ? "red" : "red")};
`;
export default ErrorMessage;
