import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

function GoogleAd(props) {
    return (
        <StyledGoogleAd>
            <ins class="adsbygoogle"
     style={{display:"block"}}
     data-ad-format="fluid"
     data-ad-layout-key="-fb+5w+4e-db+86"
     data-ad-client="ca-pub-4386348319016306"
     data-ad-slot="1918916595"></ins>
        </StyledGoogleAd>
    );
}

const StyledGoogleAd=styled(motion.div)`
width:100%;
min-height:0rem;
`
export default GoogleAd;