import { combineReducers } from "redux";
import { isDarkReducer } from "./themeReducers";
import {
  postListReducer,
  postDetailsReducer,
  postDeleteReducer,
  postCreateReducer,
  postUpdateReducer,
  likePostReducer,
  unlikePostReducer,
  userPostListReducer,
  similarPostListReducer,
  searchedPostListReducer,
  reportPostReducer,
} from "./postReducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  userConfirmEmailReducer,
  userResendConfirmEmailReducer,
  followUserReducer,
  unFollowUserReducer,
} from "./userReducers";

import {
  categoryListReducer,
  categoryDetailsReducer,
  categoryPostsListReducer,
  categoryDeleteReducer,
  categoryUpdateReducer,
  categoryCreateReducer,
} from "./categoryReducers";
import { displayMatureReducer } from "./matureReducers";

import {
  commentListReducer,
  likeCommentReducer,
  unlikeCommentReducer,
  commentDeleteReducer,
  commentCreateReducer,
  commentUpdateReducer,
} from "./commentReducers";

import {
  likeReplyReducer,
  unlikeReplyReducer,
  replyDeleteReducer,
  replyCreateReducer,
  replyUpdateReducer,
} from "./replyReducers";

import {
  subscriptionListReducer,
  subscriptionDetailsReducer,
  subscriptionDisableReducer,
  subscriptionEnableReducer,
} from "./subscriptionReducers";

import {
  userNotificationListReducer,
  notificationDeleteReducer,
} from "./notificationReducers";
const rootReducer = combineReducers({
  isDark: isDarkReducer,
  displayMature: displayMatureReducer,

  postList: postListReducer,
  postDetails: postDetailsReducer,
  likePost: likePostReducer,
  reportPost: reportPostReducer,
  unlikePost: unlikePostReducer,
  userPostList: userPostListReducer,
  similarPostList: similarPostListReducer,
  searchedPostList: searchedPostListReducer,

  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryPostsList: categoryPostsListReducer,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryCreate: categoryCreateReducer,

  postDelete: postDeleteReducer,
  postCreate: postCreateReducer,
  postUpdate: postUpdateReducer,

  userNotificationList: userNotificationListReducer,
  notificationDelete: notificationDeleteReducer,

  userLogin: userLoginReducer,

  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  followUser: followUserReducer,
  unFollowUser: unFollowUserReducer,

  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  userConfirmEmail: userConfirmEmailReducer,
  userResendConfirmEmail: userResendConfirmEmailReducer,

  commentList: commentListReducer,
  likeComment: likeCommentReducer,
  unlikeComment: unlikeCommentReducer,
  commentDelete: commentDeleteReducer,
  commentCreate: commentCreateReducer,
  commentUpdate: commentUpdateReducer,

  likeReply: likeReplyReducer,
  unlikeReply: unlikeReplyReducer,
  replyDelete: replyDeleteReducer,
  replyCreate: replyCreateReducer,
  replyUpdate: replyUpdateReducer,

  subscriptionList: subscriptionListReducer,
  subscriptionDetails: subscriptionDetailsReducer,
  subscriptionDisable: subscriptionDisableReducer,
  subscriptionEnable: subscriptionEnableReducer,
});
export default rootReducer;
