import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//components
import Category from '../components/Category';
import Loader from '../components/Loader';
import Message from '../components/Message';
import ScrollTop from '../components/ScrollTop';
import { listCategories } from '../actions/categoryActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { pageAnimation } from '../animations';
import Meta from '../components/Meta';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import GoogleAd from '../components/GoogleAd';

const Categories = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(listCategories({ isMature }));
  }, [dispatch, history, isMature]);
  const categoryList = useSelector((state) => state.categoryList);
  const { loading, categories, error, page, pages } = categoryList;
  return (
    <StyledCategoryList
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Categories" />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <StyledCategories isDarkMode={isDarkMode}>
          {categories.map((category) => (
            <Category
              key={category._id}
              id={category._id}
              category={category}
            />
          ))}
        </StyledCategories>
      )}
      <Footer />
      <ScrollToTop />
      <ScrollTop />
    </StyledCategoryList>
  );
};

const StyledCategoryList = styled(motion.div)``;
const StyledCategories = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  min-height: 40vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 0.4rem;
  grid-row-gap: 0.4rem;
  padding: 0.4rem;
  @media (max-width: 900px) {
    padding: 0.35rem 0.35rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: 0.35rem;
    grid-row-gap: 0.35rem;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`;
export default Categories;
