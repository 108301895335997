import axios from "axios";
import {
  COMMENT_LIST_REQUEST,
  COMMENT_LIST_SUCCESS,
  COMMENT_LIST_FAIL,
  COMMENT_DELETE_FAIL,
  COMMENT_DELETE_SUCCESS,
  COMMENT_DELETE_REQUEST,
  COMMENT_CREATE_FAIL,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_REQUEST,
  COMMENT_UPDATE_FAIL,
  COMMENT_UPDATE_SUCCESS,
  COMMENT_UPDATE_REQUEST,
  UNLIKE_COMMENT_REQUEST,
  UNLIKE_COMMENT_FAIL,
  UNLIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_REQUEST,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAIL,
} from "../constants/commentConstants";
import { domain } from "./api";

export const listComments = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_LIST_REQUEST });
    const { data } = await axios.get(
      `${domain}/api/v1/comments/?post=${id}&sort=-createdAt`
    );
    dispatch({ type: COMMENT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: COMMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteComment = (id) => async (dispatch) => {
  try {
    dispatch({ type: COMMENT_DELETE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.delete(`${domain}/api/v1/comments/${id}`, config);
    dispatch({ type: COMMENT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: COMMENT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createComment =
  ({ comment, id, idString }) =>
  async (dispatch) => {
    try {
      dispatch({ type: COMMENT_CREATE_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.post(
        `${domain}/api/v1/comments/`,
        { post: id, comment, idString },
        config
      );
      dispatch({ type: COMMENT_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateComment =
  ({ comment, id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: COMMENT_UPDATE_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.patch(
        `${domain}/api/v1/comments/${id}`,
        { comment },
        config
      );
      dispatch({ type: COMMENT_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMMENT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const likeComment =
  ({ id, idString }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LIKE_COMMENT_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.patch(
        `${domain}/api/v1/comments/${id}/like`,
        { idString },
        config
      );
      dispatch({ type: LIKE_COMMENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: LIKE_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const unLikeComment = (id) => async (dispatch) => {
  try {
    dispatch({ type: UNLIKE_COMMENT_REQUEST });
    const { data } = await axios.patch(
      `${domain}/api/v1/comments/${id}/unlike`
    );
    dispatch({ type: UNLIKE_COMMENT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNLIKE_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
