import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
//actions
import { reportPost } from '../actions/postActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateUserProfile } from '../actions/userActions';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

function AccountEditModal({ on, setOn, isDarkMode, title, user }) {
  const dispatch = useDispatch();

  const [location, setLocation] = useState(
    user.country || 'United States of America'
  );
  const [bio, setBio] = useState(user.bio || '');
  const [reportPage, setReportPage] = useState('/');
  const exitModalHandler = (e) => {
    const element = e.target;
    if (element.classList.contains('shadow')) {
      setOn(false);
    }
  };
  const countryList = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas (the)',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory (the)',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands (the)',
    'Central African Republic (the)',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands (the)',
    'Colombia',
    'Comoros (the)',
    'Congo (the Democratic Republic of the)',
    'Congo (the)',
    'Cook Islands (the)',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    "Côte d'Ivoire",
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic (the)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (the) [Malvinas]',
    'Faroe Islands (the)',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories (the)',
    'Gabon',
    'Gambia (the)',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (the)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    "Korea (the Democratic People's Republic of)",
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    "Lao People's Democratic Republic (the)",
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands (the)',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (the Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands (the)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of North Macedonia',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Réunion',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan (the)',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands (the)',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates (the)',
    'United Kingdom of Great Britain and Northern Ireland (the)',
    'United States Minor Outlying Islands (the)',
    'United States of America (the)',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela (Bolivarian Republic of)',
    'Viet Nam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
    'Åland Islands',
  ];

  const accountUpdateHandler = () => {
    dispatch(updateUserProfile({ bio, country: location }));
    setOn(false);
  };
  return (
    <StyledModal onClick={exitModalHandler} isDarkMode={isDarkMode}>
      {on && (
        <div className="modal shadow">
          <div className="middle">
            <div className="title">
              {title}
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setOn(false)}
              />
            </div>
            <ModalChildren isDarkMode={isDarkMode}>
              <div className="group">
                <div className="form-group">
                  <label>Location</label>
                  <select
                    onChange={(e) => setLocation(e.target.value)}
                    selected={location}
                  >
                    <option value={location} required>
                      {location}
                    </option>
                    {countryList.map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </select>
                </div>
                <div class="form-group">
                  <label for="name">Bio</label>
                  <textarea
                    placeholder="Enter Bio"
                    rows="4"
                    onChange={(e) => setBio(e.target.value)}
                    name="postDetails"
                    form="usrform"
                    value={bio}
                  ></textarea>
                </div>
                <span className="button">
                  <button onClick={accountUpdateHandler}>Save</button>
                </span>
              </div>
            </ModalChildren>
          </div>
        </div>
      )}
    </StyledModal>
  );
}
export default AccountEditModal;

const ModalChildren = styled.div`
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  .group {
    padding: 1rem 2rem;
    @media (max-width: 900px) {
      padding: 0.5rem 1rem;
    }
    .form-group {
      display: flex;
      flex-flow: column wrap;
      padding: 0.6rem 0rem;

      label {
        padding: 0.2rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
      }
      input,
      select {
        @media (max-width: 900px) {
          width: 100%;
        }
        background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
        }
      }
      textarea {
        background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
        }
        &:focus {
          outline: none;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          box-shadow: 0px 0px 1.5px 1.5px #50c1e9;
        }
      }
    }
    .button {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 0.35rem 1.3rem;
        margin-top: 0.6rem;
        background-color: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        cursor: pointer;
        border: 2px solid #50c1e9;
        border-radius: 4px;
        &:hover {
          background-color: #75b1c7;
        }
        &:disabled {
          cursor: not-allowed;
          background-color: #75b1c7;
        }
      }
    }
  }
`;

const StyledModal = styled(motion.div)`
  .modal {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    img {
      height: 100%;
      object-fit: cover;
    }
    .middle {
      @media screen and (max-width: 900px) {
        padding: 0.6rem 0rem;
        width: 90%;
        min-height: 20rem;
      }
      width: 35rem;
      min-height: 16rem;
      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      display: flex;
      flex-flow: column wrap;
      padding: 1.5rem 0rem;
      z-index: 20;
      .title {
        @media screen and (max-width: 900px) {
          padding: 1rem 0rem;
        }
        height: 15%;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        padding: 0rem 2rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          padding: 0rem 1rem;
          align-items: center;
        }
        .icon {
          cursor: pointer;
          margin-left: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }
    }
  }
`;
