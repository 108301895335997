import React from 'react';

//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart } from '@fortawesome/free-regular-svg-icons';

function Modal({ children, on, setOn, isDarkMode, title }) {
  const exitModalHandler = (e) => {
    const element = e.target;
    if (element.classList.contains('shadow')) {
      setOn(false);
    }
  };
  return (
    <StyledModal onClick={exitModalHandler} isDarkMode={isDarkMode}>
      {on && (
        <div className="modal shadow">
          <div className="middle">
            <div className="title">
              {title}
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setOn(false)}
              />
            </div>
            {children}
          </div>
        </div>
      )}
    </StyledModal>
  );
}

const StyledModal = styled(motion.div)`
  .modal {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    img {
      height: 100%;
      object-fit: cover;
    }
    .middle {
      @media screen and (max-width: 900px) {
        padding: 0.6rem 0rem;
        width: 80%;
      }
      width: 25rem;
      min-height: 10rem;
      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      display: flex;
      flex-flow: column wrap;
      padding: 1.5rem 0rem;
      z-index: 20;
      .title {
        height: 15%;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        padding: 0rem 2rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          padding: 0rem 1rem;
          align-items: center;
        }
        .icon {
          cursor: pointer;
          margin-left: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }
    }
  }
`;
export default Modal;
