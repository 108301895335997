import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "../components/Loader";
import Message from "../components/Message";
import SettingsNav from "../components/SettingsNav";
import ScrollTop from "../components/ScrollTop";
import {
  getUserDetails,
  updateUserName,
  updateUserProfile,
} from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Footer from "../components/Footer";

function IdentitySettings(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const [userName, setUserName] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user, error } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success,
    error: updateError,
    loading: updateLoading,
  } = userUpdateProfile;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user.userName) {
        dispatch(getUserDetails("me"));
      } else if (success) {
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_PROFILE_RESET });
          dispatch(getUserDetails("me"));
        }, 2000);
      } else if (updateError) {
        setTimeout(() => {
          dispatch({ type: USER_UPDATE_PROFILE_RESET });
        }, 2000);
        //   } else if (resendSuccess) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
        //   } else if (resendError) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
      } else {
      }
    }
  }, [dispatch, history, userInfo, success, updateError]);

  const profileUpdateHandler = () => {
    dispatch(updateUserName({ userName }));
  };

  return (
    <StyledIdentitySettings isDarkMode={isDarkMode}>
      <Meta title="Identity settings" />
      <div className="settings">
        <SettingsNav pathname={pathname} isDarkMode={isDarkMode} />
        {updateLoading && <Loader />}
        {success && (
          <Message variant="success">Username changed successfully</Message>
        )}
        {updateError && <Message variant="danger">{updateError}</Message>}
        <div className="identity">
          <StyledCard isDarkMode={isDarkMode}>
            <h3>Change Username</h3>

            <div className="talk">
              <h5>You can change Username however</h5>
              <ul>
                <li>
                  You must be a <Link to="/premium">Premium</Link> Member of
                  WandArt.
                </li>
                <li>You may only change your username once every 60 days.</li>
              </ul>
              {user.plan === "premium" &&
                new Date(user.userNameChangedAt || 2001).getTime() +
                  60 * 24 * 60 * 60 * 1000 >
                  Date.now() && (
                  <h5>
                    You can change username in{" "}
                    {(
                      (new Date(user.userNameChangedAt).getTime() +
                        60 * 24 * 60 * 60 * 1000 -
                        Date.now()) /
                      1000 /
                      60 /
                      60 /
                      24
                    ).toFixed(0)}
                    days
                  </h5>
                )}
            </div>
            <div className="input">
              <div className="center">
                <span>Enter New Username</span>
                <input
                  type="text"
                  disabled={
                    (user.role == "user" && user.plan !== "premium") ||
                    new Date(user.userNameChangedAt || 2001).getTime() +
                      60 * 24 * 60 * 60 * 1000 >
                      Date.now()
                  }
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
            </div>
            <span className="button">
              <button
                onClick={profileUpdateHandler}
                disabled={
                  (user.role == "user" && user.plan !== "premium") ||
                  new Date(user.userNameChangedAt || 2001).getTime() +
                    60 * 24 * 60 * 60 * 1000 >
                    Date.now()
                }
              >
                Continue
              </button>
            </span>
          </StyledCard>
        </div>
      </div>
      <ScrollTop />
      <Footer />
    </StyledIdentitySettings>
  );
}

const StyledIdentitySettings = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};

  .settings {
    display: flex;
    padding-bottom: 5rem;
    width: 100%;
    @media (max-width: 900px) {
      flex-flow: column wrap;
      padding-bottom: 0rem;
      background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
    }
  }
  .identity {
    flex: 80%;
    display: flex;
    justify-content: flex-start;
    padding: 2rem 1rem;
    @media (max-width: 900px) {
      width: 100%;
      padding: 0.5rem 0.5em;
      flex: 100%;
      padding-bottom: 10rem;
    }
  }
`;

const StyledCard = styled(motion.div)`
  display: flex;
  flex-flow: column wrap;
  width: 40rem;
  min-height: 10rem;
  padding: 1.2rem;
  border-radius: 8px;
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  h3 {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    padding: 0.2rem 0rem;
  }
  a {
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    &:hover {
      color: #50c1e9;
    }
  }
  .button {
    display: flex;
    justify-content: flex-end;
    button {
      padding: 0.35rem 0.8rem;
      background-color: #50c1e9;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      cursor: pointer;
      border: 2px solid #50c1e9;
      border-radius: 4px;
      &:hover {
        background-color: #75b1c7;
      }
      &:disabled {
        cursor: not-allowed;
        background-color: #75b1c7;
      }
    }
  }
  .input {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0rem;

    .center {
      display: flex;
      flex-flow: column wrap;
      width: 70%;
      @media (max-width: 900px) {
        width: 100%;
      }
      span {
        font-size: 0.9rem;
        padding: 0.4rem 0rem;
      }
      input {
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.5rem;
        font-size: 0.9rem;
        border: 0.5px solid;
        width: 100%;
        ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
        &:disabled {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#1b1b1b" : "#f8f8f8"};
          cursor: not-allowed;
        }
      }
    }
  }
  .talk {
    padding: 0.2rem 0rem;
    ul {
      padding: 0rem 1.2rem;
      li {
        padding: 0.1rem 0rem;
      }
    }
    h5 {
      font-size: 1rem;
      padding: 0.4rem 0rem;
      font-weight: 500;
    }
  }
`;
export default IdentitySettings;
