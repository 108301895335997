import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import whiteLogo from "../images/logoWhite.png";
import darkLogo from "../images/logoDark.png";

import { Instagram, Facebook, Whatsapp, Twitter } from "@material-ui/icons";
const Footer = () => {
  const isDarkMode = useSelector((state) => state.isDark);

  const { pathname } = useLocation();

  return pathname === "/" ? (
    <div></div>
  ) : (
    <StyledFooter isDarkMode={isDarkMode}>
      <div className="footer">
        <div className="col">
          <Link to="/">
            {" "}
            <img src={isDarkMode ? whiteLogo : darkLogo} />
          </Link>
        </div>
        <div className="col">
          <span>
            <Link to="/about">About</Link>
          </span>
          <span>
            <Link to="/contact">Contact</Link>
          </span>
          <span>
            <Link to="/terms">Terms of service</Link>
          </span>
        </div>
        <div className="col">
          <span>
            <Link to="/policy">Privacy & Copyright Policy</Link>
          </span>
          <span>
            <a href="mailto:support@wandart.art">Support</a>
          </span>
        </div>
      </div>

      <div className="bottom">
        <div className="top">
          {" "}
          <a
            href="https://facebook.com/Wandart-105185731854263/?_rdc=1&_rdr"
            target="_blank"
          >
            {" "}
            <Facebook className="icon" />
          </a>
          <a href="http://www.twitter.com/wandartofficial" target="_blank">
            {" "}
            <Twitter className="icon" />
          </a>
          <a href="http://www.instagram.com/wandartofficial" target="_blank">
            <Instagram className="icon" />
          </a>
        </div>
        <div className="down">
          {new Date().getFullYear()} Wandart | All Rights Reserved
        </div>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled(motion.div)`
  padding: 2rem;
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
  margin-top: 1rem;
  width: 100%;
  bottom: 0;
  @media screen and (max-width: 900px) {
    border-top: 0.15rem solid
      ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
    font-size: 01rem;
    margin-top: 0rem;
  }
  p {
    font-size: 1rem;
    padding: 0.6rem;
    color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
    @media screen and (max-width: 900px) {
      font-size: 1rem;
      padding: 0.3rem;
    }
  }

  .footer {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-around;
    @media screen and (max-width: 900px) {
      flex-flow: column wrap;
      align-items: center;
    }
    .col {
      padding: 1rem 0rem;
      @media screen and (max-width: 900px) {
        padding: 0rem 0rem;
      }
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      width: 10rem;
      img {
        width: 7rem;
      }
      span {
        a {
          cursor: pointer;
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
          &:hover {
            color: #50c1e9;
          }
        }
        padding: 0.4rem 0rem;
        font-size: 1rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
        text-align: center;
      }
    }
  }
  .bottom {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-items: center;

    .top {
      padding: 1rem 0rem;
      width: 10rem;
      display: flex;
      justify-content: space-around;
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
      a {
        cursor: pointer;
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
        &:hover {
          color: #50c1e9;
        }
      }
    }
    .down {
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
    }
  }
`;
export default Footer;
