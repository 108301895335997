import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
//Redux
import { useDispatch, useSelector } from 'react-redux';
//components
import LikeLoader from './LikeLoader';
//actions
import { likePost, unLikePost } from '../actions/postActions';

//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { popUp } from '../animations';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faCommentAlt,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart } from '@fortawesome/free-regular-svg-icons';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
const Post = ({ post }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isDarkMode = useSelector((state) => state.isDark);
  const userLogin = useSelector((state) => state.userLogin);
  const likerPost = useSelector((state) => state.likePost);
  const {
    loading: likeLoading,
    success: likeSuccess,
    error: likeError,
  } = likerPost;
  const unlikePost = useSelector((state) => state.unlikePost);
  const {
    loading: unlikeLoading,
    success: unlikeSuccess,
    error: unlikeError,
  } = unlikePost;
  const { userInfo } = userLogin;
  const handleLike = () => {
    if (userInfo) {
      post.likes.filter((like) => like.id == userInfo._id).length > 0
        ? dispatch(unLikePost(post.id))
        : dispatch(
            likePost({
              id: post.id,
              idString: `/${post.user.userName}/art/${post.slug}/${post.id}`,
            })
          );
    } else {
      history.push('/login');
    }
  };
  return (
    <StyledPost
      variants={popUp}
      animate="show"
      initial="hidden"
      isDarkMode={isDarkMode}
    >
      <Link
        to={{
          pathname: `/${post.user.userName}/art/${post.slug}/${post.id}`,
          state: {
            path: `${location.pathname}${location.search}`,
            name: `${location.pathname == '/' ? ' Home' : ' Back'}`,
          },
        }}
      >
        <img src={post.image.url&& post.image.url.replace("upload","upload/c_scale,f_auto,q_auto,w_800")} alt="image" />
      </Link>
      <div className="title">
        <span className="row">
          <span className="name">
            {post.name.length > 25
              ? post.name.substring(0, 25) + '...'
              : post.name}
          </span>
          <span className="comI">
            <Link
              to={{
                pathname: `/${post.user.userName}/art/${post.slug}/${post.id}#comments`,
                state: {
                  path: `${location.pathname}${location.search}`,
                  name: `${location.pathname == '/' ? ' Home' : ' Back'}`,
                },
              }}
            >
              {' '}
              {post.comments ? post.comments.length : 0}
              <ChatBubbleOutlineIcon className="icon" />
            </Link>
          </span>
        </span>
        <span
          className="row"
          onClick={(e) => {
            if (e.target.classList.contains('row')) {
              history.push({
                pathname: `/${post.user.userName}/art/${post.slug}/${post.id}`,
                state: {
                  path: `${location.pathname}${location.search}`,
                  name: `${location.pathname == '/' ? ' Home' : ' Back'}`,
                },
              });
            }
          }}
        >
          <span className="imgU">
            <Link to={`/${post.user.userName}`}>
              {' '}
              <img src={post.user.photo.url} alt="image" />
            </Link>{' '}
            <Link to={`/${post.user.userName}`}>
              {' '}
              <h4>{post.user.userName}</h4>
            </Link>
          </span>
          <span className="comI" onClick={handleLike}>
            {post.likes.length}
            {userInfo ? (
              post.likes.filter((like) => like.id == userInfo._id).length >
              0 ? (
                <FavoriteIcon className="icon ic" />
              ) : (
                <FavoriteBorderIcon className="icon" />
              )
            ) : (
              <FavoriteBorderIcon className="icon" />
            )}
          </span>
        </span>
      </div>
    </StyledPost>
  );
};
const StyledPost = styled(motion.div)`
  background: black;
  overflow: hidden;
  color: white;
  position: relative;

  img {
    width: 100%;
    display: block;
    transition: 0.3s all ease-in-out;
    @media (max-width: 900px) {
      height: 40vh;
      object-fit: cover;
    }
  }
  &:hover {
    img {
      opacity: 0.76;
    }
    .title {
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      padding: 0.2rem 0.6rem;

      img {
        opacity: 1;
        width: 1.5rem;
        border-radius: 1px;
        @media (max-width: 900px) {
          height: auto;
          object-fit: cover;
          width: 1.8rem;
        }
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.4rem;
        cursor: pointer;
        .icon {
          margin-left: 0.5rem;
          cursor: pointer;
        }
        .ic {
          color: #50c1e9;
        }
        .name {
          font-size: 1rem;
          font-weight: 300;
        }
        .comI {
          display: flex;
          align-items: center;
          a {
            display: flex;
            align-items: center;
          }
          &:hover {
            color: #50c1e9;
          }
        }
        .imgU {
          display: flex;
          align-items: center;
          h4 {
            padding-left: 0.5rem;
            font-size: 1rem;
            font-weight: 300;
          }
        }
      }
    }
  }
  .title {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.2rem 0rem;
    transition: 0.3s all ease-in-out;
  }
  @media screen and (max-width: 900px) {
  }
`;
export default Post;
