import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components
import Loader from "../components/Loader";
import Message from "../components/Message";
import SettingsNav from "../components/SettingsNav";
import ScrollTop from "../components/ScrollTop";
import {
  getUserDetails,
  updateUserName,
  updateUserProfile,
} from "../actions/userActions";
import {
  listSubscriptions,
  disableSubscription,
  enableSubscription,
} from "../actions/subscriptionActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSpinner,
  faEllipsisH,
  faEdit,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as emptyHeart } from "@fortawesome/free-regular-svg-icons";
import { SUBSCRIPTION_DISABLE_RESET } from "../constants/subscriptionConstants";
import Footer from "../components/Footer";

function BillingAndPayments(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const isDarkMode = useSelector((state) => state.isDark);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const subscriptionDisable = useSelector((state) => state.subscriptionDisable);
  const { error, loading: disableLoading, success } = subscriptionDisable;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user.name) {
        dispatch(getUserDetails("me"));
        dispatch(listSubscriptions(userInfo._id));
      } else if (success) {
        setTimeout(() => {
          dispatch({ type: SUBSCRIPTION_DISABLE_RESET });
          dispatch(listSubscriptions(userInfo._id));
          dispatch(getUserDetails("me"));
        }, 2000);
      } else if (error) {
        setTimeout(() => {
          dispatch({ type: SUBSCRIPTION_DISABLE_RESET });
        }, 2000);
        //   } else if (resendSuccess) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
        //   } else if (resendError) {
        //     setTimeout(() => {
        //       dispatch({ type: USER_RESEND_CONFIRM_EMAIL_RESET });
        //     }, 2000);
      } else {
      }
    }
  }, [dispatch, history, userInfo, success, error]);

  const subscriptionList = useSelector((state) => state.subscriptionList);
  const { loading: subscriptionLoading, subscriptions } = subscriptionList;

  const disableHandler = (id) => {
    if (
      window.confirm("Are you sure you want to disable this subscription? 😥😥")
    ) {
      dispatch(disableSubscription(id));
    }
  };
  const enableHandler = (id) => {
    dispatch(enableSubscription(id));
  };
  return (
    <StyledBillingAndPayments
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      {" "}
      <Meta title="Billing and Payments" />
      {disableLoading && <Loader />}
      {error && <Message variant="danger">{error}</Message>}
      {success && <Message variant="success">Success</Message>}
      <div className="heading">
        <h2>Billing & Payments</h2>
        <h3>Find information about your subscriptions and payments</h3>
      </div>
      {userInfo && subscriptions.length == 0 && (
        <div className="middle">
          <div className="center">
            <h3>You have no subscription</h3>
            <button onClick={() => history.push("/premium")}>
              Subscribe to Premium <FontAwesomeIcon icon={faStar} />
            </button>
          </div>
        </div>
      )}
      {userInfo && subscriptions.length > 0 && (
        <div className="cards">
          {subscriptions.map((subscription) => (
            <StyledCard isDarkMode={isDarkMode}>
              <div class="card">
                <span className="icon">
                  <Link>
                    <h3>{subscription.plan.split(" ")[0]}</h3>
                    <h2> {subscription.plan.split(" ")[1]}</h2>
                  </Link>
                </span>
                <div class="premium-group">
                  <span>Price: ${subscription.price}</span>
                </div>
                <div className="text">
                  {subscription.paymentMethod == "card" &&
                    new Date().getTime() <
                      new Date(subscription.nextPaymentDate).getTime() &&
                    subscription.cancelled &&
                    "Subsription is active but disabled"}

                  {subscription.paymentMethod == "card" &&
                    new Date().getTime() <
                      new Date(subscription.nextPaymentDate).getTime() &&
                    !subscription.cancelled &&
                    "Subsription is active"}

                  {new Date().getTime() >
                    new Date(subscription.nextPaymentDate).getTime() &&
                    "Subsription has expired"}
                  {subscription.paymentMethod == "crypto" &&
                    new Date().getTime() <
                      new Date(subscription.nextPaymentDate).getTime() &&
                    "Subsription is active"}
                </div>
                {subscription.paymentMethod == "card" &&
                  !subscription.cancelled && (
                    <button className="disable-btn">Subscription active</button>
                  )}
                {subscription.paymentMethod == "card" &&
                  subscription.cancelled && (
                    <button>Subscription disabled</button>
                  )}
                {subscription.paymentMethod == "crypto" && (
                  <button disabled>Crypto payments do not reoccur</button>
                )}
              </div>
            </StyledCard>
          ))}
        </div>
      )}
      <ScrollTop />
      <Footer />
    </StyledBillingAndPayments>
  );
}

const StyledBillingAndPayments = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  .cards {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    @media (max-width: 900px) {
      flex-flow: column wrap;
    }
  }
  .heading {
    padding: 1.5rem;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    @media screen and (max-width: 900px) {
      padding: 0.5rem 0.8rem;
      width: 100%;
    }
    h2 {
      font-size: 2.5rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      @media screen and (max-width: 900px) {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
      }
    }
    h3 {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      padding: 1rem 0rem;
      @media screen and (max-width: 900px) {
        font-size: 1.1rem;
        text-align: center;
      }
    }
  }
  .middle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;

    @media screen and (max-width: 900px) {
      padding: 0.5rem;
    }
    .center {
      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      padding: 6rem 8rem;
      border-radius: 4px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 900px) {
        width: 100%;
        padding: 4rem 0rem;
      }
      h3 {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        padding: 1rem 0rem;
        font-size: 1.5rem;
        @media screen and (max-width: 900px) {
          font-size: 1.1rem;
          text-align: center;
        }
      }

      button {
        width: 14rem;
        padding: 0.4rem;
        border: 2px solid #50c1e9;
        border-radius: 4px;
        background: transparent;
        color: #50c1e9;
        font-size: 1.2rem;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          font-size: 1.1rem;
          text-align: center;
          padding: 1rem;
        }
        &:hover {
          color: white;
          background: #50c1e9;
        }
      }
    }
  }
`;

const StyledCard = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  display: flex;
  justify-content: center;
  padding: 1rem 2rem;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0.25rem 0.5rem;
  }
  .card {
    display: flex;
    width: 27rem;
    @media (max-width: 900px) {
      width: 100%;
    }
    flex-flow: column wrap;
    justify-content: center;
    .icon {
      font-size: 1.5rem;
      padding: 0.2rem;
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      width: 100%;
      h2 {
        font-weight: 400;
        font-size: 1.5rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        padding-left: 0.4rem;
      }
      h3 {
        color: #50c1e9;
        font-weight: 700;
        font-size: 1.5rem;
      }
      a {
        display: flex;
        align-items: center;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      }
    }

    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    padding: 0.9rem 1.5rem;
    @media (max-width: 900px) {
      padding: 1rem 1rem;
    }
    border-radius: 4px;
    color: white;
    h3 {
      text-align: center;
      font-weight: 500;
      font-size: 1.3rem;
    }

    button {
      padding: 0.7rem;
      border: 2px solid #50c1e9;
      border-radius: 5px;
      margin: 0.6rem 0rem;
      font-size: 0.95rem;
      background: #50c1e9;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      cursor: pointer;
      &:hover {
        background: #4cb1d6;
      }
      &:disabled {
        cursor: not-allowed;
        background: #4cb1d6;
      }
    }
    .form-group {
      display: flex;
      flex-flow: column wrap;
      padding: 0.6rem 0rem;
      input {
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }
    }
    .premium-group {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 0.3rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 1.3rem;
      input {
        margin-left: auto;
        background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 2rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
        }
      }
    }
    .forReg {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem 0rem;
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        font-size: 0.9rem;
        &:hover {
          color: #50c1e9;
        }
      }
    }

    .text {
      padding: 0rem 0rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
      font-size: 0.9rem;
      text-align: center;
      font-size: 1.2rem;
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
    }
  }
`;
export default BillingAndPayments;
