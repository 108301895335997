import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
//Redux
import { useDispatch, useSelector } from "react-redux";

//components

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Loader from "../components/Loader";
import Message from "../components/Message";
import ErrorMessage from "../components/forms/ErrorMessage";
//actions
import { register } from "../actions/userActions";
import { USER_LOGOUT } from "../constants/userConstants";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pageAnimation } from "../animations";
import Meta from "../components/Meta";
import whiteLogo from "../images/logoWhite.png";
import darkLogo from "../images/logoDark.png";
import Footer from "../components/Footer";
import TextField from "@material-ui/core/TextField";
import ScrollTop from "../components/ScrollTop";
const Register = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setTimeout(() => {
        dispatch({ type: USER_LOGOUT });
      }, 2000);
    }
  }, [history, userInfo, redirect, error]);
  const submitHandler = ({
    name,
    username,
    password,
    email,
    emailConfirm,
    dateOfBirth,
    passwordConfirm,
  }) => {
    dispatch(
      register({
        name,
        userName: username,
        password,
        email,
        emailConfirm,
        dateOfBirth,
        passwordConfirm,
      })
    );
  };

  const daate = Date.now();
  const minDate = new Date(daate - 504911232000);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label("name"),
    username: Yup.string().required().label("Username"),
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special case Character"
      )
      .label("Password"),
    email: Yup.string().required().email().label("Email"),
    emailConfirm: Yup.string()

      .email()
      .required()
      .label("Confirm Email")
      .test("emails-match", "Emails must match", function (value) {
        return this.parent.email === value;
      }),
    dateOfBirth: Yup.date()
      .required()
      .label("Date of Birth")
      .max(minDate, "You must be atleast 16years to register"),
    passwordConfirm: Yup.string()
      .required()
      .min(8)
      .label("Confirm Password")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  let curr = new Date();
  curr.setDate(curr.getDate() + 3);
  let dateNow = curr.toISOString().substr(0, 10);
  return (
    <StyledLogin
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Register" />
      {error && <Message variant="danger">{error}</Message>}

      {loading && <Loader />}
      <div class="login">
        <Formik
          initialValues={{
            name: "",
            username: "",
            password: "",
            email: "",
            emailConfirm: "",
            dateOfBirth: new Date(),
            passwordConfirm: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => submitHandler(values)}
          class="loginForm"
        >
          {({
            handleSubmit,
            handleChange,
            setFieldTouched,
            touched,
            errors,
          }) => (
            <div class="loginForm">
              <span className="icon">
                <Link to="/">
                  {isDarkMode ? (
                    <img src={whiteLogo} />
                  ) : (
                    <img src={darkLogo} />
                  )}
                </Link>
              </span>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Name"
                  type="text"
                  onChange={handleChange("name")}
                  onBlur={() => setFieldTouched("name")}
                />
                <ErrorMessage
                  error={errors["name"]}
                  visible={touched["name"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Username"
                  type="text"
                  onChange={handleChange("username")}
                  onBlur={() => setFieldTouched("username")}
                />
                <ErrorMessage
                  error={errors["username"]}
                  visible={touched["username"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="E-mail address"
                  type="email"
                  onChange={handleChange("email")}
                  onBlur={() => setFieldTouched("email")}
                />
                <ErrorMessage
                  error={errors["email"]}
                  visible={touched["email"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Confirm E-mail address"
                  type="email"
                  onChange={handleChange("emailConfirm")}
                  onBlur={() => setFieldTouched("emailConfirm")}
                />
                <ErrorMessage
                  error={errors["emailConfirm"]}
                  visible={touched["emailConfirm"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control date"
                  placeholder="Date of Birth"
                  max={minDate}
                  type="date"
                  onChange={handleChange("dateOfBirth")}
                  onBlur={(e) => setFieldTouched("dateOfBirth")}
                  defaultValue={dateNow}
                />

                <ErrorMessage
                  error={errors["dateOfBirth"]}
                  visible={touched["dateOfBirth"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  onChange={handleChange("password")}
                  onBlur={() => setFieldTouched("password")}
                />
                <ErrorMessage
                  error={errors["password"]}
                  visible={touched["password"]}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  placeholder="Confirm password"
                  type="password"
                  onChange={handleChange("passwordConfirm")}
                  onBlur={() => setFieldTouched("passwordConfirm")}
                />
                <ErrorMessage
                  error={errors["passwordConfirm"]}
                  visible={touched["passwordConfirm"]}
                />
              </div>

              <button onClick={handleSubmit}>Register</button>
              <div className="forReg">
                Have an account?{" "}
                <Link
                  to={redirect ? `/login?redirect=${redirect}` : "register"}
                >
                  {" "}
                  Login
                </Link>
              </div>
              <div class="text">
                By continuing, you agree to Wandart's
                <Link to="/terms"> Conditions of Use</Link> and
                <Link to="/policy"> Privacy Notice.</Link>
              </div>
            </div>
          )}
        </Formik>
      </div>
      <Footer />
      <ScrollTop />
    </StyledLogin>
  );
};

const StyledLogin = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  @media screen and (max-width: 900px) {
    padding: 0rem;

    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  }
  .login {
    width: 27rem;
    @media screen and (max-width: 900px) {
      width: 100%;
      padding-bottom: 3rem;
    }
    .loginForm {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      .icon {
        font-size: 1.5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 900px) {
          display: none;
        }
        img {
          width: 7rem;
        }
        a {
          display: flex;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }

      background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
      padding: 20px 30px;
      border-radius: 4px;
      color: white;
      @media screen and (max-width: 900px) {
        padding: 1rem 1.2rem;
        border-top: 0.15rem solid
          ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      }
      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
      }
      button {
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        margin: 0.6rem 0rem;
        font-size: 0.9rem;
        background: #50c1e9;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
        &:hover {
          background: #4cb1d6;
        }
      }
      .form-group {
        display: flex;
        flex-flow: column wrap;
        padding: 0.6rem 0rem;
        input {
          background: ${({ isDarkMode }) =>
            isDarkMode ? "#0f0f0f" : "#ffffff"};
          border-radius: 5px;
          padding: 0.7rem;
          font-size: 0.9rem;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? "grey" : "#cecece")};
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
          }
        }

        .date {
          width: 100%;
          &::placeholder {
            padding-left: 0.3rem;
            font-size: 0.9rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "#d4d4d4" : "#868686")};
          }
          ::-webkit-calendar-picker-indicator {
            filter: ${({ isDarkMode }) =>
              isDarkMode ? "invert(1)" : "invert(0)"};
            cursor: pointer;
          }
        }
      }
      .forReg {
        display: flex;
        justify-content: center;
        padding: 0.4rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        font-size: 0.9rem;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          font-size: 0.9rem;
          padding: 0rem 0.2rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }

      .text {
        padding: 1rem 0rem;
        color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
        font-size: 0.9rem;
        text-align: center;
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "grey")};
          text-decoration: underline;
          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;
export default Register;
