import React from 'react';
import Masonry from 'react-masonry-css';
//Redux
import { useDispatch, useSelector } from 'react-redux';

//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import Post from './Post';
import ScrollToTop from './ScrollToTop';

function AccountPosts({ posts, user, userInfo }) {
  const isDarkMode = useSelector((state) => state.isDark);

  return (
    <StyledAccountPosts isDarkMode={isDarkMode}>
      {posts && posts.length > 0 ? (
        <Masonry
          breakpointCols={{ default: 4, 900: 1 }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {posts.map((post) => (
            <Post key={post._id} id={post._id} post={post} />
          ))}
        </Masonry>
      ) : (
        <div className="noPost">
          {userInfo.userName === user.userName
            ? 'No Post, Try Uploading a post'
            : `${user.userName} has no posts`}
        </div>
      )}
      <ScrollToTop />
    </StyledAccountPosts>
  );
}

const StyledAccountPosts = styled(motion.div)`
  padding: 0.5rem 0.5rem;
  min-height: 50vh;
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  @media (max-width: 900px) {
    padding: 0rem 0rem;
    min-height: 55vh;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 0.4rem; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 0.4rem;
  }

  .noPost {
    padding: 6rem;
    text-align: center;
  }

  @media (max-width: 900px) {
    .my-masonry-grid_column > div {
      margin-bottom: 0.25rem; /* space between items */
      height: 40vh;
    }
  }
`;
export default AccountPosts;
