import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

import LikeLoader from "./LikeLoader";
import { time } from "./Time";
//actions
import {
  likeComment,
  unLikeComment,
  deleteComment,
  updateComment,
} from "../actions/commentActions";
import { createReply } from "../actions/replyActions";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Reply from "./Reply";
import SendIcon from "@material-ui/icons/Send";
import { Divider } from "@material-ui/core";

const Comment = ({ comment, user, replies, createdAt, likes, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const [replyOn, setReplyOn] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [reply, setReply] = useState("");
  const [com, setCom] = useState(comment);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const isDarkMode = useSelector((state) => state.isDark);
  const _likeComment = useSelector((state) => state.likeComment);
  const {
    loading: likeLoading,
    success: likeSuccess,
    error: likeError,
  } = _likeComment;
  const _unlikeComment = useSelector((state) => state.unlikeComment);
  const {
    loading: unlikeLoading,
    success: unlikeSuccess,
    error: unlikeError,
  } = _unlikeComment;

  const mins = +(Date.now() - new Date(createdAt)) / 1000 / 60;
  let real = 0;
  let realString = "";
  if (mins < 60) {
    real = mins;

    if (real == 1) {
      realString = "minute";
    } else {
      realString = "minutes";
    }
  } else if (mins < 1440) {
    real = mins / 60;
    if (real == 1) {
      realString = "hour";
    } else {
      realString = "hours";
    }
  } else if (mins < 43200) {
    real = mins / 1440;
    if (real == 1) {
      realString = "day";
    } else {
      realString = "days";
    }
  } else if (mins < 525600) {
    real = mins / 43200;
    if (real == 1) {
      realString = "month";
    } else {
      realString = "months";
    }
  } else {
  }

  const handleLike = () => {
    if (userInfo) {
      likes.filter((like) => like.id == userInfo._id).length > 0
        ? dispatch(unLikeComment(id))
        : dispatch(likeComment({ id, idString: `${pathname}#comment-${id}` }));
    } else {
      history.push("/login");
    }
  };
  const handleDelete = () => {
    dispatch(deleteComment(id));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createReply({ reply, id, idString: `${pathname}#comment-${id}` }));
    setReply("");
  };
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    dispatch(updateComment({ comment: com, id }));
    setEditComment(false);
  };
  return (
    <StyledComment isDarkMode={isDarkMode}>
      <div className="comment" id={`comment-${id}`}>
        <div className="left">
          <Link to={`/${user.userName}`}>
            <img src={user.photo.url} alt="image" />{" "}
          </Link>
        </div>
        <div className="right">
          <div className="up">
            <span>
              <Link to={`/${user.userName}`}>{user.userName} </Link>{" "}
              <h5>
                {real.toFixed(0)}
                {realString} ago
              </h5>{" "}
            </span>
            <span className="comment"> {comment}</span>
          </div>
          <div className="down">
            <span className="comI" onClick={() => setReplyOn(!replyOn)}>
              <ChatBubbleIcon className="icon" /> <h5>Reply</h5>
            </span>
            <span className="comI" onClick={handleLike}>
              {userInfo ? (
                likes.filter((like) => like.id == userInfo._id).length > 0 ? (
                  <FavoriteIcon className="icon ic" />
                ) : (
                  <FavoriteBorderIcon className="icon" />
                )
              ) : (
                <FavoriteBorderIcon className="icon" />
              )}
              <h5> {likes.length}</h5>
            </span>

            {/* {userInfo &&
              (userInfo.id !== user.id ? (
                <span className="comI">
                  <h5>Report</h5>
                </span>
              ) : (
                ""
              ))} */}
            {userInfo &&
              (userInfo.id == user.id ? (
                <span className="comI" onClick={handleDelete}>
                  <DeleteIcon className="icon" />
                </span>
              ) : (
                ""
              ))}
            {userInfo &&
              (userInfo.id == user.id ? (
                <span
                  className="comI"
                  onClick={() => setEditComment(!editComment)}
                >
                  <EditIcon className="icon" />
                </span>
              ) : (
                ""
              ))}
          </div>
        </div>
      </div>
      {editComment && (
        <div className="editComment">
          <div className="left">
            <Link to={`/${userInfo ? userInfo.userName : "login"}`}>
              <img
                src={
                  userInfo
                    ? userInfo.photo.url
                    : "https://res.cloudinary.com/darmvd8nd/image/upload/v1619768396/bv4zf4nc9z0nklf98pxn.jpg"
                }
                alt="image"
              />{" "}
            </Link>
          </div>
          <div className="right">
            {userInfo ? (
              <div className="logged">
                <div className="inputGroup">
                  <input
                    type="text"
                    placeholder="Edit comment"
                    value={com}
                    onChange={(e) => setCom(e.target.value)}
                  />
                  <button onClick={handleSubmitEdit}>
                    <SendIcon />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}

      {replyOn && (
        <div className="reply">
          <div className="left">
            <Link to={`/${userInfo ? userInfo.userName : "login"}`}>
              <img
                src={
                  userInfo
                    ? userInfo.photo.url
                    : "https://res.cloudinary.com/darmvd8nd/image/upload/v1619768396/bv4zf4nc9z0nklf98pxn.jpg"
                }
                alt="image"
              />{" "}
            </Link>
          </div>
          <div className="right">
            {userInfo ? (
              <div className="logged">
                <div className="inputGroup">
                  <input
                    type="text"
                    placeholder="Write Reply"
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                  />
                  <button onClick={handleSubmit}>
                    <SendIcon />
                  </button>
                </div>
              </div>
            ) : (
              <div className="notLogged">
                <span>
                  <Link to={`/register?redirect=${pathname}`}>Register</Link> or{" "}
                  <Link to={`/login?redirect=${pathname}`}>Login</Link> to reply
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      <StyledReplies isDarkMode={isDarkMode}>
        {replies.map((reply) => (
          <Reply
            reply={reply}
            id={reply.id}
            likes={reply.likes}
            user={reply.user}
          />
        ))}
      </StyledReplies>
    </StyledComment>
  );
};
const StyledComment = styled(motion.div)`
  padding: 1rem 0rem;
  display: flex;
  flex-flow: column wrap;
  @media (max-width: 900px) {
    padding: 0.5rem 0rem;
  }
  .editComment {
    padding: 1rem 0rem;
    display: flex;
    img {
      width: 2rem;
      border-radius: 2px;
      @media (max-width: 900px) {
        width: 1.7rem;
      }
    }
    .left {
      padding-right: 0.5rem;
    }
    .right {
      display: flex;
      width: 100%;
      background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      border-radius: 2px;
      .logged {
        width: 100%;
        .inputGroup {
          width: 100%;
          display: flex;
          input {
            width: 83%;
            background: ${({ isDarkMode }) =>
              isDarkMode ? "#171818" : "#f8f8f8"};
            outline: none;
            border: none;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 1rem;
            @media (max-width: 900px) {
              padding: 0.5rem;
              width: 80%;
            }
            &::placeholder {
              color: ${({ isDarkMode }) =>
                isDarkMode ? "#e2dede" : "#444343"};
            }
          }
          button {
            padding: 1rem;
            background: #50c1e9;
            outline: none;
            border: none;
            border-radius: 0px 2px 2px 0px;
            width: 17%;
            cursor: pointer;
            border: 2px solid #50c1e9;

            cursor: pointer;
            &:hover {
              background: #4cb1d6;
            }
            @media (max-width: 900px) {
              padding: 0.5rem;
              font-size: 0.9rem;
              width: 20%;
            }
          }
        }
      }
    }
  }
  .comment {
    display: flex;
    width: 100%;
    .left {
      img {
        width: 2rem;
        border-radius: 2px;
        @media (max-width: 900px) {
          width: 1.7rem;
        }
      }
      padding-right: 0.5rem;
    }
    .right {
      display: flex;
      flex-flow: column wrap;
      width: 100%;

      .up {
        background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
        border-radius: 2px;
        display: flex;
        flex-flow: column wrap;
        padding: 0.5rem;
        span {
          display: flex;
          align-items: center;
        }
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "#e2dede" : "#444343")};
          &:hover {
            color: #50c1e9;
          }
        }
        h5 {
          font-size: 0.85rem;
          font-weight: 400;
          padding-left: 1.2rem;
        }
        .comment {
          font-size: 1rem;
          padding: 0.4rem 0rem;
          font-weight: 400;
        }
      }
      .down {
        display: flex;
        align-items: center;
        padding: 0.4rem 0rem;
        .comI {
          padding: 0rem 0.7rem;
          h5 {
            padding-left: 0.3rem;
            font-weight: 100;
          }
        }
      }
    }
  }
  .reply {
    padding: 0.4rem 0rem;
    display: flex;
    padding-left: 3rem;
    @media (max-width: 900px) {
      padding-left: 1.7rem;
    }
    img {
      width: 2rem;
      border-radius: 2px;
      @media (max-width: 900px) {
        width: 1.7rem;
      }
    }
    .left {
      padding-right: 0.5rem;
    }
    .right {
      display: flex;
      width: 100%;
      background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
      border-radius: 2px;
      .logged {
        width: 100%;
        .inputGroup {
          width: 100%;
          display: flex;
          input {
            width: 83%;
            background: ${({ isDarkMode }) =>
              isDarkMode ? "#171818" : "#f8f8f8"};
            outline: none;
            border: none;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            padding: 1rem;
            @media (max-width: 900px) {
              padding: 0.5rem;
              width: 80%;
            }
            &::placeholder {
              color: ${({ isDarkMode }) =>
                isDarkMode ? "#e2dede" : "#444343"};
            }
          }
          button {
            padding: 1rem;
            background: #50c1e9;
            outline: none;
            border: none;
            border-radius: 0px 2px 2px 0px;
            width: 17%;
            cursor: pointer;
            border: 2px solid #50c1e9;

            cursor: pointer;
            &:hover {
              background: #4cb1d6;
            }
            @media (max-width: 900px) {
              padding: 0.5rem;
              font-size: 0.9rem;
              width: 20%;
            }
          }
        }
      }
      .notLogged {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        justify-content: center;
        align-items: center;
        @media (max-width: 900px) {
          text-align: center;
          padding: 1rem;
        }
        a {
          color: ${({ isDarkMode }) => (isDarkMode ? "#e2dede" : "#444343")};

          &:hover {
            color: #50c1e9;
          }
        }
      }
    }
  }
`;

const StyledReplies = styled(motion.div)``;
export default Comment;
