import axios from "axios";
import {
  REPLY_DELETE_FAIL,
  REPLY_DELETE_SUCCESS,
  REPLY_DELETE_REQUEST,
  REPLY_CREATE_FAIL,
  REPLY_CREATE_SUCCESS,
  REPLY_CREATE_REQUEST,
  REPLY_UPDATE_FAIL,
  REPLY_UPDATE_SUCCESS,
  REPLY_UPDATE_REQUEST,
  UNLIKE_REPLY_REQUEST,
  UNLIKE_REPLY_FAIL,
  UNLIKE_REPLY_SUCCESS,
  LIKE_REPLY_REQUEST,
  LIKE_REPLY_SUCCESS,
  LIKE_REPLY_FAIL,
} from "../constants/replyConstants";
import { domain } from "./api";

export const deleteReply = (id) => async (dispatch) => {
  try {
    dispatch({ type: REPLY_DELETE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" } };
    await axios.delete(`${domain}/api/v1/replies/${id}`, config);
    dispatch({ type: REPLY_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: REPLY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createReply =
  ({ reply, id, idString }) =>
  async (dispatch) => {
    try {
      dispatch({ type: REPLY_CREATE_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.post(
        `${domain}/api/v1/replies/`,
        { reply, commentId: id, idString },
        config
      );
      dispatch({ type: REPLY_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REPLY_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateReply =
  ({ reply, id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: REPLY_UPDATE_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.patch(
        `${domain}/api/v1/replies/${id}`,
        { reply },
        config
      );
      dispatch({ type: REPLY_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REPLY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const likeReply =
  ({ id, idString }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LIKE_REPLY_REQUEST });
      const config = { headers: { "Content-Type": "application/json" } };
      const { data } = await axios.patch(
        `${domain}/api/v1/replies/${id}/like`,
        { idString },
        config
      );
      dispatch({ type: LIKE_REPLY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: LIKE_REPLY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const unLikeReply = (id) => async (dispatch) => {
  try {
    dispatch({ type: UNLIKE_REPLY_REQUEST });
    const { data } = await axios.patch(`${domain}/api/v1/replies/${id}/unlike`);
    dispatch({ type: UNLIKE_REPLY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UNLIKE_REPLY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
