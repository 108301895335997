import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Redux
import { useDispatch } from 'react-redux';
//actions
import { reportPost } from '../actions/postActions';
//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion } from 'framer-motion';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
function ReportModal({ on, setOn, isDarkMode, title, post }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [more, setMore] = useState('');
  const [reportPage, setReportPage] = useState('/');
  const exitModalHandler = (e) => {
    const element = e.target;
    if (element.classList.contains('shadow')) {
      setOn(false);
      setValue2('');
    }
  };

  const submitReportHandler = () => {
    if (value == 'I just don’t want to see this') {
      dispatch(reportPost({ id: post.id, title: value2, body: more }));
      setOn(false);
      setValue2('');
      setValue2('');
      setReportPage('/');
    } else if (value.length > 0 && value2.length > 0) {
      dispatch(reportPost({ id: post.id, title: value2, body: more }));
      setOn(false);
      setValue2('');
      setValue2('');
      setReportPage('/');
    } else if (value == 'It’s inappropriate') {
      setReportPage('/inappropriate');
    } else if (value == 'It violates intellectual property rights') {
      setReportPage('/intellectual');
    } else if (value == 'It’s something else') {
      setReportPage('/other');
    } else {
    }
  };

  const cancelReportHandler = () => {
    if (reportPage == '/') {
      setOn(false);
      setValue2('');
      setMore('');
      setValue('');
    } else {
      setReportPage('/');
      setValue2('');
      setMore('');
      setValue('');
    }
  };

  return (
    <StyledModal onClick={exitModalHandler} isDarkMode={isDarkMode}>
      {on && (
        <div className="modal shadow">
          <div className="middle">
            <div className="title">
              {title}
              <FontAwesomeIcon
                className="icon"
                icon={faTimes}
                color="black"
                onClick={() => setOn(false)}
              />
            </div>
            <ReportModalChildren isDarkMode={isDarkMode}>
              {reportPage == '/' ? (
                <div className="radio-group">
                  <span className="input-group">
                    <input
                      type="radio"
                      value="I just don’t want to see this"
                      name="report"
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <label>I just don’t want to see this</label>
                  </span>
                  <span className="input-group">
                    <input
                      type="radio"
                      value="It’s inappropriate"
                      name="report"
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <label>It’s inappropriate</label>
                  </span>
                  <span className="input-group">
                    <input
                      type="radio"
                      value="It violates intellectual property rights"
                      name="report"
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <label>It violates intellectual property rights</label>
                  </span>
                  <span className="input-group">
                    <input
                      type="radio"
                      value="It’s something else"
                      name="report"
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <label>It’s something else</label>
                  </span>
                </div>
              ) : reportPage == '/inappropriate' ? (
                <form
                  className="radio-group"
                  onChange={(e) => setValue2(e.target.value)}
                >
                  <span className="input-group">
                    <input
                      type="radio"
                      value="This is sexually explicit"
                      name="inapp"
                    />
                    <label>
                      <h2>This is sexually explicit</h2>
                      <h3>
                        Art that goes against Wandart's{' '}
                        <Link to="/policy">Policy.</Link>
                      </h3>
                    </label>
                  </span>
                  <span className="input-group">
                    <input
                      type="radio"
                      value="This needs a Mature Tag"
                      name="inapp"
                    />
                    <label>
                      <h2>This needs a Mature Tag</h2>
                      <h3>
                        Art that should be tagged as “Mature” for including
                        nudity, sexual themes, violence/gore, strong language,
                        or is ideologically sensitive
                      </h3>
                    </label>
                  </span>
                  <span className="input-group">
                    <input
                      type="radio"
                      value="This is underage sexualized content"
                      name="inapp"
                    />
                    <label>
                      <h2>This is underage sexualized content</h2>
                      <h3>
                        Underage models or characters shown nude or in a sexual
                        context
                      </h3>
                    </label>
                  </span>
                </form>
              ) : reportPage == '/intellectual' ? (
                <div className="radio-group">
                  <form onChange={(e) => setValue2(e.target.value)}>
                    <span className="input-group">
                      <input
                        type="radio"
                        value="This is my intellectual property"
                        name="intel"
                      />
                      <label>
                        <h2>This is my intellectual property</h2>
                        <h3>I am the copyright owner</h3>
                      </label>
                    </span>
                    <span className="input-group">
                      <input
                        type="radio"
                        value="This is someone else’s intellectual property"
                        name="intel"
                      />
                      <label>
                        <h2>This is someone else’s intellectual property</h2>
                        <h3>
                          This post is using someone else’s content without
                          permission
                        </h3>
                      </label>
                    </span>
                  </form>
                  <span className="input-group">
                    <textarea
                      placeholder="Tell us more"
                      rows="4"
                      onChange={(e) => setMore(e.target.value)}
                      value={more}
                    ></textarea>
                  </span>
                </div>
              ) : reportPage == '/other' ? (
                <div className="radio-group">
                  <form onChange={(e) => setValue2(e.target.value)}>
                    <span className="input-group">
                      <input
                        type="radio"
                        value="This post is misplaced"
                        name="other"
                      />
                      <label>
                        <h2>This post is misplaced</h2>
                        <h3>A post is in the incorrect category </h3>
                      </label>
                    </span>
                    <span className="input-group">
                      <input
                        type="radio"
                        value="This is spam, malware, or warez"
                        name="other"
                      />
                      <label>
                        <h2>This is spam, malware, or warez</h2>
                        <h3>
                          A post that is advertising, a scam, a script bot,
                          malware, or warez, are not allowed on Wandart{' '}
                        </h3>
                      </label>
                    </span>
                    <span className="input-group">
                      <input type="radio" value="This is abuse" name="other" />
                      <label>
                        <h2>This is abuse </h2>
                        <h3>Hate speech, personal attacks, or death threats</h3>
                      </label>
                    </span>
                    <span className="input-group">
                      <input
                        type="radio"
                        value="This is self or animal harm"
                        name="other"
                      />
                      <label>
                        <h2>This is self or animal harm </h2>
                        <h3>
                          {' '}
                          Depictions of realistic violence, gore, self harm, or
                          animal harms
                        </h3>
                      </label>
                    </span>
                  </form>
                  <span className="input-group">
                    <textarea
                      placeholder="Is there anything else you will like us to know?"
                      rows="4"
                      onChange={(e) => setMore(e.target.value)}
                      value={more}
                    ></textarea>
                  </span>
                </div>
              ) : (
                ''
              )}
              <span className="button-group">
                <button className="cancel" onClick={cancelReportHandler}>
                  {reportPage == '/' ? 'Cancel' : 'Back'}
                </button>
                <button onClick={submitReportHandler}>
                  {value == 'I just don’t want to see this' || value2.length > 0
                    ? 'Submit'
                    : 'Next'}
                </button>
              </span>
            </ReportModalChildren>
          </div>
        </div>
      )}
    </StyledModal>
  );
}
export default ReportModal;

const ReportModalChildren = styled.div`
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  .radio-group {
    padding: 1rem;
    max-height: 16rem;
    display: flex;
    flex-flow: column;
    overflow-y: scroll;
    .input-group {
      display: flex;
      align-items: flex-start;
      padding: 0.5rem 0rem;
      input {
        cursor: pointer;
      }
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'gray')};
        text-decoration: underline;
        &:hover {
          color: #50c1e9;
        }
      }
      textarea {
        background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
        width: 100%;
        border-radius: 5px;
        padding: 0.7rem;
        font-size: 0.9rem;
        border: 0.5px solid
          ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        &::placeholder {
          padding-left: 0.3rem;
          font-size: 0.9rem;
          color: ${({ isDarkMode }) => (isDarkMode ? '#d4d4d4' : '#868686')};
        }
        &:focus {
          outline: none;
          border: 0.5px solid
            ${({ isDarkMode }) => (isDarkMode ? 'grey' : '#cecece')};
          box-shadow: 0px 0px 1.5px 1.5px #50c1e9;
        }
      }
      label {
        padding-left: 0.5rem;
        h2 {
          font-size: 1rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
          @media (max-width: 900px) {
            font-weight: 500;
          }
        }
        h3 {
          font-size: 0.8rem;
          color: ${({ isDarkMode }) => (isDarkMode ? 'whitesmoke' : 'gray')};
        }
      }
    }
  }
  .button-group {
    display: flex;
    justify-content: center;
    padding-top: 0.8rem;
    button {
      padding: 0.35em 0.8rem;
      margin: 0rem 0.5rem;
      background-color: #50c1e9;
      color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
      cursor: pointer;
      border: 2px solid #50c1e9;
      border-radius: 4px;
      &:hover {
        background-color: #4cb1d6;
      }
    }
    .cancel {
      background: ${({ isDarkMode }) => (isDarkMode ? '#171818' : '#f8f8f8')};
      color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
      border: 2px solid
        ${({ isDarkMode }) => (isDarkMode ? '#171818' : '#f8f8f8')};
      &:hover {
        background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      }
    }
  }
`;

const StyledModal = styled(motion.div)`
  .modal {
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    img {
      height: 100%;
      object-fit: cover;
    }
    .middle {
      @media screen and (max-width: 900px) {
        padding: 0.6rem 0rem;
        width: 80%;
      }
      width: 32rem;
      min-height: 14rem;
      background: ${({ isDarkMode }) => (isDarkMode ? '#0f0f0f' : '#ffffff')};
      display: flex;
      flex-flow: column wrap;
      padding: 1.5rem 0rem;
      z-index: 20;
      .title {
        height: 15%;
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        padding: 0rem 2rem;
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          padding: 0rem 1rem;
          align-items: center;
        }
        .icon {
          cursor: pointer;
          margin-left: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        }
      }
    }
  }
`;
