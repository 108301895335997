import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Masonry from "react-masonry-css";

//Redux
import { useDispatch, useSelector } from "react-redux";

//components

import Post from "../components/Post";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import GoogleAd from "../components/GoogleAd";
import ScrollTop from "../components/ScrollTop";
//actions
import { listPosts } from "../actions/postActions";
import { confirmEmail } from "../actions/userActions";
//Styling and Animationimport styled from "styled-components";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useLocation } from "react-router-dom";
import { pageAnimation } from "../animations";
import { USER_CONFIRM_EMAIL_RESET } from "../constants/userConstants";

import Meta from "../components/Meta";
import ScrollToTop from "../components/ScrollToTop";

const Home = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const { userInfo } = userLogin;
  const [verifyTokenError, setVerifyTokenError] = useState(false);

  const userConfirmEmail = useSelector((state) => state.userConfirmEmail);
  const { success: confirmSuccess, error: confirmError } = userConfirmEmail;
  const search = location.search;
  const verifyToken = search.startsWith("?verify") && search.split("=")[1];
  const searchString =
    search && search.startsWith("?category") && search.split("&");

  const keyword = searchString ? searchString[1].split("=")[1] : "";
  const likePost = useSelector((state) => state.likePost);
  const {
    loading: likeLoading,
    success: likeSuccess,
    error: likeError,
  } = likePost;
  const unlikePost = useSelector((state) => state.unlikePost);
  const {
    loading: unlikeLoading,
    success: unlikeSuccess,
    error: unlikeError,
  } = unlikePost;

  let pageNumber = search && search.startsWith("?page") && search.split("=")[1];
  if (search && search.startsWith("?keyword")) {
    let string = search.split("&")[1];
    pageNumber = string.split("=")[1];
  }
  useEffect(() => {
    dispatch(listPosts({ isMature, approved: true }));
    if (userInfo) {
      if (verifyToken) {
        dispatch(confirmEmail(verifyToken));
        setTimeout(() => {
          dispatch({ type: USER_CONFIRM_EMAIL_RESET });
          history.push("/");
        }, 2000);
      }
    } else {
      if (verifyToken) {
        setVerifyTokenError(true);
        setTimeout(() => {
          setVerifyTokenError(false);
          history.push("/");
        }, 2000);
      }
    }
  }, [dispatch, history, isMature, verifyToken, likeSuccess, unlikeSuccess]);

  const postList = useSelector((state) => state.postList);
  const { loading, posts, error, page, pages } = postList;

  return (
    <PostList
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta />

      {confirmError && <Message variant="danger">{confirmError}</Message>}
      {verifyTokenError && (
        <Message variant="danger">Please login to confirm email</Message>
      )}
      {confirmSuccess && (
        <Message variant="success">Email confirmed successfully</Message>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Masonry
            breakpointCols={{ default: 4, 900: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {posts.map((post) => (
              <Post key={post._id} id={post._id} post={post} />
            ))}
          </Masonry>
        </>
      )}
      <ScrollToTop />
      <ScrollTop />
      {navigator.cookieEnabled ? (
        ""
      ) : (
        <div className="cookieConsent">
          <span className="text">
            Please enable cookies or some of this website's functionality will
            fail! <Link to="/policy">Privacy policy</Link>
          </span>
        </div>
      )}
    </PostList>
  );
};

const PostList = styled(motion.div)`
  padding: 0.5rem 0.5rem;
  background: ${({ isDarkMode }) => (isDarkMode ? "#1b1b1b" : "#f1f1f1")};
  .cookieConsent {
    padding: 1.3rem;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 20;
    justify-content: center;
    width: 100%;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
    @media (max-width: 900px) {
      padding: 0.8rem;
      font-size: 0.9rem;
    }

    a {
      text-decoration: underline;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      &:hover {
        color: #50c1e9;
      }
    }
  }
  @media (max-width: 900px) {
    padding: 0rem 0rem;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 0.4rem; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 0.4rem;
  }
  @media (max-width: 900px) {
    .my-masonry-grid_column > div {
      margin-bottom: 0.25rem; /* space between items */
      height: 40vh;
    }
  }
`;
const Posts = styled(motion.Masonry)`
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @media (max-width: 900px) {
    min-height: 30vh;
    padding: 0rem 0.5rem;
    padding-bottom:0.5rem;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    width:100%;
  } */
`;
export default Home;
