import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Masonry from 'react-masonry-css';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//components
import Post from '../components/Post';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ScrollTop from '../components/ScrollTop';
//actions
import { listSearchedPosts } from '../actions/postActions';

//Styling and Animationimport styled from "styled-components";
import styled from 'styled-components';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { pageAnimation } from '../animations';
//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComment,
  faCommentAlt,
  faHeart,
  faTrash,
  faChevronLeft,
  faTimes,
  faDownload,
  faShare,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import Meta from '../components/Meta';
import Footer from '../components/Footer';

const Search = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const isDarkMode = useSelector((state) => state.isDark);
  const isMature = useSelector((state) => state.displayMature);
  const { userInfo } = userLogin;
  const search = location.search;

  const keyword = search.split('=')[1];
  useEffect(() => {
    dispatch(listSearchedPosts({ isMature, keyword }));
  }, [dispatch, isMature, history, keyword]);

  const searchedPostList = useSelector((state) => state.searchedPostList);
  const { loading, posts, error, page, pages } = searchedPostList;
  return (
    <StyledSearch
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
      isDarkMode={isDarkMode}
    >
      <Meta title="Search" />
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : posts.length == 0 ? (
        <div className="find">
          <span className="back">
            <Link to="/">
              <FontAwesomeIcon icon={faChevronLeft} /> Home
            </Link>
          </span>
          <h4>We couldnt find results for "{keyword}"</h4>
          <h5>Try a different search term</h5>
        </div>
      ) : (
        <StyledSearchPosts isDarkMode={isDarkMode}>
          <div className="titSearch">
            {posts.length} search results for "{keyword}"
          </div>
          <Masonry
            breakpointCols={{ default: 4, 900: 1 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {posts.map((post) => (
              <Post key={post._id} id={post._id} post={post} />
            ))}
          </Masonry>
        </StyledSearchPosts>
      )}
      <ScrollTop />
      <Footer />
    </StyledSearch>
  );
};

const StyledSearch = styled(motion.div)`
  .find {
    background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
    height: 80vh;
    width: 100%;
    @media (max-width: 900px) {
      height: 95vh;
    }
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
    h4 {
      font-size: 1.6rem;
      font-weight: 700;
      @media (max-width: 900px) {
        text-align: center;
      }
    }
    h5 {
      font-size: 1rem;
      font-weight: 550;
      @media (max-width: 900px) {
        text-align: center;
      }
    }
    .back {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      @media (max-width: 900px) {
        padding: 0.6rem;
      }
      a {
        color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
        font-size: 1.2rem;
        &:hover {
          color: #50c1e9;
        }
      }
    }
  }
`;

const StyledSearchPosts = styled(motion.div)`
  background: ${({ isDarkMode }) => (isDarkMode ? '#1b1b1b' : '#f1f1f1')};
  .titSearch {
    color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    font-size: 1.3rem;
    @media (max-width: 900px) {
      padding: 0.8rem 1rem;
    }
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    background: black;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    .my-masonry-grid_column > div {
      margin-bottom: 0.25rem; /* space between items */
      height: 40vh;
    }
  }
`;

export default Search;
