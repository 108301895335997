import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//actions

import {
  logout,
  getLoggedUserStatus,
  resendConfirmEmail,
} from "../actions/userActions";
//Redux
import { useDispatch, useSelector } from "react-redux";

import { useSwipeable } from "react-swipeable";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faBars,
  faSearch,
  faTimes,
  faMoon,
  faSun,
  faToggleOn,
  faToggleOff,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import CheckIcon from "@material-ui/icons/Check";
import { lineAnim } from "../animations";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import whiteLogo from "../images/logoWhite.png";
import darkLogo from "../images/logoDark.png";
import { changeTheme } from "../actions/themeActions";

function MobNav({
  searchToggle,
  menuToggle,
  isDarkMode,
  setMenuToggle,
  userInfo,

  exitHandler,
  setSearchToggle,
  notifications,
  handlerMatureSwitch,
  setThemeloading,
  isMature,
  notDown,
  setNotDown,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [keyword, setKeyword] = useState("");
  const [rightMenuToggle, setRightMenuToggle] = useState(false);
  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;
  const userResendConfirmEmail = useSelector(
    (state) => state.userResendConfirmEmail
  );
  const {
    loading: resendLoading,
    success: resendSuccess,
    error: resendError,
  } = userResendConfirmEmail;
  const searchHandler = (e) => {
    e.preventDefault();
    setSearchToggle(false);
    if (keyword.trim()) {
      history.push(`/search/?keyword=${keyword}`);
    } else {
      history.push("/");
    }
  };
  const exitHandler2 = (e) => {
    const element = e.target;
    if (element.classList.contains("window")) {
      setRightMenuToggle(false);
    }
  };
  const logoutHandler = () => {
    setRightMenuToggle(false);
    dispatch(logout());
  };
  const resendHandler = (e) => {
    dispatch(resendConfirmEmail());
  };
  const handlerThemeSwitch = () => {
    setMenuToggle(!menuToggle);
    setThemeloading(true);
    setTimeout(() => {
      setThemeloading(false);
      dispatch(changeTheme());
    }, 1300);
  };
  return (
    <StyledMobNav
      searchToggle={searchToggle}
      menuToggle={menuToggle}
      isDarkMode={isDarkMode}
    >
      <StyledMenu
        searchToggle={searchToggle}
        menuToggle={menuToggle}
        isDarkMode={isDarkMode}
        {...useSwipeable({ onSwipedLeft: () => setMenuToggle(!menuToggle) })}
      >
        <div className="menu">
          <div className="left">
            <span className="icon">
              <Link to="/" onClick={() => setMenuToggle(!menuToggle)}>
                {isDarkMode ? <img src={whiteLogo} /> : <img src={darkLogo} />}
              </Link>
            </span>
            {userInfo && userInfo.plan == "premium" && userInfo.role == "user" && (
              <span className="submit">
                <Link
                  className="nav-link sub"
                  to="/submit"
                  onClick={() => setMenuToggle(!menuToggle)}
                >
                  Submit Post
                </Link>
              </span>
            )}
            <span className="theme">
              <span>Theme </span>
              {isDarkMode ? (
                <button onClick={handlerThemeSwitch} className="moon">
                  <FontAwesomeIcon icon={faMoon} color="whitesmoke" />
                </button>
              ) : (
                <button onClick={handlerThemeSwitch} className="sun">
                  <FontAwesomeIcon icon={faSun} color="gray" />
                </button>
              )}
            </span>
            <span className="theme">
              <span>Display mature content </span>
              {isMature ? (
                <button onClick={handlerMatureSwitch}>
                  <FontAwesomeIcon icon={faToggleOn} className="fI" />{" "}
                  <span> On </span>
                </button>
              ) : (
                <button onClick={handlerMatureSwitch}>
                  <FontAwesomeIcon icon={faToggleOff} className="fI" />
                  <span> Off </span>
                </button>
              )}
            </span>
            <div className="line"></div>
            <Link to="/about" onClick={() => setMenuToggle(!menuToggle)}>
              {" "}
              About{" "}
            </Link>
            <Link to="/contact" onClick={() => setMenuToggle(!menuToggle)}>
              Contact
            </Link>
            <Link to="/policy" onClick={() => setMenuToggle(!menuToggle)}>
              Privacy & Copyright policy
            </Link>
          </div>

          <div className="right window" onClick={exitHandler}></div>
        </div>
      </StyledMenu>
      <StyledRightMenu
        rightMenuToggle={rightMenuToggle}
        searchToggle={searchToggle}
        menuToggle={menuToggle}
        isDarkMode={isDarkMode}
        {...useSwipeable({
          onSwipedRight: () => setRightMenuToggle(!rightMenuToggle),
        })}
      >
        <div className="menu">
          <div className="right window" onClick={exitHandler2}></div>
          <div className="left">
            <div className="icon">
              {userInfo ? (
                <>
                  <Link
                    to={`/${userInfo.userName}`}
                    onClick={() => setRightMenuToggle(!rightMenuToggle)}
                  >
                    {userInfo.userName}
                  </Link>
                  {userInfo.emailConfirmStatus == false && (
                    <div className="resend-email">
                      <span>Please confirm your email !!</span>
                      <small>
                        Didnt recieve an email?{" "}
                        {resendSuccess ? (
                          <button>
                            Sent <CheckIcon className="icon" />
                          </button>
                        ) : (
                          <button onClick={resendHandler}>
                            Resend{" "}
                            {resendLoading && (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            )}
                          </button>
                        )}
                      </small>
                    </div>
                  )}
                </>
              ) : (
                <h2>Hello!</h2>
              )}{" "}
            </div>
            {userInfo ? (
              userInfo.role == "user" && userInfo.plan !== "premium" ? (
                <div className="premiumButton">
                  {" "}
                  <button
                    onClick={() => {
                      setRightMenuToggle(!rightMenuToggle);
                      history.push("/premium");
                    }}
                  >
                    Premium <FontAwesomeIcon icon={faStar} />
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              <div className="premiumButton">
                {" "}
                <button
                  onClick={() => {
                    history.push("/premium");
                    setRightMenuToggle(!rightMenuToggle);
                  }}
                >
                  Premium <FontAwesomeIcon icon={faStar} />
                </button>
              </div>
            )}
            {userInfo ? (
              <>
                <Link
                  to="/account"
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                >
                  Billing & payment{" "}
                </Link>
                <Link
                  to="/settings/identity"
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                >
                  Account Settings{" "}
                </Link>
              </>
            ) : (
              <>
                <Link
                  className="nav-link"
                  to="/login"
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                >
                  Login
                </Link>

                <Link
                  className="nav-link"
                  to="/register"
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                >
                  Register
                </Link>
              </>
            )}
            {(userInfo && userInfo.role === "admin") ||
            (userInfo && userInfo.role === "moderator") ? (
              <>
                <h2>Admin</h2>
                <Link
                  className="nav-link"
                  to="/admin/createpost"
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                >
                  Create Post
                </Link>
                <Link
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                  className="nav-link"
                  to="/admin/userlist"
                >
                  Users
                </Link>
                <Link
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                  className="nav-link"
                  to="/admin/postlist"
                >
                  Posts
                </Link>
                <Link
                  onClick={() => setRightMenuToggle(!rightMenuToggle)}
                  className="nav-link"
                  to="/admin/categorylist"
                >
                  Categories
                </Link>
              </>
            ) : (
              ""
            )}

            {userInfo && (
              <>
                <div className="line"></div>
                <Link onClick={logoutHandler}>Logout</Link>
              </>
            )}
          </div>
        </div>
      </StyledRightMenu>

      <div className="left">
        <FontAwesomeIcon
          icon={faBars}
          id="toggle"
          className="fIcon"
          onClick={() => setMenuToggle(!menuToggle)}
        />{" "}
        <span className="icon">
          <Link to="/">
            {isDarkMode ? <img src={whiteLogo} /> : <img src={darkLogo} />}
          </Link>
        </span>
      </div>

      <div className="right">
        <span className="nav-link">
          <FontAwesomeIcon
            icon={faSearch}
            id="cancelSearch"
            className="hide"
            onClick={() => setSearchToggle(!searchToggle)}
          />
        </span>
        <div
          className="form"
          {...useSwipeable({
            onSwipedUp: () => setSearchToggle(!searchToggle),
          })}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="hide cancelSearch"
            color="white"
            onClick={() => setSearchToggle(!searchToggle)}
          />
          <form onSubmit={searchHandler}>
            <input
              type="search"
              placeholder="Search HD wallpapers and art"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <motion.div variants={lineAnim} className="line hide"></motion.div>
            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <div class="suggestions ">
            <small class="sugg-text">Suggestions</small>
            {categories &&
              categories.map(
                (item, index) =>
                  index < 5 && (
                    <Link to={`/category/${item.slug}`}>{item.name}</Link>
                  ) // <= only 5 items
              )}
          </div>
        </div>
        {userInfo && userInfo.photo ? (
          <div className="prof">
            {userInfo && userInfo.photo && (
              <div className="dropdown not">
                <span className="nav-link more">
                  <NotificationsIcon onClick={() => setNotDown(!notDown)} />
                </span>
                {notDown && (
                  <div className="hover-down hovNot">
                    <span>
                      <h3>Your notifications</h3>
                    </span>
                    {notifications && notifications.length > 0 && (
                      <div className="line"></div>
                    )}
                    {notifications && notifications.length > 0 ? (
                      notifications.map((notification) => (
                        <div key={notifications.id}>
                          <span
                            className="notification"
                            onClick={(e) =>
                              !e.target.classList.contains("dont") &&
                              history.push(notification.idString)
                            }
                          >
                            <span className="left">
                              <Link
                                to={`/${notification.user.userName}`}
                                className="dont"
                              >
                                <img src={notification.user.photo.url} />
                              </Link>
                            </span>
                            <span className="right">
                              <Link
                                to={`/${notification.user.userName}`}
                                className="dont"
                              >
                                {notification.user.userName}
                              </Link>{" "}
                              <h5>{notification.message}</h5>
                            </span>
                          </span>
                          <div className="line"></div>
                        </div>
                      ))
                    ) : (
                      <span className="noNotification">
                        You have no notifications
                      </span>
                    )}
                    <span className="feedBack">
                      <Link
                        to="/notifications/feedback"
                        onClick={() => setNotDown(!notDown)}
                      >
                        See All
                      </Link>
                    </span>
                  </div>
                )}
              </div>
            )}
            <span className="nav-link">
              <img
                src={userInfo.photo.url}
                onClick={() => setRightMenuToggle(!rightMenuToggle)}
              />
            </span>
          </div>
        ) : (
          <span className="nav-link">
            <AccountBoxIcon
              style={{ fontSize: "2rem" }}
              onClick={() => setRightMenuToggle(!rightMenuToggle)}
            />
          </span>
        )}
      </div>
    </StyledMobNav>
  );
}

const StyledMobNav = styled(motion.div)`
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;
  }
  background: ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
  z-index: 4;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  .fIcon {
    margin: 0rem 0.4rem;
  }

  .left {
    margin-right: auto;
    display: flex;
    align-items: center;
    padding: 0.8rem 0.6rem;
    font-size: 1.2rem;

    .fIcon {
      margin-right: 1rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    }
    .icon {
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 7rem;
      }
    }
  }
  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0.8rem 0.6rem;
    font-size: 1.2rem;

    .fIcon {
      margin-left: 1rem;
    }
    .prof {
      display: flex;
      align-items: center;
      color: white;
      img {
        width: 1.8rem;
        border-radius: 4px;
      }
    }
    .nav-link {
      padding: 0rem 0.4rem;
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    }
    .dropdown {
      display: flex;
      border: none;

      .hover-down {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        height: 92vh;
        overflow-y: scroll;
        background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
        box-shadow: 0px 0px 3px 1.5px
          ${({ isDarkMode }) => (isDarkMode ? "#0f0f0f" : "#ffffff")};
        z-index: 1;
        margin: 0rem;
        transition: 0.1s ease-in-out;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
        .line {
          height: 2px;
          width: 100%;
          background-color: #99a8b2;
        }
        button {
          border-radius: 50%;
          padding: 0.16rem 0.25rem;
          border: none;
          cursor: pointer;
          background-color: inherit;
          border: 2px solid
            ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
          color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
          .fI {
            color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
            font-size: 1.3rem;
            &:hover {
              border: 2px solid #50c1e9;
              border-radius: 50%;
            }
          }
        }
        a {
          display: inline;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          font-size: 0.9rem;
          &:hover {
            color: #50c1e9;
          }
        }
      }
      .hovNot {
        .line {
          height: 1px;
          width: 100%;
          background: ${({ isDarkMode }) => (isDarkMode ? "gray" : "#cfcbcb;")};
          background: #cfcbcb;
          margin: 0rem 0rem;
        }

        h3 {
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          padding: 0.7rem 0rem;
        }
        .notification {
          font-size: 0.9rem;
          padding: 0.5em 0rem;
          display: flex;

          &:hover {
            background: ${({ isDarkMode }) =>
              isDarkMode ? "rgb(80,193,233,0.5)" : "rgb(80,193,233,0.3)"};
          }
          .left {
            flex: 12%;
            img {
              width: 2rem;
              border-radius: 2px;
            }
          }
          .right {
            flex: 88%;
            display: flex;

            align-items: center;
            justify-content: flex-start;
            padding: 0rem 0.4rem;
            a {
              padding: 0rem 0rem 0rem 0rem;
              width: auto;
            }
            h5 {
              padding: 0rem 0rem 0rem 0.3rem;
              width: auto;
              font-weight: 500;
              font-size: 0.9rem;
            }
          }
        }
        .noNotification {
          margin-left: auto;
          margin-right: auto;
          padding: 1rem;
        }
        .feedBack {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          a {
            padding: 1rem;
            font-size: 1rem;
          }
        }
      }
    }
    .form {
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      height: 100vh;
      left: 0;
      bottom: 0;
      position: fixed;
      background: ${({ isDarkMode }) =>
        isDarkMode ? "rgb(23,24,24,0.8)" : "rgb(248,248,248,0.8)"};
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      z-index: ${({ searchToggle }) => (searchToggle ? "5" : "-5")};
      opacity: ${({ searchToggle }) => (searchToggle ? "1" : "1")};
      padding: 0.8rem 1rem;
      transition: all 0.4s ease-in-out;
      transform: ${({ searchToggle }) =>
        searchToggle ? "translateY(0%)" : "translateY(-100%)"};
      .cancelSearch {
        position: absolute;
        right: 0;
        top: 0;
        margin-left: auto;
        margin: 4rem 1rem;
        opacity: 1rem;
        display: block;
        font-size: 1.3em;
        color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        cursor: pointer;
      }

      form {
        margin: 0;
        width: 100%;
        padding: 0rem;
        .line {
          width: 90%;
          height: 0.2rem;
          margin-bottom: 1rem;
          display: block;
          opacity: 1;
          margin-left: auto;
          margin-right: auto;
          background: ${({ isDarkMode }) =>
            isDarkMode ? "whitesmoke" : "gray"};
        }
        input[type="search"]::-ms-clear {
          display: none;
          width: 0;
          height: 0;
        }
        input[type="search"]::-ms-reveal {
          display: none;
          width: 0;
          height: 0;
        }

        /* clears the 'X' from Chrome */
        input[type="search"]::-webkit-search-decoration,
        input[type="search"]::-webkit-search-cancel-button,
        input[type="search"]::-webkit-search-results-button,
        input[type="search"]::-webkit-search-results-decoration {
          display: none;
        }

        input {
          display: flex;
          border-bottom: none;
          background: transparent;
          padding: 0.3rem 3rem;
          margin: 8rem auto 0.2rem auto;
          border-radius: 0px;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          border-top: none;
          border-left: none;
          border-right: none;
          font-size: 1rem;
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
          &::placeholder {
            padding-left: 0px;
            padding-bottom: 0px;
            font-size: 1rem;
            color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }
        button {
          display: none;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
        }
      }
      .suggestions {
        display: flex;
        flex-flow: wrap column;
        width: 100%;
        .sugg-text {
          text-align: center;
          color: #50c1e9;
          margin: 0.5rem;
        }
        a {
          margin-left: auto;
          margin-right: auto;
          color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
          padding: 0.6rem;
        }
      }
    }
  }
`;

const StyledMenu = styled(motion.div)`
  display: flex;
  color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  transform: ${({ menuToggle }) =>
    menuToggle ? "translateX(0%)" : "translateX(-100%)"};

  z-index: ${({ menuToggle }) => (menuToggle ? "8" : "-2")};

  .submit {
    display: flex;
    align-items: center;
    padding-right: 0.4rem;
    a,
    .sub {
      color: #50c1e9;
      font-weight: 550;
      font-size: 1rem;
      &:hover {
        color: #4cb1d6;
      }
    }
  }
  button {
    border-radius: 50%;
    padding: 0.16rem 0.25rem;
    border: none;
    cursor: pointer;
    background-color: inherit;
    border: 2px solid
      ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
    color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
    font-size: 1.1rem;

    .fI {
      color: ${({ isDarkMode }) => (isDarkMode ? "whitesmoke" : "gray")};
      font-size: 1.3rem;
      border-radius: 0.4rem;
    }
  }
  .sun {
    font-size: 1.1rem;
  }
  .moon {
    font-size: 1.1rem;
  }
  .theme {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    span {
      padding: 0.6rem 0rem;
      font-size: 1.1rem;
    }
    button {
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }
  }
  .menu {
    display: flex;
    color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 5;
    transition: all 0.5s ease-in-out;
    transform: ${({ menuToggle }) =>
      menuToggle ? "translateX(0%)" : "translateX(-100%)"};
  }
  .left {
    .icon {
      a {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        h2 {
          font-weight: 500;
          font-size: 1.5rem;
        }
      }
    }
    .logout {
      text-align: center;
      color: red;
    }
    .line {
      height: 2px;
      width: 100%;
      background-color: #99a8b2;
      margin: 0.2rem 0rem;
    }
    flex: 80%;
    background: ${({ isDarkMode }) => (isDarkMode ? "#171818" : "#f8f8f8")};
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    h2 {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      font-size: 1.35rem;
      text-align: center;
      padding: 0.2rem 0rem;
      font-weight: 500;
    }
    a {
      color: ${({ isDarkMode }) => (isDarkMode ? "white" : "black")};
      padding: 0.6rem 0rem;
      font-size: 1.1rem;
    }
  }
  .right {
    flex: 20%;
  }
`;

const StyledRightMenu = styled(StyledMenu)`
  .premiumButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0rem;
    button {
      width: 6rem;
      padding: 0.4rem;
      border: 2px solid #50c1e9;
      border-radius: 4px;
      background: transparent;
      color: #50c1e9;
      font-size: 0.9rem;
      cursor: pointer;
      &:hover {
        color: white;
        background: #50c1e9;
      }
    }
  }
  .resend-email {
    padding: 0.8rem 0.7rem;
    display: flex;
    flex-flow: column wrap;
    border: 2px solid #50c1e9;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 4px;
    color: #50c1e9;
    span {
      padding: 0rem 0rem;
    }
    small {
      display: flex;
      align-items: center;
      button {
        margin-left: 0.2rem;
        border: 2px solid #50c1e9;
        border-radius: 2px;
        padding: 0.2rem;
        color: #50c1e9;
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 5rem;
        .icon {
          width: 2rem;
          font-size: 1rem;
          margin: none;
        }
      }
    }
  }
  .right {
    flex: 20%;
    padding: 2rem;
  }
  transform: ${({ rightMenuToggle }) =>
    rightMenuToggle ? "translateX(0%)" : "translateX(100%)"};

  z-index: ${({ rightMenuToggle }) => (rightMenuToggle ? "8" : "-2")};

  .menu {
    transform: ${({ rightMenuToggle }) =>
      rightMenuToggle ? "translateX(0%)" : "translateX(100%)"};
  }
`;

export default MobNav;
